import {createMediaSignals} from '@pexip/media';
import {createSignal} from '@pexip/signal';

export const pushToTalkSignal = createSignal<boolean>({
    name: 'media:unmute:actions',
});

export const mediaSignals = createMediaSignals(
    [
        'onAudioMuteStateChanged',
        'onDevicesChanged',
        'onStatusChanged',
        'onStreamTrackEnabled',
        'onStreamTrackEnded',
        'onStreamTrackMuted',
        'onStreamTrackUnmutedFinal',
        'onStreamTrackUnmuted',
        'onVideoMuteStateChanged',
    ],
    'infinity-connect',
);
