import {useTranslation} from 'react-i18next';
import React from 'react';

import {
    ActionsRow,
    Button,
    ThemeProvider,
    ToggleSwitch,
} from '@pexip/components';
import {
    SettingsPanel,
    PanelHeader,
    TestId as MediaTestId,
} from '@pexip/media-components';

import {TestId} from '../../../test/testIds';

export const MeetingSettings: React.FC<{
    isLocked: boolean;
    isStarted: boolean;
    isEnableOverlayText: boolean;
    startConference: () => void;
    toggleLock: () => void;
    toggleEnableOverlayText: () => void;
    onBackClick: () => void;
}> = ({
    isLocked,
    isStarted,
    isEnableOverlayText,
    startConference,
    toggleLock,
    toggleEnableOverlayText,
    onBackClick,
}) => {
    const {t} = useTranslation();

    return (
        <>
            <SettingsPanel
                headerContent={
                    <ThemeProvider colorScheme="light">
                        <PanelHeader
                            title={`${t(
                                'settings.meeting-settings',
                                'Meeting Settings',
                            )}`}
                            onBackClick={onBackClick}
                            backButtonTestId={MediaTestId.ButtonSettingsBack}
                        />
                    </ThemeProvider>
                }
            >
                <ThemeProvider colorScheme="light">
                    <ActionsRow
                        title={t('settings.lock', 'Lock the meeting')}
                        subtitle={t(
                            'settings.lock-desc',
                            'Prevents anyone from automatically joining the meeting',
                        )}
                    >
                        <Button
                            variant="tertiary"
                            onClick={toggleLock}
                            data-testid={
                                isLocked
                                    ? TestId.ButtonUnlock
                                    : TestId.ButtonLock
                            }
                        >
                            {isLocked
                                ? t('settings.unlock', 'Unlock the meeting')
                                : t('settings.lock', 'Lock the meeting')}
                        </Button>
                    </ActionsRow>
                    <ActionsRow
                        title={t(
                            'settings.show-name-labels',
                            'Show name labels',
                        )}
                        subtitle={t(
                            'settings.show-name-labels-desc',
                            'Displays participant names in the meeting layout for others to see. You may want to turn this off if taking a screenshot for social media',
                        )}
                    >
                        {/* TODO: The inline style for padding-left shound be removed when the settings panel is redesigned */}
                        <div style={{paddingLeft: '50px'}}>
                            <ToggleSwitch
                                onChange={toggleEnableOverlayText}
                                labelModifier="hidden"
                                label={t(
                                    'settings.show-name-labels',
                                    'Show name labels',
                                )}
                                name="name-labels-overlay"
                                checked={isEnableOverlayText}
                            ></ToggleSwitch>
                        </div>
                    </ActionsRow>
                    {!isStarted && (
                        <ActionsRow
                            title={t('settings.start-meeting', 'Start meeting')}
                            subtitle={t(
                                'settings.start-meeting-desc',
                                'Starts the meeting',
                            )}
                        >
                            <Button
                                onClick={startConference}
                                variant="tertiary"
                                data-testid={TestId.ButtonStartConference}
                            >
                                {t('settings.start-meeting', 'Start meeting')}
                            </Button>
                        </ActionsRow>
                    )}
                </ThemeProvider>
            </SettingsPanel>
        </>
    );
};
