import React, {useCallback} from 'react';
import type {Key} from '@react-types/shared';
import {Item} from '@react-stately/collections';

import {InnerAriaMenuButton} from './InnerAriaMenuButton';
import type {AriaMenuItem, AriaMenuProps} from './AriaMenu.types';
import {ariaMenuOpenStateSignal} from './AriaMenu.signals';

/**
 * AriaMenu component represents a dropdown menu.
 */
export const AriaMenu: React.FC<AriaMenuProps> = ({
    items,
    button,
    headerContent,
    onOpenChange: onOpenChangeProp,
    testId,
    colorScheme,
    popoverPlacement,
    popoverOffset,
    menuClassName,
}) => {
    const itemMap: Map<Key, AriaMenuItem> = new Map();
    items.map(item => itemMap.set(item.key, item));

    const onOpenChange = useCallback(
        (isOpen: boolean) => {
            ariaMenuOpenStateSignal.emit(isOpen);
            onOpenChangeProp?.(isOpen);
        },
        [onOpenChangeProp],
    );

    return (
        <InnerAriaMenuButton
            button={button}
            headerContent={headerContent}
            onOpenChange={onOpenChange}
            itemMap={itemMap}
            testId={testId}
            colorScheme={colorScheme}
            popoverPlacement={popoverPlacement}
            popoverOffset={popoverOffset}
            menuClassName={menuClassName}
        >
            {items.map(item => (
                <Item
                    textValue={
                        typeof item.label === 'string'
                            ? item.label
                            : item.textValue
                    }
                    key={item.key}
                >
                    {item.label}
                </Item>
            ))}
        </InnerAriaMenuButton>
    );
};
