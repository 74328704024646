import {useCallback, useContext} from 'react';

import {Channels, Plugins} from '..';
import {useBranding} from '../../branding/Context';
import type {PluginElements} from '../types';
import {logger} from '../../logger';

export const useOrderedPlugins = () => {
    const pluginManifest = useBranding('plugins');
    const plugins = useContext(Plugins);
    const channelsRef = useContext(Channels);

    const findChannelId = useCallback(
        (pluginId: string) => {
            const channelId = Array.from(
                (channelsRef?.current ?? new Map<string, unknown>()).keys(),
            ).find(channelId => pluginId && channelId.includes(pluginId));
            if (!channelId) {
                logger.warn(
                    'Plugin manifest id does not match with id given to "registerPlugin" in plugin source code',
                );
            }
            return channelId;
        },
        [channelsRef],
    );

    return pluginManifest && pluginManifest.every(plugin => plugin.id)
        ? pluginManifest.map(plugin => {
              const channelId = plugin.id
                  ? findChannelId(plugin.id)
                  : undefined;
              return (channelId ? plugins[channelId] : {}) as PluginElements;
          })
        : Object.values(plugins);
};
