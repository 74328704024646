import React from 'react';
import cx from 'classnames';

import {AspectRatio} from '@pexip/components';

import type {SelfviewAspectRatios} from '../../../types';
import {Selfview} from '../Selfview.view';

import styles from './AspectRatioSelfview.module.scss';

export interface WithAspectRatioSelfviewProps {
    className?: string;
    aspectRatioType?: SelfviewAspectRatios;
    isMaximized?: boolean;
}

export function withAspectRatioSelfview<P>(Component: React.ComponentType<P>) {
    return function WrappedWithAspectRatioSelfview(
        props: P & WithAspectRatioSelfviewProps,
    ) {
        const {aspectRatioType: selfviewAspectRatioType = 'camera-step'} =
            props;

        return (
            <AspectRatio
                className={cx(styles.selfviewAspectRatio, {
                    [styles.cameraStep]:
                        selfviewAspectRatioType === 'camera-step',
                    [styles.readyToJoinStep]:
                        selfviewAspectRatioType === 'ready-to-join-step',
                    [styles.inMeeting]:
                        selfviewAspectRatioType === 'in-meeting',
                    [styles.readyToJoinExpress]:
                        selfviewAspectRatioType === 'ready-to-join-express',
                    [styles.forceLandscape]:
                        selfviewAspectRatioType === 'force-landscape',
                    [styles.maximize]: props.isMaximized,
                })}
            >
                <Component {...props} />
            </AspectRatio>
        );
    };
}

export const AspectRatioSelfview = withAspectRatioSelfview(Selfview);

export type SelfviewAspectRatioProps = React.ComponentProps<
    typeof AspectRatioSelfview
>;
