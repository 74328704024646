import React from 'react';
import cx from 'classnames';

import type {ExtendedSizeModifier} from '../../../types/sizes';
import {sizeToPadding} from '../../../utils/helpers';

import styles from './BoxHeader.module.scss';

export const BoxHeader: React.FC<
    React.ComponentProps<'div'> & {
        padding?: ExtendedSizeModifier;
        contentBelowDivider?: React.ReactElement;
    }
> = ({className, padding = 'small', contentBelowDivider, children}) => {
    const content = (
        <div
            className={cx(
                styles[sizeToPadding(padding)],
                styles.boxHeader,
                className,
            )}
        >
            {children}
        </div>
    );
    return contentBelowDivider ? (
        <div>
            {content}
            {contentBelowDivider}
        </div>
    ) : (
        content
    );
};

export type BoxHeaderProps = React.ComponentProps<typeof BoxHeader>;
