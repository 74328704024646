import type {SegmentationModel} from './types/segmentation';
import {SEGMENTATION_MODELS} from './types/segmentation';
import type {ImageMapIterable} from './types/media';
import type {RenderingEvents} from './constants';
import {RENDERING_EVENTS} from './constants';

export const isSegmentationModel = (t: unknown): t is SegmentationModel => {
    if (
        typeof t === 'string' &&
        Object.values(SEGMENTATION_MODELS).includes(t as SegmentationModel)
    ) {
        return true;
    }
    return false;
};

export const isRenderingEvents = (t: unknown): t is RenderingEvents => {
    if (
        typeof t === 'string' &&
        Object.values(RENDERING_EVENTS).includes(t as RenderingEvents)
    ) {
        return true;
    }
    return false;
};

export const toRenderingEvents = (t: unknown): RenderingEvents => {
    if (isRenderingEvents(t)) {
        return t;
    }
    return RENDERING_EVENTS.Error;
};

export const getErrorMessage = (error: unknown) =>
    error instanceof Error ? error.message : String(error);

export const createObjectUpdater = <T extends {[K in keyof T]: unknown}>(
    original: T,
) => {
    const keys = new Set(Object.keys(original));
    return (another: Partial<T>) => {
        for (const key in another) {
            if (keys.has(key) && typeof original[key] === typeof another[key]) {
                original[key] = another[key] as T[typeof key];
            }
        }
    };
};

export const createRemoteImageBitmap = (map?: ImageMapIterable) => {
    const cache = new Map(map);
    const fetchingCache = new Set<string>();

    const fetchImage = async (uri: string) => {
        const response = await fetch(uri);
        const blob = await response.blob();
        const image = await createImageBitmap(blob);
        return image;
    };

    const tryFetchingImage = async (uri: string) => {
        const image = cache.get(uri);
        if (!image) {
            if (fetchingCache.has(uri)) {
                return null;
            }
            fetchingCache.add(uri);
            const image = await fetchImage(uri);
            cache.set(uri, image);
            fetchingCache.delete(uri);
            return image;
        }
        return image;
    };

    const removeImage = (uri: string) => {
        const image = cache.get(uri);
        image?.close();
        return cache.delete(uri);
    };

    return {
        tryFetchingImage,
        setImage: (uri: string, image: ImageBitmap) => {
            if (cache.has(uri)) {
                removeImage(uri);
            }
            cache.set(uri, image);
        },
        getImage: (uri: string) => {
            return cache.get(uri) ?? null;
        },
        removeImage,
        clear: () => {
            cache.forEach(img => img.close());
            cache.clear();
        },
    };
};

export const clamping = (min: number, max: number) => (value: number) =>
    Math.min(Math.max(value, min), max);

export const calculateMaxBlurPass = (height: number) =>
    Math.trunc(Math.log2(height));

/**
 * Calculate the coordinates and size of the source and destination to fit the
 * target aspect ratio.
 *
 * @param fromWidth - The width of the source
 * @param fromHeight - The height of the source
 * @param toWidth - The width of the destination
 * @param toHeight - The height of the destination
 */
export const resize = (
    fromWidth: number,
    fromHeight: number,
    toWidth: number,
    toHeight: number,
    // eslint-disable-next-line max-params -- avoid unnecessary object creation
) => {
    if (
        !fromWidth ||
        !fromHeight ||
        !toWidth ||
        !toHeight ||
        fromWidth * toHeight === toWidth * fromHeight
    ) {
        return {
            sx: 0,
            sy: 0,
            sw: fromWidth,
            sh: fromHeight,
            dx: 0,
            dy: 0,
            dw: toWidth,
            dh: toHeight,
        };
    }
    const originalAspectRatio = fromWidth / fromHeight;
    const targetAspectRatio = toWidth / toHeight;
    if (originalAspectRatio > targetAspectRatio) {
        const sw =
            Math.ceil(fromWidth * targetAspectRatio) / originalAspectRatio;
        const delta = Math.abs(fromWidth - sw);
        const sx = Math.ceil(delta / 2);
        return {
            sx,
            sy: 0,
            sw,
            sh: fromHeight,
            dx: 0,
            dy: 0,
            dw: toWidth,
            dh: toHeight,
        };
    }
    const sh = Math.ceil(fromHeight * originalAspectRatio) / targetAspectRatio;
    const delta = Math.abs(fromHeight - sh);
    const sy = Math.ceil(delta / 2);
    return {
        sx: 0,
        sy,
        sw: fromWidth,
        sh,
        dx: 0,
        dy: 0,
        dw: toWidth,
        dh: toHeight,
    };
};
