import React from 'react';

import type {Participant} from '@pexip/infinity';
import {InteractiveElement} from '@pexip/components';

import type {ChatMessage, InMeetingParticipant} from '../..';
import {ChatMessageView, KEYBOARD_EVENT_KEY, TestId} from '../..';

export const ChatDirectMessagePreview: React.FC<{
    hasUnreadMessages: boolean;
    participantInDirectChatWith: InMeetingParticipant | Participant;
    handleGoToDirectChat: () => void;
    latestMessage: ChatMessage;
}> = ({
    participantInDirectChatWith,
    latestMessage,
    hasUnreadMessages,
    handleGoToDirectChat,
}) => {
    return (
        <InteractiveElement
            data-testid={TestId.ButtonChatDirectMessagePreview}
            tabIndex={0}
            htmlTag="div"
            onClick={handleGoToDirectChat}
            onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                if (e.key === KEYBOARD_EVENT_KEY.enter) {
                    handleGoToDirectChat();
                }
            }}
        >
            <ChatMessageView
                isUnread={hasUnreadMessages}
                useHoverStyle
                withAvatar
                isTruncated
                message={{
                    ...latestMessage,
                    displayName: participantInDirectChatWith.displayName ?? '',
                }}
            />
        </InteractiveElement>
    );
};
