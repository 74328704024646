/**
 * media-control is meant to extend the mediaDevices api methods such as
 * `getUserMedia`, `enumerateDevices` and events on media streams and tracks.
 * The final goal is to have a library that gives a stable and robust way to use
 * these methods while guaranteeing that the developer has better control over
 * which devices are delivered, fail, or exist.
 *
 * @packageDocumentation
 */

import {
    DeviceChangedChanges,
    areMultipleFacingModeSupported,
    areTracksEnabled,
    createTrackDevicesChanges,
    deviceChanged,
    findAudioInputDevices,
    findAudioOutputDevices,
    findCurrentAudioOutputId,
    findCurrentVideoInputDeviceIdFromStream,
    findDeviceWithDeviceId,
    findDevicesByKind,
    findMediaInputFromMediaStreamTrack,
    findMediaInputFromStream,
    findPermissionGrantedDevices,
    findVideoInputDevices,
    getDevices,
    getInputDevicePermissionState,
    hasAnyGrantedInput,
    hasAnyInputs,
    hasAudioInputs,
    hasAudioOrVideoInputs,
    hasChangedInput,
    hasRequestingDevice,
    hasVideoInputs,
    interpretCurrentFacingMode,
    isRequestedInputDevice,
    isRequestedInputTrack,
    isRequestedResolution,
    isStreamingRequestedDevices,
    isStreamingRequestedDevicesBase,
    isTrackMutedOrEnded,
    muteStreamTrack,
    shouldRequestDevice,
    stopMediaStream,
    toKey,
    toMediaDeviceInfo,
    toMediaDeviceInfoLike,
} from './devices';
import {
    compareDevices,
    findDevice,
    isAudioOutput,
    isVideoInput,
    isAudioInput,
    isDeviceGranted,
} from './utils';
import {
    mergeConstraints,
    getConstraintsHandlers,
    relaxInputConstraint,
    findDeviceFromConstraints,
    isExactDeviceConstraint,
    extractConstraintsWithKeys,
    applyConstraints,
    getValueFromConstrainNumber,
    getFacingModeFromConstraintString,
    extractConstrainDevice,
} from './constraints';
import {eventEmitter, MediaEvent, Events, MediaEventType} from './eventEmitter';
import {
    handleMediaStream,
    createStreamTrackEventSubscriptions,
} from './streams';
import {
    MediaDeviceRequest,
    MediaDeviceFailure,
    MediaDeviceKinds,
    MediaDeviceInfoLike,
    MediaStreamTrackLike,
    MediaInput,
    Unsubscribe,
    StreamTrackEventHandlers,
    InputConstraintSet,
    FacingMode,
    DisplayMediaOptions,
} from './types';
import {
    isMediaDeviceInfo,
    isMediaDeviceInfoArray,
    isMediaStreamTrack,
    isFacingMode,
} from './typeGuards';
import {createStreamTrackMap} from './streamTrackMap';
export {setLogger} from './logger';

const state = () => {
    const {dispatch, subscriber} = eventEmitter();
    const streamTrackMap = createStreamTrackMap();
    const {getDefaultConstraints, setDefaultConstraints} =
        getConstraintsHandlers();
    const {getUserMedia} = handleMediaStream({
        dispatch,
        getDefaultConstraints,
        streamTrackMap,
    });

    /**
     * Subscription media events
     *
     * @beta
     */
    const subscribe = (listener: (event: MediaEvent) => void) => {
        return subscriber(
            listener,
            deviceChanged(event => {
                // Can be used to keep a store in sync
                dispatch({
                    type: MediaEventType.DevicesChanged,
                    devices: event.devices,
                });

                if (!hasAudioOrVideoInputs(event.devices)) {
                    return dispatch({
                        type: MediaEventType.NoInputDevices,
                        devices: event.devices,
                    });
                }

                if (event.unauthorized.length) {
                    dispatch({
                        type: MediaEventType.DevicesUnauthorized,
                        devices: event.unauthorized,
                        authorizedDevices: event.authorized,
                    });
                }

                if (event.found.length) {
                    // Can be used to trigger user notification of a potentially better device being available
                    dispatch({
                        type: MediaEventType.DevicesFound,
                        authorizedDevices: event.authorized,
                        unauthorizedDevices: event.unauthorized,
                        devices: event.found,
                    });
                }

                if (event.lost.length) {
                    // Can be used to trigger user notification of no longer seen devices
                    dispatch({
                        type: MediaEventType.DevicesLost,
                        authorizedDevices: event.authorized,
                        unauthorizedDevices: event.unauthorized,
                        devices: event.lost,
                    });
                    for (const device of event.lost) {
                        if (streamTrackMap.has(device)) {
                            // Can be used to trigger user warning that a device they were using is lost
                            dispatch({type: MediaEventType.DeviceLost, device});
                        }
                    }
                }
            }),
        );
    };

    return {
        getUserMedia,
        setDefaultConstraints,
        subscribe,
    };
};

const {
    /**
     * Get MediaStream with provided {@link MediaDeviceRequest | input constraints}
     */
    getUserMedia,
    /**
     * Set default media stream constraints
     *
     * A {@link
     * https://developer.mozilla.org/en-US/docs/Web/API/MediaStreamConstraints |
     * MediaStreamConstraints} object specifying the types of media to request, along with any requirements for each type.
     */
    setDefaultConstraints,
    /**
     * Subscribe media events
     */
    subscribe,
} = state();

export {getUserMedia, setDefaultConstraints, subscribe};

export {
    DeviceChangedChanges,
    DisplayMediaOptions,
    Events,
    FacingMode,
    InputConstraintSet,
    MediaDeviceFailure,
    MediaDeviceInfoLike,
    MediaDeviceKinds,
    MediaDeviceRequest,
    MediaEvent,
    MediaEventType,
    MediaInput,
    MediaStreamTrackLike,
    StreamTrackEventHandlers,
    Unsubscribe,
    applyConstraints,
    areMultipleFacingModeSupported,
    areTracksEnabled,
    compareDevices,
    createStreamTrackEventSubscriptions,
    createTrackDevicesChanges,
    deviceChanged,
    extractConstrainDevice,
    extractConstraintsWithKeys,
    findAudioInputDevices,
    findAudioOutputDevices,
    findCurrentAudioOutputId,
    findCurrentVideoInputDeviceIdFromStream,
    findDevice,
    findDeviceFromConstraints,
    findDeviceWithDeviceId,
    findDevicesByKind,
    findMediaInputFromMediaStreamTrack,
    findMediaInputFromStream,
    findPermissionGrantedDevices,
    findVideoInputDevices,
    getDevices,
    getFacingModeFromConstraintString,
    getInputDevicePermissionState,
    getValueFromConstrainNumber,
    hasAnyGrantedInput,
    hasAnyInputs,
    hasAudioInputs,
    hasAudioOrVideoInputs,
    hasChangedInput,
    hasRequestingDevice,
    hasVideoInputs,
    interpretCurrentFacingMode,
    isAudioInput,
    isAudioOutput,
    isDeviceGranted,
    isExactDeviceConstraint,
    isFacingMode,
    isMediaDeviceInfo,
    isMediaDeviceInfoArray,
    isMediaStreamTrack,
    isRequestedInputDevice,
    isRequestedInputTrack,
    isRequestedResolution,
    isStreamingRequestedDevices,
    isStreamingRequestedDevicesBase,
    isTrackMutedOrEnded,
    isVideoInput,
    mergeConstraints,
    muteStreamTrack,
    relaxInputConstraint,
    shouldRequestDevice,
    stopMediaStream,
    toKey,
    toMediaDeviceInfo,
    toMediaDeviceInfoLike,
};

export * from './constants';
export * from './displayMedia';

/**
 * MediaControl Interface
 *
 * @beta
 */
export interface MediaControl {
    /** {@inheritDoc deviceChanged} */
    deviceChanged: typeof deviceChanged;
    /** {@inheritDoc getDevices} */
    getDevices: typeof getDevices;
    /** {@inheritDoc MediaEventType} */
    MediaEventType: typeof MediaEventType;
    /** {@inheritDoc getUserMedia} */
    getUserMedia: typeof getUserMedia;
    /** {@inheritDoc setDefaultConstraints} */
    setDefaultConstraints: typeof setDefaultConstraints;
    /** {@inheritDoc subscribe} */
    subscribe: typeof subscribe;
}

export type {InputDevicePermission} from './types';
