import React from 'react';

import type {IScrollbars} from '@pexip/components';

import {scrollToLastMessage} from '../../utils';
import type {ChatMessage, ParticipantUuid} from '../../types';
import {ChatPanelMessages} from '../ChatMessage/ChatPanelMessages.view';
import {NewChatMessagePill} from '../NewChatMessagePill/NewChatMessagePill.view';

import styles from './ChatPanel.module.scss';

export const ChatPanelView: React.FC<{
    currentUserId: ParticipantUuid;
    closeNewChatMessage: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
    displayNewMessageToast: boolean;
    messages: ChatMessage[];
    newMessagesCount: number;
    scrollbarRef: React.RefObject<IScrollbars>;
    chatPanelMessagesClassName?: string | undefined;
}> = ({
    currentUserId,
    closeNewChatMessage,
    displayNewMessageToast,
    messages,
    newMessagesCount,
    scrollbarRef,
    chatPanelMessagesClassName,
}) => (
    <div className={styles.chatWrapper}>
        {displayNewMessageToast && (
            <div className={styles.newMessagesPillWrapper}>
                <NewChatMessagePill
                    newMessagesCount={newMessagesCount}
                    scrollToLastMessage={() =>
                        scrollToLastMessage(scrollbarRef)
                    }
                    closeNewChatMessage={closeNewChatMessage}
                />
            </div>
        )}
        <div className={styles.chatMessagesWrapper}>
            {messages.length > 0 && (
                <ChatPanelMessages
                    currentUserId={currentUserId}
                    messages={messages}
                    className={chatPanelMessagesClassName}
                />
            )}
        </div>
    </div>
);
