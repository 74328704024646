import {useCallback, useRef} from 'react';

export const useDoubleTap = (
    callback: () => void,
    tapTimeout: number = 300,
) => {
    const lastTapTime = useRef(0);

    const onDoubleTap = useCallback(() => {
        const currentTime = Date.now();
        const timeDifference = currentTime - lastTapTime.current;

        if (lastTapTime.current > 0 && timeDifference < tapTimeout) {
            callback();
        }

        lastTapTime.current = currentTime;
    }, [callback, tapTimeout]);

    return onDoubleTap;
};
