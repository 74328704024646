import {assert} from '@pexip/utils';

import type {Segmenter} from '../types';
import type {
    Delegate,
    SegmenterOptions,
    ImageSegmenterOptions,
    SegmentationModelAsset,
} from '../../../common/types/segmentation';
import type {
    ProcessorEvents,
    ProcessorOptions,
    ProcessorWorkerEvents as WorkerEvents,
} from '../../../common/types/processor';
import type {ImageRecord, VideoFrameLike} from '../../../common/types/media';
import type {ExtractMessageEventType} from '../../../common/types/utils';
import type {ProcessStatus, RenderingEvents} from '../../../common/constants';
import {
    PROCESS_STATUS,
    PROCESSING_WIDTH,
    PROCESSING_HEIGHT,
    RENDERING_EVENTS,
} from '../../../common/constants';

type WorkerEventTypeNoError = Exclude<
    ExtractMessageEventType<WorkerEvents>,
    'error' | 'debug' | 'event'
>;
type Callback = () => void;

type EventHandlers = Map<RenderingEvents, Set<Callback>>;

interface Options extends Omit<ImageSegmenterOptions, 'delegate'> {
    /**
     * Processing width for the processing canvas.
     * @defaultValue 768
     */
    processingWidth?: number;
    /**
     * Processing height for the processing canvas.
     * @defaultValue 432
     */
    processingHeight?: number;
    /**
     * The URL for the worker script.
     */
    workerScriptUrl?: URL;
    /**
     * Request Credentials for the worker.
     *
     * @defaultValue 'same-origin'
     */
    credentials?: RequestCredentials;
    /**
     * @see `SegmentationModelAsset`
     */
    modelAsset: SegmentationModelAsset;

    delegate?: () => Delegate;

    log?: (message: string, meta?: unknown) => void;
}

const cloneImageRecord = async (image: ImageRecord) => {
    const cloned = await createImageBitmap(image.image);
    return {image: cloned, key: image.key};
};

export const createSegmenter = (
    /**
     * A base path to specify the directory the Wasm files should be loaded
     * from. If not provided, it will be loaded from the host's root directory.
     *
     * @see {@link
     * https://ai.google.dev/edge/api/mediapipe/js/tasks-vision.filesetresolver#filesetresolverforvisiontasks | FileResolver}
     */
    basePath: SegmenterOptions['basePath'] = '/',
    {
        processingWidth = PROCESSING_WIDTH,
        processingHeight = PROCESSING_HEIGHT,
        workerScriptUrl = new URL(
            '../../../workers/mediaWorker.js',
            import.meta.url,
        ),
        credentials = 'same-origin',
        delegate,
        log,
        ...options
    }: Options,
): Segmenter => {
    const props: {
        mediaWorker?: Worker;
        status: ProcessStatus;
        output?: OffscreenCanvas | null;
        modelAsset: SegmentationModelAsset;
        eventHandlers: EventHandlers;
    } = {
        status: PROCESS_STATUS.New,
        modelAsset: options.modelAsset,
        eventHandlers: new Map(),
    };

    const initWorker = () => {
        const mediaWorker = new Worker(workerScriptUrl, {
            type: 'classic',
            credentials,
        });

        mediaWorker.addEventListener('error', error => {
            log?.(`${error.message}`, error);
            error.preventDefault();
        });
        mediaWorker.addEventListener(
            'message',
            (event: MessageEvent<WorkerEvents>) => {
                const {type} = event.data;
                if (type === 'debug') {
                    log?.(event.data.data.message, event.data.data.context);
                }
                if (type === 'event') {
                    log?.(event.data.data.type, event.data.data.message);
                    props.eventHandlers
                        .get(event.data.data.type)
                        ?.forEach(cb => {
                            cb();
                        });
                    switch (event.data.data.type) {
                        case RENDERING_EVENTS.ProcessorRestarted:
                            break;
                        case RENDERING_EVENTS.ContextRestored:
                            void handleContextRestored();
                            break;
                        default:
                            props.status = PROCESS_STATUS.Error;
                            break;
                    }
                }
            },
        );

        return mediaWorker;
    };

    const handleContextRestored = async () => {
        try {
            await postMsg('updated', {
                type: 'update',
                data: {restart: true},
            });
            props.status = PROCESS_STATUS.Updated;
            props.eventHandlers.get('ProcessorRestarted')?.forEach(cb => {
                cb();
            });
        } catch (err) {
            log?.('Failed to restart process', {
                error: err,
            });
            props.status = PROCESS_STATUS.Error;
        }
    };

    function postMsg(
        expectedResponseType: 'processed',
        message: ProcessorEvents,
        transfer?: Transferable[],
    ): Promise<VideoFrameLike | undefined>;
    function postMsg(
        expectedResponseType: 'opened' | undefined,
        message: ProcessorEvents,
        transfer?: Transferable[],
    ): Promise<void>;
    function postMsg(
        expectedResponseType: 'updated' | undefined,
        message: ProcessorEvents,
        transfer?: Transferable[],
    ): Promise<void>;
    async function postMsg<T extends WorkerEventTypeNoError>(
        expectedResponseType: T | undefined,
        message: ProcessorEvents,
        transfer?: Transferable[],
        // eslint-disable-next-line @typescript-eslint/no-invalid-void-type --- necessary to get it work work promise void
    ): Promise<void | VideoFrameLike | undefined> {
        if (!expectedResponseType) {
            return props.mediaWorker?.postMessage(message, transfer ?? []);
        }
        return await new Promise<VideoFrameLike | undefined>(
            (resolve, reject) => {
                const handleMessage = (ev: MessageEvent<WorkerEvents>) => {
                    switch (ev.data.type) {
                        case expectedResponseType: {
                            removeListener();
                            // @ts-expect-error --- Call `resolve` is expecting to pass an argument or nothing
                            resolve(ev.data.data);
                            break;
                        }
                        case 'debug': {
                            // Nothing to handle
                            break;
                        }
                        case 'event': {
                            removeListener();
                            reject(
                                new Error(ev.data.data.type, {
                                    cause: ev.data.data.message,
                                }),
                            );
                            break;
                        }
                        default: {
                            removeListener();
                            reject(
                                new Error(`UnhandledEvent: ${ev.data.type}`),
                            );
                        }
                    }
                };
                const handleError = (error: ErrorEvent) => {
                    removeListener();
                    reject(error.message);
                };
                const removeListener = () => {
                    props.mediaWorker?.removeEventListener(
                        'message',
                        handleMessage,
                    );
                    props.mediaWorker?.removeEventListener(
                        'error',
                        handleError,
                    );
                };
                props.mediaWorker?.addEventListener('message', handleMessage);
                props.mediaWorker?.addEventListener('error', handleError);
                props.mediaWorker?.postMessage(message, transfer ?? []);
            },
        );
    }

    const open = async (processorOptions?: ProcessorOptions) => {
        props.status = PROCESS_STATUS.Opening;
        props.mediaWorker = initWorker();
        props.output = processorOptions?.output;

        const backgroundImage =
            processorOptions?.backgroundImage &&
            (await cloneImageRecord(processorOptions.backgroundImage));

        try {
            const processorSettings = {
                basePath,
                processingWidth,
                processingHeight,
                ...processorOptions,
                imageSegmenterOptions: {
                    ...options,
                    delegate: delegate?.(),
                    ...processorOptions?.imageSegmenterOptions,
                },
                backgroundImage,
            };
            log?.('Init processor with config', {config: processorSettings});
            await postMsg(
                'opened',
                {
                    type: 'open',
                    data: processorSettings,
                },
                [processorOptions?.output, backgroundImage?.image].filter(
                    Boolean,
                ) as Transferable[],
            );
            props.status = PROCESS_STATUS.Opened;
        } catch (error: unknown) {
            props.status = PROCESS_STATUS.Error;
            log?.('Failed to open process', {
                error: error,
            });
        }
    };
    const process: Segmenter['process'] = async (input, options) => {
        if (props.status === PROCESS_STATUS.Error) {
            return input;
        }
        props.status = PROCESS_STATUS.Processing;
        try {
            const resultFrame = await postMsg(
                'processed',
                {
                    type: 'process',
                    data: {videoFrame: input, renderOptions: options},
                },
                [input.frame],
            );
            if (props.status === PROCESS_STATUS.Processing) {
                props.status = PROCESS_STATUS.Idle;
            }
            return resultFrame;
        } catch (error: unknown) {
            props.status = PROCESS_STATUS.Error;
            log?.('Error during the processing, return original instead.', {
                error,
            });
            return input;
        }
    };
    const update: Segmenter['update'] = async options => {
        try {
            const backgroundImage =
                options.backgroundImage &&
                (await cloneImageRecord(options.backgroundImage));
            log?.('Update processor config', {config: options});
            const transfer = backgroundImage ? [backgroundImage.image] : [];
            await postMsg(
                'updated',
                {
                    type: 'update',
                    data: {
                        ...options,
                        backgroundImage,
                    },
                },
                transfer,
            );
        } catch (error: unknown) {
            props.status = PROCESS_STATUS.Error;
            log?.('Failed to update process', {
                error: error,
            });
        }
    };
    const close = () => {
        props.status = PROCESS_STATUS.Closing;
        log?.('Close processor');
        void postMsg(undefined, {type: 'close'});
        props.eventHandlers.clear();
        props.status = PROCESS_STATUS.Closed;
    };
    const destroy = async () => {
        props.status = PROCESS_STATUS.Destroying;
        log?.('Destroy processor');
        await postMsg(undefined, {type: 'destroy'});
        props.mediaWorker?.terminate();
        props.mediaWorker = undefined;
        props.status = PROCESS_STATUS.Destroyed;
        return Promise.resolve();
    };

    return {
        get modelAsset() {
            return props.modelAsset;
        },
        set modelAsset(asset) {
            props.modelAsset = asset;
        },
        get status() {
            return props.status;
        },
        get width() {
            return processingWidth;
        },
        get height() {
            return processingHeight;
        },
        subscribe: (event, callback) => {
            let handlers = props.eventHandlers.get(event);
            if (!handlers) {
                props.eventHandlers.set(event, new Set());
                handlers = props.eventHandlers.get(event);
            }
            assert(handlers);
            handlers.add(callback);
            return () => handlers?.delete(callback);
        },
        open,
        update,
        process,
        close,
        destroy,
    };
};
