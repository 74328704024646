import React from 'react';
import cx from 'classnames';

import type {ExtendedSizeModifier} from '@pexip/components';
import {
    FontVariant,
    TextHeading,
    Button,
    Icon,
    IconTypes,
    sizeToPadding,
} from '@pexip/components';

import {TestId} from '../..';

import styles from './PanelHeader.module.scss';

export const PanelHeader: React.FC<
    {
        padding?: ExtendedSizeModifier;
        topPadding?: ExtendedSizeModifier;
        rightPadding?: ExtendedSizeModifier;
        bottomPadding?: ExtendedSizeModifier;
        leftPadding?: ExtendedSizeModifier;
        endContent?: React.ReactElement;
        bottomContent?: React.ReactElement;
        backButtonAriaLabel?: string;
        closeButtonAriaLabel?: string;
        closeButtonTestId?: string;
        backButtonTestId?: string;
        title?: string;
        onBackClick?: () => void;
        onCloseClick?: () => void;
    } & (
        | {onBackClick: () => void; backButtonTestId: string}
        | {onBackClick?: undefined; backButtonTestId?: undefined}
    )
> = ({
    padding,
    topPadding = padding,
    rightPadding = padding,
    bottomPadding = padding,
    leftPadding = padding,
    endContent,
    bottomContent,
    backButtonAriaLabel = 'Go back',
    closeButtonAriaLabel = 'Close panel',
    closeButtonTestId = '',
    backButtonTestId,
    title,
    onCloseClick,
    onBackClick,
}) => (
    <div
        className={cx(
            styles.container,
            topPadding && styles[`${sizeToPadding(topPadding)}Top`],
            rightPadding && styles[`${sizeToPadding(rightPadding)}Right`],
            bottomPadding && styles[`${sizeToPadding(bottomPadding)}Bottom`],
            leftPadding && styles[`${sizeToPadding(leftPadding)}Left`],
        )}
    >
        <div className={styles.topContentContainer}>
            <div className={styles.startContent}>
                {onBackClick && (
                    <Button
                        aria-label={backButtonAriaLabel}
                        onClick={onBackClick}
                        className="mr-1"
                        size="none"
                        variant="translucent"
                        data-testid={backButtonTestId}
                    >
                        <Icon
                            size="small"
                            source={IconTypes.IconArrowLeftRegular}
                        ></Icon>
                    </Button>
                )}
                {title && (
                    <TextHeading
                        data-testid={TestId.PanelHeaderTitle}
                        htmlTag="h5"
                        fontVariant={FontVariant.H5}
                    >
                        {title}
                    </TextHeading>
                )}
            </div>
            {(endContent ?? onCloseClick) && (
                <div className={styles.endContent}>
                    {endContent}
                    {onCloseClick && (
                        <Button
                            aria-label={closeButtonAriaLabel}
                            onClick={onCloseClick}
                            size="compact"
                            modifier="square"
                            variant="translucent"
                            data-testid={closeButtonTestId}
                        >
                            <Icon source={IconTypes.IconClose}></Icon>
                        </Button>
                    )}
                </div>
            )}
        </div>
        {bottomContent}
    </div>
);

export type PanelHeaderProps = React.ComponentProps<typeof PanelHeader>;
