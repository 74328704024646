type ExtractValueTypes<T> = T extends object ? T[keyof T] : never;

export const PROCESSING_WIDTH = 768;
export const PROCESSING_HEIGHT = 432;
export const FOREGROUND_THRESHOLD = 0.5;
export const BACKGROUND_BLUR_AMOUNT = 3; // Between 1 and 20
export const EDGE_BLUR_AMOUNT = 3; // Between 0 and 20
export const MASK_COMBINE_RATIO = 0.5;

/**
 * Process status
 */
export const PROCESS_STATUS = {
    Closed: 'closed',
    Closing: 'closing',
    Destroyed: 'destroyed',
    Destroying: 'destroying',
    Error: 'Error',
    Idle: 'idle',
    New: 'new',
    Opened: 'opened',
    Opening: 'opening',
    Processing: 'processing',
    Updated: 'updated',
    Updating: 'updating',
} as const;
export type ProcessStatus = ExtractValueTypes<typeof PROCESS_STATUS>;

export const RENDER_EFFECT = {
    None: 'none',
    Blur: 'blur',
    Overlay: 'overlay',
} as const;

export type RenderEffects = ExtractValueTypes<typeof RENDER_EFFECT>;

export const RENDERING_EVENTS = {
    /**
     * Triggered when failed to create a context
     */
    ContextCreationError: 'ContextCreationError',
    /**
     * Triggered when context is lost
     */
    ContextLost: 'ContextLost',
    /**
     * Triggered when context is restored
     */
    ContextRestored: 'ContextRestored',
    /**
     * Triggered when there is unknown error
     */
    Error: 'Error',
    /**
     * Triggered when the processor is restarted
     */
    ProcessorRestarted: 'ProcessorRestarted',
    /**
     * Triggered when sampling error occurs
     */
    SamplingError: 'SamplingError',
} as const;

export type RenderingEvents = ExtractValueTypes<typeof RENDERING_EVENTS>;
