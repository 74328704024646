import React, {useEffect, useLayoutEffect} from 'react';
import {setContext} from '@sentry/react';
import {useTranslation} from 'react-i18next';

import {
    FullscreenSpinner,
    NotificationToast,
    Announcer,
} from '@pexip/components';
import {getUserAgentDetails} from '@pexip/media-components';

import {TestId} from '../test/testIds';

import {UserName} from './pages/UserName/UserName.page';
import {MobileGetStarted} from './pages/MobileGetStarted/MobileGetStarted.page';
import {replace, useRouter} from './router';
import {BrandingContext} from './branding/Context';
import {InfinityContextProvider} from './contexts/InfinityContextProvider';
import {UserMediaContextProvider} from './contexts/UserMediaContextProvider';
import {ImageStoreContextProvider} from './contexts/ImageStoreContextProvider';
import {useBrandingLoader} from './branding/useBrandingLoader';
import {getShouldRenderUserNamePageHook} from './hooks/useShouldRenderUserNamePage';
import {useMainRoutes} from './hooks/useMainRoutes';
import {
    mobileGetStartedNextSignal,
    renderUserNamePageSignal,
    renderCustomStepSignal,
} from './signals/MeetingFlow.signals';
import {getShouldRenderMobileGetStartedPageHook} from './hooks/useShouldRenderMobileGetStartedPage';
import {PluginManager} from './plugins';
import {EXPRESS_PATH} from './constants';
import {getBrandingPath} from './branding';
import {PluginForms} from './plugins/components/Form/Forms';
import {PluginPrompts} from './plugins/components/Prompts';
import {CustomStepPage} from './pages/CustomStep.page';
import {getShouldRenderCustomStepPageHook} from './hooks/useShouldRenderCustomStepPage';
import {AppContextProvider} from './contexts/AppContextProvider';
import {logger} from './logger';

const useShouldRenderUserNamePage = getShouldRenderUserNamePageHook(
    renderUserNamePageSignal,
);

const useShouldRenderMobileGetStartedPage =
    getShouldRenderMobileGetStartedPageHook(mobileGetStartedNextSignal);

const IN_MEETING_RE = /^\/m\/[^/]+(\/?|\/?\?[^/]+\/?)$/;

const useRedirectInMeeting = () => {
    useLayoutEffect(() => {
        const fromBase = window.location
            .toString()
            .slice(document.baseURI.replace(/\/$/, '').length);
        if (IN_MEETING_RE.exec(fromBase)) {
            if (window.location.search) {
                replace(fromBase.replace(/\?|\/\?/, `/${EXPRESS_PATH}?`));
                return;
            }
            replace(`${fromBase.replace(/\/$/, '')}/${EXPRESS_PATH}`);
        }
    }, []);
};

export const userAgentDetails = getUserAgentDetails(navigator.userAgent);
export const App: React.FC = () => {
    useRouter();
    const brand = useBrandingLoader();
    const {i18n} = useTranslation();

    if (brand?.plugins) {
        const plugins: typeof brand.plugins = [];
        brand.plugins.forEach(plugin => {
            if (!plugin.src) {
                logger.warn({plugin}, 'A Plugin must have a "src"');
                return;
            }
            if (plugins.find(p => p.id === plugin.id)) {
                logger.warn(
                    {plugin},
                    'Plugin with duplicate id found in branding manifest',
                );
                return;
            }
            const src = plugin.src;
            try {
                new URL(src);
                plugins.push({...plugin, src});
            } catch {
                plugins.push({...plugin, src: getBrandingPath(src)});
            }
        });
        brand.plugins = plugins;
    }
    const matchedRoutes = useMainRoutes();
    const isOauthRedirectHandler = matchedRoutes.some(route =>
        route.key?.toString().includes('oauth-redirect'),
    );
    const is404 = matchedRoutes.some(route => route.props.match.fallback);
    const shouldRenderUserNamePage = useShouldRenderUserNamePage();
    const shouldRenderMobileGetStartedPage =
        useShouldRenderMobileGetStartedPage();

    const useShouldRenderCustomStepPage = getShouldRenderCustomStepPageHook(
        renderCustomStepSignal,
        brand?.customStepConfig,
    );
    const shouldRenderCustomStepPage = useShouldRenderCustomStepPage();

    useRedirectInMeeting();

    useEffect(() => {
        if (brand?.plugins) {
            setContext('Plugins', {
                ...brand.plugins.map(plugin => ({
                    [plugin.src]: {},
                })),
            });
        }
    }, [brand]);

    useEffect(() => {
        document.documentElement.lang = i18n.language;
    }, [i18n.language]);

    const renderPage = () => {
        if (!is404 && shouldRenderMobileGetStartedPage) {
            return <MobileGetStarted />;
        }

        if (!is404 && shouldRenderCustomStepPage) {
            return <CustomStepPage />;
        }

        if (!is404 && !isOauthRedirectHandler && shouldRenderUserNamePage) {
            return <UserName />;
        }

        return matchedRoutes;
    };

    if (!brand) {
        return null;
    }
    /**
     * Since useModalOverlay from \@react-aria/overlays is used in useModal hook to add aria-hidden="true" to all DOM elements
     * that are not part of a modal, the data-live-announcer attribute is used to exclude the announcer div
     * from that logic.
     * This also protects the Announcer from AriaMenu aria-hidden="true" logic.
     */
    return (
        <React.Suspense fallback={<FullscreenSpinner />}>
            <main>
                <Announcer
                    data-live-announcer="true"
                    testId={TestId.Announcer}
                />
                <BrandingContext.Provider value={brand}>
                    <ImageStoreContextProvider>
                        <InfinityContextProvider>
                            <UserMediaContextProvider>
                                <AppContextProvider>
                                    <PluginManager>
                                        <>
                                            <h1 hidden>{document.title}</h1>
                                            {renderPage()}
                                            <PluginForms />
                                            <PluginPrompts />
                                            <NotificationToast />
                                        </>
                                    </PluginManager>
                                </AppContextProvider>
                            </UserMediaContextProvider>
                        </InfinityContextProvider>
                    </ImageStoreContextProvider>
                </BrandingContext.Provider>
            </main>
        </React.Suspense>
    );
};
