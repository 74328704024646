import {useTranslation} from 'react-i18next';
import React, {useState} from 'react';

import type {AriaMenuProps, ButtonProps} from '@pexip/components';
import {Button, Icon, IconTypes, Tooltip} from '@pexip/components';

import {TestId} from '../../../../test/testIds';

import {ToolbarAriaMenu} from './ToolbarAriaMenu.view';

export const MoreMenu: React.FC<
    Omit<AriaMenuProps, 'button'> &
        Pick<ButtonProps, 'children' | 'size'> & {
            isTouch?: boolean;
            isMobile?: boolean;
        }
> = ({
    items,
    onOpenChange,
    isTouch = false,
    isMobile = false,
    children,
    ...props
}) => {
    const [isMoreMenuOpen, setMoreMenuOpen] = useState(false);
    const {t} = useTranslation();
    const moreControlsText = t('meeting.more-controls', 'More controls');
    return (
        <ToolbarAriaMenu
            items={items}
            onOpenChange={isOpen => {
                setMoreMenuOpen(isOpen);
                onOpenChange?.(isOpen);
            }}
            button={
                <Tooltip
                    text={moreControlsText}
                    role="tooltip"
                    position={isTouch ? 'top' : 'right'}
                >
                    <Button
                        isActive={isMoreMenuOpen}
                        aria-label={moreControlsText}
                        modifier="square"
                        data-testid={TestId.ButtonToolbarMoreItems}
                        variant={isTouch ? 'neutral' : 'translucent'}
                        size="large"
                    >
                        <Icon
                            source={
                                isMobile
                                    ? IconTypes.IconMoreHorizontal
                                    : IconTypes.IconMoreVertical
                            }
                            size={isMobile ? 'medium' : undefined}
                        />
                        {children}
                    </Button>
                </Tooltip>
            }
            isTouch={isTouch}
            {...props}
        />
    );
};
