import React from 'react';
import cx from 'classnames';

import type {ChatMessage, ParticipantUuid} from '../../types';
import {TestId} from '../../../test/testIds';

import {ChatMessageView} from './ChatMessage.view';
import {ChatActivityMessage} from './ChatActivityMessage.view';

export const ChatPanelMessages: React.FC<
    {
        messages: ChatMessage[];
        currentUserId: ParticipantUuid;
    } & React.ComponentProps<'div'>
> = ({messages, className, currentUserId}) => (
    <div data-testid={TestId.ChatMessages} className={className}>
        {messages.map((message, index, arr) => {
            const isLastMessage = index + 1 === arr.length;
            const isMessageFromYou = message.userId === currentUserId;
            const isPreviousMessageFromYou =
                index > 0 ? arr[index - 1]?.userId === currentUserId : false;
            const isNextMessageFromYou =
                index + 1 < arr.length
                    ? arr[index + 1]?.userId === currentUserId
                    : false;

            if (message.type === 'activity') {
                return (
                    <ChatActivityMessage
                        key={message.id}
                        className={cx({
                            ['mt-3']: isPreviousMessageFromYou,
                            ['mb-3']: isNextMessageFromYou,
                        })}
                        message={message}
                    />
                );
            }
            return (
                <ChatMessageView
                    isYou={isMessageFromYou}
                    className={cx({
                        ['mt-1']: isPreviousMessageFromYou && isMessageFromYou,
                        ['mb-1']: isLastMessage && isMessageFromYou,
                    })}
                    key={message.id}
                    message={message}
                />
            );
        })}
    </div>
);
