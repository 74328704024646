import React from 'react';
import cx from 'classnames';
import {useTranslation} from 'react-i18next';

import {Avatar, AvatarType, Button, Text, FontVariant} from '@pexip/components';

import {TestId, type InMeetingParticipant} from '../..';

import styles from './ChatNewDirectMessage.module.scss';

export const ChatNewDirectMessage: React.FC<
    React.ComponentProps<'div'> & {
        participant: InMeetingParticipant;
        onMessageClick: (participant: InMeetingParticipant) => void;
    }
> = ({className, participant, onMessageClick}) => {
    const {t} = useTranslation();
    return (
        <div className={cx(styles.container, className)}>
            <div>
                <Avatar
                    colorScheme="dark"
                    displayName={participant.displayName}
                    sizeModifier={'small'}
                    type={AvatarType.Initials}
                    className="mr-2"
                />
                <Text fontVariant={FontVariant.BodyBold}>
                    {participant.displayName}
                </Text>
            </div>
            <Button
                data-testid={TestId.ButtonChatNewDirectMessage}
                onClick={() => onMessageClick(participant)}
                size="compact"
                variant="tertiary"
            >
                {t('meeting.chat-message-participant', 'Message')}
            </Button>
        </div>
    );
};
