'use strict';
export const validateRequestTokenBody = validate11;
const schema12 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        TopLevel: {
            type: 'object',
            additionalProperties: false,
            properties: {
                display_name: {
                    type: 'string',
                    description:
                        'The name by which this participant should be known.',
                },
                call_tag: {
                    type: 'string',
                    description:
                        'An optional call tag to assign to this participant.',
                },
                chosen_idp: {type: 'string'},
                client_id: {type: 'string'},
                sso_token: {type: 'string'},
                sso_redirect_target: {
                    type: 'string',
                    description:
                        "Specifically, the template must contain a magic placeholder __pexip-sso-token__ which will be replaced with the real resumption token once SSO has completed. So, for example https://nightly.pexip.com/path/to/wherever/the/client/wants/__pexip-sso-token__ (the client could also request the token be injected as a query parameter -- the backend doesn't care, or make any assumptions about the URI structure used by the client software)",
                },
                node: {
                    type: 'string',
                    description:
                        '`<FQDN/address that the client is connecting to>`. This helps with some SAML SSO flows.',
                },
                conference_extension: {
                    type: 'string',
                    description: 'Alias of the target conference',
                },
                token: {
                    type: 'string',
                    description: 'One time token for joining a call',
                },
                direct_media: {type: 'boolean'},
                breakout_rooms: {type: 'boolean'},
                supports_direct_chat: {
                    type: 'boolean',
                    description:
                        'Boolean indicating whether the participant can receive and send a direct chat message..',
                },
            },
            required: ['display_name'],
            title: 'TopLevel',
        },
    },
    oneOf: [
        {
            $ref: '#/definitions/request_token/properties/Body/definitions/TopLevel',
        },
    ],
};
const schema13 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        display_name: {
            type: 'string',
            description: 'The name by which this participant should be known.',
        },
        call_tag: {
            type: 'string',
            description: 'An optional call tag to assign to this participant.',
        },
        chosen_idp: {type: 'string'},
        client_id: {type: 'string'},
        sso_token: {type: 'string'},
        sso_redirect_target: {
            type: 'string',
            description:
                "Specifically, the template must contain a magic placeholder __pexip-sso-token__ which will be replaced with the real resumption token once SSO has completed. So, for example https://nightly.pexip.com/path/to/wherever/the/client/wants/__pexip-sso-token__ (the client could also request the token be injected as a query parameter -- the backend doesn't care, or make any assumptions about the URI structure used by the client software)",
        },
        node: {
            type: 'string',
            description:
                '`<FQDN/address that the client is connecting to>`. This helps with some SAML SSO flows.',
        },
        conference_extension: {
            type: 'string',
            description: 'Alias of the target conference',
        },
        token: {
            type: 'string',
            description: 'One time token for joining a call',
        },
        direct_media: {type: 'boolean'},
        breakout_rooms: {type: 'boolean'},
        supports_direct_chat: {
            type: 'boolean',
            description:
                'Boolean indicating whether the participant can receive and send a direct chat message..',
        },
    },
    required: ['display_name'],
    title: 'TopLevel',
};
const func2 = Object.prototype.hasOwnProperty;
function validate11(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                data.display_name === undefined &&
                (missing0 = 'display_name')
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/request_token/properties/Body/definitions/TopLevel/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!func2.call(schema13.properties, key0)) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.display_name !== undefined) {
                        const _errs5 = errors;
                        if (typeof data.display_name !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/display_name',
                                schemaPath:
                                    '#/definitions/request_token/properties/Body/definitions/TopLevel/properties/display_name/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.call_tag !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.call_tag !== 'string') {
                                const err2 = {
                                    instancePath: instancePath + '/call_tag',
                                    schemaPath:
                                        '#/definitions/request_token/properties/Body/definitions/TopLevel/properties/call_tag/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err2];
                                } else {
                                    vErrors.push(err2);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                        if (valid2) {
                            if (data.chosen_idp !== undefined) {
                                const _errs9 = errors;
                                if (typeof data.chosen_idp !== 'string') {
                                    const err3 = {
                                        instancePath:
                                            instancePath + '/chosen_idp',
                                        schemaPath:
                                            '#/definitions/request_token/properties/Body/definitions/TopLevel/properties/chosen_idp/type',
                                        keyword: 'type',
                                        params: {type: 'string'},
                                        message: 'must be string',
                                    };
                                    if (vErrors === null) {
                                        vErrors = [err3];
                                    } else {
                                        vErrors.push(err3);
                                    }
                                    errors++;
                                }
                                var valid2 = _errs9 === errors;
                            } else {
                                var valid2 = true;
                            }
                            if (valid2) {
                                if (data.client_id !== undefined) {
                                    const _errs11 = errors;
                                    if (typeof data.client_id !== 'string') {
                                        const err4 = {
                                            instancePath:
                                                instancePath + '/client_id',
                                            schemaPath:
                                                '#/definitions/request_token/properties/Body/definitions/TopLevel/properties/client_id/type',
                                            keyword: 'type',
                                            params: {type: 'string'},
                                            message: 'must be string',
                                        };
                                        if (vErrors === null) {
                                            vErrors = [err4];
                                        } else {
                                            vErrors.push(err4);
                                        }
                                        errors++;
                                    }
                                    var valid2 = _errs11 === errors;
                                } else {
                                    var valid2 = true;
                                }
                                if (valid2) {
                                    if (data.sso_token !== undefined) {
                                        const _errs13 = errors;
                                        if (
                                            typeof data.sso_token !== 'string'
                                        ) {
                                            const err5 = {
                                                instancePath:
                                                    instancePath + '/sso_token',
                                                schemaPath:
                                                    '#/definitions/request_token/properties/Body/definitions/TopLevel/properties/sso_token/type',
                                                keyword: 'type',
                                                params: {type: 'string'},
                                                message: 'must be string',
                                            };
                                            if (vErrors === null) {
                                                vErrors = [err5];
                                            } else {
                                                vErrors.push(err5);
                                            }
                                            errors++;
                                        }
                                        var valid2 = _errs13 === errors;
                                    } else {
                                        var valid2 = true;
                                    }
                                    if (valid2) {
                                        if (
                                            data.sso_redirect_target !==
                                            undefined
                                        ) {
                                            const _errs15 = errors;
                                            if (
                                                typeof data.sso_redirect_target !==
                                                'string'
                                            ) {
                                                const err6 = {
                                                    instancePath:
                                                        instancePath +
                                                        '/sso_redirect_target',
                                                    schemaPath:
                                                        '#/definitions/request_token/properties/Body/definitions/TopLevel/properties/sso_redirect_target/type',
                                                    keyword: 'type',
                                                    params: {type: 'string'},
                                                    message: 'must be string',
                                                };
                                                if (vErrors === null) {
                                                    vErrors = [err6];
                                                } else {
                                                    vErrors.push(err6);
                                                }
                                                errors++;
                                            }
                                            var valid2 = _errs15 === errors;
                                        } else {
                                            var valid2 = true;
                                        }
                                        if (valid2) {
                                            if (data.node !== undefined) {
                                                const _errs17 = errors;
                                                if (
                                                    typeof data.node !==
                                                    'string'
                                                ) {
                                                    const err7 = {
                                                        instancePath:
                                                            instancePath +
                                                            '/node',
                                                        schemaPath:
                                                            '#/definitions/request_token/properties/Body/definitions/TopLevel/properties/node/type',
                                                        keyword: 'type',
                                                        params: {
                                                            type: 'string',
                                                        },
                                                        message:
                                                            'must be string',
                                                    };
                                                    if (vErrors === null) {
                                                        vErrors = [err7];
                                                    } else {
                                                        vErrors.push(err7);
                                                    }
                                                    errors++;
                                                }
                                                var valid2 = _errs17 === errors;
                                            } else {
                                                var valid2 = true;
                                            }
                                            if (valid2) {
                                                if (
                                                    data.conference_extension !==
                                                    undefined
                                                ) {
                                                    const _errs19 = errors;
                                                    if (
                                                        typeof data.conference_extension !==
                                                        'string'
                                                    ) {
                                                        const err8 = {
                                                            instancePath:
                                                                instancePath +
                                                                '/conference_extension',
                                                            schemaPath:
                                                                '#/definitions/request_token/properties/Body/definitions/TopLevel/properties/conference_extension/type',
                                                            keyword: 'type',
                                                            params: {
                                                                type: 'string',
                                                            },
                                                            message:
                                                                'must be string',
                                                        };
                                                        if (vErrors === null) {
                                                            vErrors = [err8];
                                                        } else {
                                                            vErrors.push(err8);
                                                        }
                                                        errors++;
                                                    }
                                                    var valid2 =
                                                        _errs19 === errors;
                                                } else {
                                                    var valid2 = true;
                                                }
                                                if (valid2) {
                                                    if (
                                                        data.token !== undefined
                                                    ) {
                                                        const _errs21 = errors;
                                                        if (
                                                            typeof data.token !==
                                                            'string'
                                                        ) {
                                                            const err9 = {
                                                                instancePath:
                                                                    instancePath +
                                                                    '/token',
                                                                schemaPath:
                                                                    '#/definitions/request_token/properties/Body/definitions/TopLevel/properties/token/type',
                                                                keyword: 'type',
                                                                params: {
                                                                    type: 'string',
                                                                },
                                                                message:
                                                                    'must be string',
                                                            };
                                                            if (
                                                                vErrors === null
                                                            ) {
                                                                vErrors = [
                                                                    err9,
                                                                ];
                                                            } else {
                                                                vErrors.push(
                                                                    err9,
                                                                );
                                                            }
                                                            errors++;
                                                        }
                                                        var valid2 =
                                                            _errs21 === errors;
                                                    } else {
                                                        var valid2 = true;
                                                    }
                                                    if (valid2) {
                                                        if (
                                                            data.direct_media !==
                                                            undefined
                                                        ) {
                                                            const _errs23 =
                                                                errors;
                                                            if (
                                                                typeof data.direct_media !==
                                                                'boolean'
                                                            ) {
                                                                const err10 = {
                                                                    instancePath:
                                                                        instancePath +
                                                                        '/direct_media',
                                                                    schemaPath:
                                                                        '#/definitions/request_token/properties/Body/definitions/TopLevel/properties/direct_media/type',
                                                                    keyword:
                                                                        'type',
                                                                    params: {
                                                                        type: 'boolean',
                                                                    },
                                                                    message:
                                                                        'must be boolean',
                                                                };
                                                                if (
                                                                    vErrors ===
                                                                    null
                                                                ) {
                                                                    vErrors = [
                                                                        err10,
                                                                    ];
                                                                } else {
                                                                    vErrors.push(
                                                                        err10,
                                                                    );
                                                                }
                                                                errors++;
                                                            }
                                                            var valid2 =
                                                                _errs23 ===
                                                                errors;
                                                        } else {
                                                            var valid2 = true;
                                                        }
                                                        if (valid2) {
                                                            if (
                                                                data.breakout_rooms !==
                                                                undefined
                                                            ) {
                                                                const _errs25 =
                                                                    errors;
                                                                if (
                                                                    typeof data.breakout_rooms !==
                                                                    'boolean'
                                                                ) {
                                                                    const err11 =
                                                                        {
                                                                            instancePath:
                                                                                instancePath +
                                                                                '/breakout_rooms',
                                                                            schemaPath:
                                                                                '#/definitions/request_token/properties/Body/definitions/TopLevel/properties/breakout_rooms/type',
                                                                            keyword:
                                                                                'type',
                                                                            params: {
                                                                                type: 'boolean',
                                                                            },
                                                                            message:
                                                                                'must be boolean',
                                                                        };
                                                                    if (
                                                                        vErrors ===
                                                                        null
                                                                    ) {
                                                                        vErrors =
                                                                            [
                                                                                err11,
                                                                            ];
                                                                    } else {
                                                                        vErrors.push(
                                                                            err11,
                                                                        );
                                                                    }
                                                                    errors++;
                                                                }
                                                                var valid2 =
                                                                    _errs25 ===
                                                                    errors;
                                                            } else {
                                                                var valid2 =
                                                                    true;
                                                            }
                                                            if (valid2) {
                                                                if (
                                                                    data.supports_direct_chat !==
                                                                    undefined
                                                                ) {
                                                                    const _errs27 =
                                                                        errors;
                                                                    if (
                                                                        typeof data.supports_direct_chat !==
                                                                        'boolean'
                                                                    ) {
                                                                        const err12 =
                                                                            {
                                                                                instancePath:
                                                                                    instancePath +
                                                                                    '/supports_direct_chat',
                                                                                schemaPath:
                                                                                    '#/definitions/request_token/properties/Body/definitions/TopLevel/properties/supports_direct_chat/type',
                                                                                keyword:
                                                                                    'type',
                                                                                params: {
                                                                                    type: 'boolean',
                                                                                },
                                                                                message:
                                                                                    'must be boolean',
                                                                            };
                                                                        if (
                                                                            vErrors ===
                                                                            null
                                                                        ) {
                                                                            vErrors =
                                                                                [
                                                                                    err12,
                                                                                ];
                                                                        } else {
                                                                            vErrors.push(
                                                                                err12,
                                                                            );
                                                                        }
                                                                        errors++;
                                                                    }
                                                                    var valid2 =
                                                                        _errs27 ===
                                                                        errors;
                                                                } else {
                                                                    var valid2 =
                                                                        true;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        } else {
            const err13 = {
                instancePath,
                schemaPath:
                    '#/definitions/request_token/properties/Body/definitions/TopLevel/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err13];
            } else {
                vErrors.push(err13);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err14 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err14];
        } else {
            vErrors.push(err14);
        }
        errors++;
        validate11.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate11.errors = vErrors;
    return errors === 0;
}
export const validateRequestToken200 = validate12;
const schema14 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '200': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success'],
                },
                result: {
                    $ref: '#/definitions/request_token/properties/200/definitions/Result',
                },
            },
            required: ['status', 'result'],
            title: 'Status200',
        },
        Result: {
            type: 'object',
            description:
                'This result contains the token (abridged in the above example) to use to authenticate all future requests, and an expiry time (in seconds) after which this token becomes invalid.',
            additionalProperties: false,
            properties: {
                allow_1080p: {type: 'boolean'},
                bandwidth_in: {
                    type: 'number',
                    description: 'Max bandwidth-in for the conference',
                },
                bandwidth_out: {
                    type: 'number',
                    description: 'Max bandwidth-out for the conference',
                },
                call_tag: {
                    type: 'string',
                    description:
                        'An optional call tag that is assigned to this participant.',
                },
                call_type: {
                    type: 'string',
                    enum: ['video', 'video-only', 'audio'],
                    description: 'Type of the call.',
                },
                conference_name: {
                    type: 'string',
                    description: 'Name of the conference',
                },
                token: {
                    type: 'string',
                    description:
                        'The authentication token for future requests.',
                },
                expires: {
                    type: 'string',
                    description:
                        'Validity lifetime in seconds. Use refresh_token to obtain an updated token.',
                },
                fecc_enabled: {type: 'boolean'},
                guests_can_present: {type: 'boolean'},
                idp_uuid: {type: 'string'},
                participant_uuid: {
                    type: 'string',
                    description:
                        'The uuid associated with this newly created participant. It is used to identify this participant in the participant list.',
                },
                version: {
                    $ref: '#/definitions/request_token/properties/200/definitions/Version',
                },
                role: {
                    type: 'string',
                    enum: ['HOST', 'GUEST'],
                    description:
                        'Whether the participant is connecting as a `HOST` or a `GUEST`.',
                },
                route_via_registrar: {type: 'boolean'},
                rtmp_enabled: {type: 'boolean'},
                rtsp_enabled: {type: 'boolean'},
                trickle_ice_enabled: {type: 'boolean'},
                vp9_enabled: {type: 'boolean'},
                chat_enabled: {
                    type: 'boolean',
                    description:
                        'true = chat is enabled; false = chat is not enabled.',
                },
                service_type: {
                    type: 'string',
                    enum: [
                        'conference',
                        'gateway',
                        'test_call',
                        'lecture',
                        'media_playback',
                    ],
                    description:
                        'Either `conference`, `gateway` or `test_call` depending on whether this is a VMR, gateway or Test Call Service respectively.',
                },
                stun: {
                    type: 'array',
                    description:
                        'STUN server configuration from the Pexip Conferencing Node.',
                    items: {
                        $ref: '#/definitions/request_token/properties/200/definitions/Stun',
                    },
                },
                turn: {
                    type: 'array',
                    description:
                        'TURN server configuration from the Pexip Conferencing Node.',
                    items: {
                        $ref: '#/definitions/request_token/properties/200/definitions/Turn',
                    },
                },
                display_name: {
                    type: 'string',
                    description: 'Echoes the display name in the request.',
                },
                analytics_enabled: {
                    type: 'boolean',
                    description:
                        'Whether the Automatically send deployment and usage statistics to Pexip global setting has been enabled on the Pexip installation.',
                },
                current_service_type: {
                    type: 'string',
                    description:
                        'The service type this user is connecting into. May be `conference`, `gateway` or `test_call` as for service_type if directly connecting in. May also be `waiting_room` if waiting to be allowed to join a locked conference, or `ivr` if on the PIN entry screen.',
                    enum: [
                        'conference',
                        'gateway',
                        'test_call',
                        'waiting_room',
                        'ivr',
                        'lecture',
                        'media_playback',
                    ],
                },
                direct_media: {
                    type: 'boolean',
                    description: 'Reflects if vmr uses direct media.',
                },
                service_name: {type: 'string'},
                pex_datachannel_id: {type: 'number'},
                client_stats_update_interval: {type: 'number'},
                use_relay_candidates_only: {type: 'boolean'},
                breakout_rooms: {
                    type: 'boolean',
                    description: 'Reflects if vmr supports breakout rooms.',
                },
            },
            required: [
                'token',
                'expires',
                'participant_uuid',
                'version',
                'role',
                'display_name',
                'current_service_type',
            ],
            title: 'Result',
        },
        Version: {
            type: 'object',
            description:
                'This result contains the token (abridged in the above example) to use to authenticate all future requests, and an expiry time (in seconds) after which this token becomes invalid.',
            additionalProperties: false,
            properties: {
                version_id: {type: 'string'},
                pseudo_version: {type: 'string'},
            },
            required: [],
            title: 'Version',
        },
        Stun: {
            type: 'object',
            description:
                'STUN server configuration from the Pexip Conferencing Node.',
            additionalProperties: false,
            properties: {url: {type: 'string'}},
            required: ['url'],
            title: 'Stun',
        },
        Turn: {
            type: 'object',
            description:
                'TURN server configuration from the Pexip Conferencing Node.',
            additionalProperties: false,
            properties: {
                credential: {type: 'string'},
                urls: {type: 'array', items: {type: 'string'}},
                username: {type: 'string'},
            },
            required: ['urls'],
            title: 'Turn',
        },
    },
    oneOf: [
        {$ref: '#/definitions/request_token/properties/200/definitions/200'},
    ],
};
const schema15 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success'],
        },
        result: {
            $ref: '#/definitions/request_token/properties/200/definitions/Result',
        },
    },
    required: ['status', 'result'],
    title: 'Status200',
};
const schema16 = {
    type: 'object',
    description:
        'This result contains the token (abridged in the above example) to use to authenticate all future requests, and an expiry time (in seconds) after which this token becomes invalid.',
    additionalProperties: false,
    properties: {
        allow_1080p: {type: 'boolean'},
        bandwidth_in: {
            type: 'number',
            description: 'Max bandwidth-in for the conference',
        },
        bandwidth_out: {
            type: 'number',
            description: 'Max bandwidth-out for the conference',
        },
        call_tag: {
            type: 'string',
            description:
                'An optional call tag that is assigned to this participant.',
        },
        call_type: {
            type: 'string',
            enum: ['video', 'video-only', 'audio'],
            description: 'Type of the call.',
        },
        conference_name: {
            type: 'string',
            description: 'Name of the conference',
        },
        token: {
            type: 'string',
            description: 'The authentication token for future requests.',
        },
        expires: {
            type: 'string',
            description:
                'Validity lifetime in seconds. Use refresh_token to obtain an updated token.',
        },
        fecc_enabled: {type: 'boolean'},
        guests_can_present: {type: 'boolean'},
        idp_uuid: {type: 'string'},
        participant_uuid: {
            type: 'string',
            description:
                'The uuid associated with this newly created participant. It is used to identify this participant in the participant list.',
        },
        version: {
            $ref: '#/definitions/request_token/properties/200/definitions/Version',
        },
        role: {
            type: 'string',
            enum: ['HOST', 'GUEST'],
            description:
                'Whether the participant is connecting as a `HOST` or a `GUEST`.',
        },
        route_via_registrar: {type: 'boolean'},
        rtmp_enabled: {type: 'boolean'},
        rtsp_enabled: {type: 'boolean'},
        trickle_ice_enabled: {type: 'boolean'},
        vp9_enabled: {type: 'boolean'},
        chat_enabled: {
            type: 'boolean',
            description: 'true = chat is enabled; false = chat is not enabled.',
        },
        service_type: {
            type: 'string',
            enum: [
                'conference',
                'gateway',
                'test_call',
                'lecture',
                'media_playback',
            ],
            description:
                'Either `conference`, `gateway` or `test_call` depending on whether this is a VMR, gateway or Test Call Service respectively.',
        },
        stun: {
            type: 'array',
            description:
                'STUN server configuration from the Pexip Conferencing Node.',
            items: {
                $ref: '#/definitions/request_token/properties/200/definitions/Stun',
            },
        },
        turn: {
            type: 'array',
            description:
                'TURN server configuration from the Pexip Conferencing Node.',
            items: {
                $ref: '#/definitions/request_token/properties/200/definitions/Turn',
            },
        },
        display_name: {
            type: 'string',
            description: 'Echoes the display name in the request.',
        },
        analytics_enabled: {
            type: 'boolean',
            description:
                'Whether the Automatically send deployment and usage statistics to Pexip global setting has been enabled on the Pexip installation.',
        },
        current_service_type: {
            type: 'string',
            description:
                'The service type this user is connecting into. May be `conference`, `gateway` or `test_call` as for service_type if directly connecting in. May also be `waiting_room` if waiting to be allowed to join a locked conference, or `ivr` if on the PIN entry screen.',
            enum: [
                'conference',
                'gateway',
                'test_call',
                'waiting_room',
                'ivr',
                'lecture',
                'media_playback',
            ],
        },
        direct_media: {
            type: 'boolean',
            description: 'Reflects if vmr uses direct media.',
        },
        service_name: {type: 'string'},
        pex_datachannel_id: {type: 'number'},
        client_stats_update_interval: {type: 'number'},
        use_relay_candidates_only: {type: 'boolean'},
        breakout_rooms: {
            type: 'boolean',
            description: 'Reflects if vmr supports breakout rooms.',
        },
    },
    required: [
        'token',
        'expires',
        'participant_uuid',
        'version',
        'role',
        'display_name',
        'current_service_type',
    ],
    title: 'Result',
};
const schema17 = {
    type: 'object',
    description:
        'This result contains the token (abridged in the above example) to use to authenticate all future requests, and an expiry time (in seconds) after which this token becomes invalid.',
    additionalProperties: false,
    properties: {
        version_id: {type: 'string'},
        pseudo_version: {type: 'string'},
    },
    required: [],
    title: 'Version',
};
const schema18 = {
    type: 'object',
    description: 'STUN server configuration from the Pexip Conferencing Node.',
    additionalProperties: false,
    properties: {url: {type: 'string'}},
    required: ['url'],
    title: 'Stun',
};
const schema19 = {
    type: 'object',
    description: 'TURN server configuration from the Pexip Conferencing Node.',
    additionalProperties: false,
    properties: {
        credential: {type: 'string'},
        urls: {type: 'array', items: {type: 'string'}},
        username: {type: 'string'},
    },
    required: ['urls'],
    title: 'Turn',
};
function validate14(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.token === undefined && (missing0 = 'token')) ||
                (data.expires === undefined && (missing0 = 'expires')) ||
                (data.participant_uuid === undefined &&
                    (missing0 = 'participant_uuid')) ||
                (data.version === undefined && (missing0 = 'version')) ||
                (data.role === undefined && (missing0 = 'role')) ||
                (data.display_name === undefined &&
                    (missing0 = 'display_name')) ||
                (data.current_service_type === undefined &&
                    (missing0 = 'current_service_type'))
            ) {
                validate14.errors = [
                    {
                        instancePath,
                        schemaPath: '#/required',
                        keyword: 'required',
                        params: {missingProperty: missing0},
                        message:
                            "must have required property '" + missing0 + "'",
                    },
                ];
                return false;
            } else {
                const _errs1 = errors;
                for (const key0 in data) {
                    if (!func2.call(schema16.properties, key0)) {
                        delete data[key0];
                    }
                }
                if (_errs1 === errors) {
                    if (data.allow_1080p !== undefined) {
                        const _errs2 = errors;
                        if (typeof data.allow_1080p !== 'boolean') {
                            validate14.errors = [
                                {
                                    instancePath: instancePath + '/allow_1080p',
                                    schemaPath: '#/properties/allow_1080p/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                },
                            ];
                            return false;
                        }
                        var valid0 = _errs2 === errors;
                    } else {
                        var valid0 = true;
                    }
                    if (valid0) {
                        if (data.bandwidth_in !== undefined) {
                            const _errs4 = errors;
                            if (!(typeof data.bandwidth_in == 'number')) {
                                validate14.errors = [
                                    {
                                        instancePath:
                                            instancePath + '/bandwidth_in',
                                        schemaPath:
                                            '#/properties/bandwidth_in/type',
                                        keyword: 'type',
                                        params: {type: 'number'},
                                        message: 'must be number',
                                    },
                                ];
                                return false;
                            }
                            var valid0 = _errs4 === errors;
                        } else {
                            var valid0 = true;
                        }
                        if (valid0) {
                            if (data.bandwidth_out !== undefined) {
                                const _errs6 = errors;
                                if (!(typeof data.bandwidth_out == 'number')) {
                                    validate14.errors = [
                                        {
                                            instancePath:
                                                instancePath + '/bandwidth_out',
                                            schemaPath:
                                                '#/properties/bandwidth_out/type',
                                            keyword: 'type',
                                            params: {type: 'number'},
                                            message: 'must be number',
                                        },
                                    ];
                                    return false;
                                }
                                var valid0 = _errs6 === errors;
                            } else {
                                var valid0 = true;
                            }
                            if (valid0) {
                                if (data.call_tag !== undefined) {
                                    const _errs8 = errors;
                                    if (typeof data.call_tag !== 'string') {
                                        validate14.errors = [
                                            {
                                                instancePath:
                                                    instancePath + '/call_tag',
                                                schemaPath:
                                                    '#/properties/call_tag/type',
                                                keyword: 'type',
                                                params: {type: 'string'},
                                                message: 'must be string',
                                            },
                                        ];
                                        return false;
                                    }
                                    var valid0 = _errs8 === errors;
                                } else {
                                    var valid0 = true;
                                }
                                if (valid0) {
                                    if (data.call_type !== undefined) {
                                        let data4 = data.call_type;
                                        const _errs10 = errors;
                                        if (typeof data4 !== 'string') {
                                            validate14.errors = [
                                                {
                                                    instancePath:
                                                        instancePath +
                                                        '/call_type',
                                                    schemaPath:
                                                        '#/properties/call_type/type',
                                                    keyword: 'type',
                                                    params: {type: 'string'},
                                                    message: 'must be string',
                                                },
                                            ];
                                            return false;
                                        }
                                        if (
                                            !(
                                                data4 === 'video' ||
                                                data4 === 'video-only' ||
                                                data4 === 'audio'
                                            )
                                        ) {
                                            validate14.errors = [
                                                {
                                                    instancePath:
                                                        instancePath +
                                                        '/call_type',
                                                    schemaPath:
                                                        '#/properties/call_type/enum',
                                                    keyword: 'enum',
                                                    params: {
                                                        allowedValues:
                                                            schema16.properties
                                                                .call_type.enum,
                                                    },
                                                    message:
                                                        'must be equal to one of the allowed values',
                                                },
                                            ];
                                            return false;
                                        }
                                        var valid0 = _errs10 === errors;
                                    } else {
                                        var valid0 = true;
                                    }
                                    if (valid0) {
                                        if (
                                            data.conference_name !== undefined
                                        ) {
                                            const _errs12 = errors;
                                            if (
                                                typeof data.conference_name !==
                                                'string'
                                            ) {
                                                validate14.errors = [
                                                    {
                                                        instancePath:
                                                            instancePath +
                                                            '/conference_name',
                                                        schemaPath:
                                                            '#/properties/conference_name/type',
                                                        keyword: 'type',
                                                        params: {
                                                            type: 'string',
                                                        },
                                                        message:
                                                            'must be string',
                                                    },
                                                ];
                                                return false;
                                            }
                                            var valid0 = _errs12 === errors;
                                        } else {
                                            var valid0 = true;
                                        }
                                        if (valid0) {
                                            if (data.token !== undefined) {
                                                const _errs14 = errors;
                                                if (
                                                    typeof data.token !==
                                                    'string'
                                                ) {
                                                    validate14.errors = [
                                                        {
                                                            instancePath:
                                                                instancePath +
                                                                '/token',
                                                            schemaPath:
                                                                '#/properties/token/type',
                                                            keyword: 'type',
                                                            params: {
                                                                type: 'string',
                                                            },
                                                            message:
                                                                'must be string',
                                                        },
                                                    ];
                                                    return false;
                                                }
                                                var valid0 = _errs14 === errors;
                                            } else {
                                                var valid0 = true;
                                            }
                                            if (valid0) {
                                                if (
                                                    data.expires !== undefined
                                                ) {
                                                    const _errs16 = errors;
                                                    if (
                                                        typeof data.expires !==
                                                        'string'
                                                    ) {
                                                        validate14.errors = [
                                                            {
                                                                instancePath:
                                                                    instancePath +
                                                                    '/expires',
                                                                schemaPath:
                                                                    '#/properties/expires/type',
                                                                keyword: 'type',
                                                                params: {
                                                                    type: 'string',
                                                                },
                                                                message:
                                                                    'must be string',
                                                            },
                                                        ];
                                                        return false;
                                                    }
                                                    var valid0 =
                                                        _errs16 === errors;
                                                } else {
                                                    var valid0 = true;
                                                }
                                                if (valid0) {
                                                    if (
                                                        data.fecc_enabled !==
                                                        undefined
                                                    ) {
                                                        const _errs18 = errors;
                                                        if (
                                                            typeof data.fecc_enabled !==
                                                            'boolean'
                                                        ) {
                                                            validate14.errors =
                                                                [
                                                                    {
                                                                        instancePath:
                                                                            instancePath +
                                                                            '/fecc_enabled',
                                                                        schemaPath:
                                                                            '#/properties/fecc_enabled/type',
                                                                        keyword:
                                                                            'type',
                                                                        params: {
                                                                            type: 'boolean',
                                                                        },
                                                                        message:
                                                                            'must be boolean',
                                                                    },
                                                                ];
                                                            return false;
                                                        }
                                                        var valid0 =
                                                            _errs18 === errors;
                                                    } else {
                                                        var valid0 = true;
                                                    }
                                                    if (valid0) {
                                                        if (
                                                            data.guests_can_present !==
                                                            undefined
                                                        ) {
                                                            const _errs20 =
                                                                errors;
                                                            if (
                                                                typeof data.guests_can_present !==
                                                                'boolean'
                                                            ) {
                                                                validate14.errors =
                                                                    [
                                                                        {
                                                                            instancePath:
                                                                                instancePath +
                                                                                '/guests_can_present',
                                                                            schemaPath:
                                                                                '#/properties/guests_can_present/type',
                                                                            keyword:
                                                                                'type',
                                                                            params: {
                                                                                type: 'boolean',
                                                                            },
                                                                            message:
                                                                                'must be boolean',
                                                                        },
                                                                    ];
                                                                return false;
                                                            }
                                                            var valid0 =
                                                                _errs20 ===
                                                                errors;
                                                        } else {
                                                            var valid0 = true;
                                                        }
                                                        if (valid0) {
                                                            if (
                                                                data.idp_uuid !==
                                                                undefined
                                                            ) {
                                                                const _errs22 =
                                                                    errors;
                                                                if (
                                                                    typeof data.idp_uuid !==
                                                                    'string'
                                                                ) {
                                                                    validate14.errors =
                                                                        [
                                                                            {
                                                                                instancePath:
                                                                                    instancePath +
                                                                                    '/idp_uuid',
                                                                                schemaPath:
                                                                                    '#/properties/idp_uuid/type',
                                                                                keyword:
                                                                                    'type',
                                                                                params: {
                                                                                    type: 'string',
                                                                                },
                                                                                message:
                                                                                    'must be string',
                                                                            },
                                                                        ];
                                                                    return false;
                                                                }
                                                                var valid0 =
                                                                    _errs22 ===
                                                                    errors;
                                                            } else {
                                                                var valid0 =
                                                                    true;
                                                            }
                                                            if (valid0) {
                                                                if (
                                                                    data.participant_uuid !==
                                                                    undefined
                                                                ) {
                                                                    const _errs24 =
                                                                        errors;
                                                                    if (
                                                                        typeof data.participant_uuid !==
                                                                        'string'
                                                                    ) {
                                                                        validate14.errors =
                                                                            [
                                                                                {
                                                                                    instancePath:
                                                                                        instancePath +
                                                                                        '/participant_uuid',
                                                                                    schemaPath:
                                                                                        '#/properties/participant_uuid/type',
                                                                                    keyword:
                                                                                        'type',
                                                                                    params: {
                                                                                        type: 'string',
                                                                                    },
                                                                                    message:
                                                                                        'must be string',
                                                                                },
                                                                            ];
                                                                        return false;
                                                                    }
                                                                    var valid0 =
                                                                        _errs24 ===
                                                                        errors;
                                                                } else {
                                                                    var valid0 =
                                                                        true;
                                                                }
                                                                if (valid0) {
                                                                    if (
                                                                        data.version !==
                                                                        undefined
                                                                    ) {
                                                                        let data12 =
                                                                            data.version;
                                                                        const _errs26 =
                                                                            errors;
                                                                        const _errs27 =
                                                                            errors;
                                                                        if (
                                                                            errors ===
                                                                            _errs27
                                                                        ) {
                                                                            if (
                                                                                data12 &&
                                                                                typeof data12 ==
                                                                                    'object' &&
                                                                                !Array.isArray(
                                                                                    data12,
                                                                                )
                                                                            ) {
                                                                                const _errs29 =
                                                                                    errors;
                                                                                for (const key1 in data12) {
                                                                                    if (
                                                                                        !(
                                                                                            key1 ===
                                                                                                'version_id' ||
                                                                                            key1 ===
                                                                                                'pseudo_version'
                                                                                        )
                                                                                    ) {
                                                                                        delete data12[
                                                                                            key1
                                                                                        ];
                                                                                    }
                                                                                }
                                                                                if (
                                                                                    _errs29 ===
                                                                                    errors
                                                                                ) {
                                                                                    if (
                                                                                        data12.version_id !==
                                                                                        undefined
                                                                                    ) {
                                                                                        const _errs30 =
                                                                                            errors;
                                                                                        if (
                                                                                            typeof data12.version_id !==
                                                                                            'string'
                                                                                        ) {
                                                                                            validate14.errors =
                                                                                                [
                                                                                                    {
                                                                                                        instancePath:
                                                                                                            instancePath +
                                                                                                            '/version/version_id',
                                                                                                        schemaPath:
                                                                                                            '#/definitions/request_token/properties/200/definitions/Version/properties/version_id/type',
                                                                                                        keyword:
                                                                                                            'type',
                                                                                                        params: {
                                                                                                            type: 'string',
                                                                                                        },
                                                                                                        message:
                                                                                                            'must be string',
                                                                                                    },
                                                                                                ];
                                                                                            return false;
                                                                                        }
                                                                                        var valid2 =
                                                                                            _errs30 ===
                                                                                            errors;
                                                                                    } else {
                                                                                        var valid2 =
                                                                                            true;
                                                                                    }
                                                                                    if (
                                                                                        valid2
                                                                                    ) {
                                                                                        if (
                                                                                            data12.pseudo_version !==
                                                                                            undefined
                                                                                        ) {
                                                                                            const _errs32 =
                                                                                                errors;
                                                                                            if (
                                                                                                typeof data12.pseudo_version !==
                                                                                                'string'
                                                                                            ) {
                                                                                                validate14.errors =
                                                                                                    [
                                                                                                        {
                                                                                                            instancePath:
                                                                                                                instancePath +
                                                                                                                '/version/pseudo_version',
                                                                                                            schemaPath:
                                                                                                                '#/definitions/request_token/properties/200/definitions/Version/properties/pseudo_version/type',
                                                                                                            keyword:
                                                                                                                'type',
                                                                                                            params: {
                                                                                                                type: 'string',
                                                                                                            },
                                                                                                            message:
                                                                                                                'must be string',
                                                                                                        },
                                                                                                    ];
                                                                                                return false;
                                                                                            }
                                                                                            var valid2 =
                                                                                                _errs32 ===
                                                                                                errors;
                                                                                        } else {
                                                                                            var valid2 =
                                                                                                true;
                                                                                        }
                                                                                    }
                                                                                }
                                                                            } else {
                                                                                validate14.errors =
                                                                                    [
                                                                                        {
                                                                                            instancePath:
                                                                                                instancePath +
                                                                                                '/version',
                                                                                            schemaPath:
                                                                                                '#/definitions/request_token/properties/200/definitions/Version/type',
                                                                                            keyword:
                                                                                                'type',
                                                                                            params: {
                                                                                                type: 'object',
                                                                                            },
                                                                                            message:
                                                                                                'must be object',
                                                                                        },
                                                                                    ];
                                                                                return false;
                                                                            }
                                                                        }
                                                                        var valid0 =
                                                                            _errs26 ===
                                                                            errors;
                                                                    } else {
                                                                        var valid0 =
                                                                            true;
                                                                    }
                                                                    if (
                                                                        valid0
                                                                    ) {
                                                                        if (
                                                                            data.role !==
                                                                            undefined
                                                                        ) {
                                                                            let data15 =
                                                                                data.role;
                                                                            const _errs34 =
                                                                                errors;
                                                                            if (
                                                                                typeof data15 !==
                                                                                'string'
                                                                            ) {
                                                                                validate14.errors =
                                                                                    [
                                                                                        {
                                                                                            instancePath:
                                                                                                instancePath +
                                                                                                '/role',
                                                                                            schemaPath:
                                                                                                '#/properties/role/type',
                                                                                            keyword:
                                                                                                'type',
                                                                                            params: {
                                                                                                type: 'string',
                                                                                            },
                                                                                            message:
                                                                                                'must be string',
                                                                                        },
                                                                                    ];
                                                                                return false;
                                                                            }
                                                                            if (
                                                                                !(
                                                                                    data15 ===
                                                                                        'HOST' ||
                                                                                    data15 ===
                                                                                        'GUEST'
                                                                                )
                                                                            ) {
                                                                                validate14.errors =
                                                                                    [
                                                                                        {
                                                                                            instancePath:
                                                                                                instancePath +
                                                                                                '/role',
                                                                                            schemaPath:
                                                                                                '#/properties/role/enum',
                                                                                            keyword:
                                                                                                'enum',
                                                                                            params: {
                                                                                                allowedValues:
                                                                                                    schema16
                                                                                                        .properties
                                                                                                        .role
                                                                                                        .enum,
                                                                                            },
                                                                                            message:
                                                                                                'must be equal to one of the allowed values',
                                                                                        },
                                                                                    ];
                                                                                return false;
                                                                            }
                                                                            var valid0 =
                                                                                _errs34 ===
                                                                                errors;
                                                                        } else {
                                                                            var valid0 =
                                                                                true;
                                                                        }
                                                                        if (
                                                                            valid0
                                                                        ) {
                                                                            if (
                                                                                data.route_via_registrar !==
                                                                                undefined
                                                                            ) {
                                                                                const _errs36 =
                                                                                    errors;
                                                                                if (
                                                                                    typeof data.route_via_registrar !==
                                                                                    'boolean'
                                                                                ) {
                                                                                    validate14.errors =
                                                                                        [
                                                                                            {
                                                                                                instancePath:
                                                                                                    instancePath +
                                                                                                    '/route_via_registrar',
                                                                                                schemaPath:
                                                                                                    '#/properties/route_via_registrar/type',
                                                                                                keyword:
                                                                                                    'type',
                                                                                                params: {
                                                                                                    type: 'boolean',
                                                                                                },
                                                                                                message:
                                                                                                    'must be boolean',
                                                                                            },
                                                                                        ];
                                                                                    return false;
                                                                                }
                                                                                var valid0 =
                                                                                    _errs36 ===
                                                                                    errors;
                                                                            } else {
                                                                                var valid0 =
                                                                                    true;
                                                                            }
                                                                            if (
                                                                                valid0
                                                                            ) {
                                                                                if (
                                                                                    data.rtmp_enabled !==
                                                                                    undefined
                                                                                ) {
                                                                                    const _errs38 =
                                                                                        errors;
                                                                                    if (
                                                                                        typeof data.rtmp_enabled !==
                                                                                        'boolean'
                                                                                    ) {
                                                                                        validate14.errors =
                                                                                            [
                                                                                                {
                                                                                                    instancePath:
                                                                                                        instancePath +
                                                                                                        '/rtmp_enabled',
                                                                                                    schemaPath:
                                                                                                        '#/properties/rtmp_enabled/type',
                                                                                                    keyword:
                                                                                                        'type',
                                                                                                    params: {
                                                                                                        type: 'boolean',
                                                                                                    },
                                                                                                    message:
                                                                                                        'must be boolean',
                                                                                                },
                                                                                            ];
                                                                                        return false;
                                                                                    }
                                                                                    var valid0 =
                                                                                        _errs38 ===
                                                                                        errors;
                                                                                } else {
                                                                                    var valid0 =
                                                                                        true;
                                                                                }
                                                                                if (
                                                                                    valid0
                                                                                ) {
                                                                                    if (
                                                                                        data.rtsp_enabled !==
                                                                                        undefined
                                                                                    ) {
                                                                                        const _errs40 =
                                                                                            errors;
                                                                                        if (
                                                                                            typeof data.rtsp_enabled !==
                                                                                            'boolean'
                                                                                        ) {
                                                                                            validate14.errors =
                                                                                                [
                                                                                                    {
                                                                                                        instancePath:
                                                                                                            instancePath +
                                                                                                            '/rtsp_enabled',
                                                                                                        schemaPath:
                                                                                                            '#/properties/rtsp_enabled/type',
                                                                                                        keyword:
                                                                                                            'type',
                                                                                                        params: {
                                                                                                            type: 'boolean',
                                                                                                        },
                                                                                                        message:
                                                                                                            'must be boolean',
                                                                                                    },
                                                                                                ];
                                                                                            return false;
                                                                                        }
                                                                                        var valid0 =
                                                                                            _errs40 ===
                                                                                            errors;
                                                                                    } else {
                                                                                        var valid0 =
                                                                                            true;
                                                                                    }
                                                                                    if (
                                                                                        valid0
                                                                                    ) {
                                                                                        if (
                                                                                            data.trickle_ice_enabled !==
                                                                                            undefined
                                                                                        ) {
                                                                                            const _errs42 =
                                                                                                errors;
                                                                                            if (
                                                                                                typeof data.trickle_ice_enabled !==
                                                                                                'boolean'
                                                                                            ) {
                                                                                                validate14.errors =
                                                                                                    [
                                                                                                        {
                                                                                                            instancePath:
                                                                                                                instancePath +
                                                                                                                '/trickle_ice_enabled',
                                                                                                            schemaPath:
                                                                                                                '#/properties/trickle_ice_enabled/type',
                                                                                                            keyword:
                                                                                                                'type',
                                                                                                            params: {
                                                                                                                type: 'boolean',
                                                                                                            },
                                                                                                            message:
                                                                                                                'must be boolean',
                                                                                                        },
                                                                                                    ];
                                                                                                return false;
                                                                                            }
                                                                                            var valid0 =
                                                                                                _errs42 ===
                                                                                                errors;
                                                                                        } else {
                                                                                            var valid0 =
                                                                                                true;
                                                                                        }
                                                                                        if (
                                                                                            valid0
                                                                                        ) {
                                                                                            if (
                                                                                                data.vp9_enabled !==
                                                                                                undefined
                                                                                            ) {
                                                                                                const _errs44 =
                                                                                                    errors;
                                                                                                if (
                                                                                                    typeof data.vp9_enabled !==
                                                                                                    'boolean'
                                                                                                ) {
                                                                                                    validate14.errors =
                                                                                                        [
                                                                                                            {
                                                                                                                instancePath:
                                                                                                                    instancePath +
                                                                                                                    '/vp9_enabled',
                                                                                                                schemaPath:
                                                                                                                    '#/properties/vp9_enabled/type',
                                                                                                                keyword:
                                                                                                                    'type',
                                                                                                                params: {
                                                                                                                    type: 'boolean',
                                                                                                                },
                                                                                                                message:
                                                                                                                    'must be boolean',
                                                                                                            },
                                                                                                        ];
                                                                                                    return false;
                                                                                                }
                                                                                                var valid0 =
                                                                                                    _errs44 ===
                                                                                                    errors;
                                                                                            } else {
                                                                                                var valid0 =
                                                                                                    true;
                                                                                            }
                                                                                            if (
                                                                                                valid0
                                                                                            ) {
                                                                                                if (
                                                                                                    data.chat_enabled !==
                                                                                                    undefined
                                                                                                ) {
                                                                                                    const _errs46 =
                                                                                                        errors;
                                                                                                    if (
                                                                                                        typeof data.chat_enabled !==
                                                                                                        'boolean'
                                                                                                    ) {
                                                                                                        validate14.errors =
                                                                                                            [
                                                                                                                {
                                                                                                                    instancePath:
                                                                                                                        instancePath +
                                                                                                                        '/chat_enabled',
                                                                                                                    schemaPath:
                                                                                                                        '#/properties/chat_enabled/type',
                                                                                                                    keyword:
                                                                                                                        'type',
                                                                                                                    params: {
                                                                                                                        type: 'boolean',
                                                                                                                    },
                                                                                                                    message:
                                                                                                                        'must be boolean',
                                                                                                                },
                                                                                                            ];
                                                                                                        return false;
                                                                                                    }
                                                                                                    var valid0 =
                                                                                                        _errs46 ===
                                                                                                        errors;
                                                                                                } else {
                                                                                                    var valid0 =
                                                                                                        true;
                                                                                                }
                                                                                                if (
                                                                                                    valid0
                                                                                                ) {
                                                                                                    if (
                                                                                                        data.service_type !==
                                                                                                        undefined
                                                                                                    ) {
                                                                                                        let data22 =
                                                                                                            data.service_type;
                                                                                                        const _errs48 =
                                                                                                            errors;
                                                                                                        if (
                                                                                                            typeof data22 !==
                                                                                                            'string'
                                                                                                        ) {
                                                                                                            validate14.errors =
                                                                                                                [
                                                                                                                    {
                                                                                                                        instancePath:
                                                                                                                            instancePath +
                                                                                                                            '/service_type',
                                                                                                                        schemaPath:
                                                                                                                            '#/properties/service_type/type',
                                                                                                                        keyword:
                                                                                                                            'type',
                                                                                                                        params: {
                                                                                                                            type: 'string',
                                                                                                                        },
                                                                                                                        message:
                                                                                                                            'must be string',
                                                                                                                    },
                                                                                                                ];
                                                                                                            return false;
                                                                                                        }
                                                                                                        if (
                                                                                                            !(
                                                                                                                data22 ===
                                                                                                                    'conference' ||
                                                                                                                data22 ===
                                                                                                                    'gateway' ||
                                                                                                                data22 ===
                                                                                                                    'test_call' ||
                                                                                                                data22 ===
                                                                                                                    'lecture' ||
                                                                                                                data22 ===
                                                                                                                    'media_playback'
                                                                                                            )
                                                                                                        ) {
                                                                                                            validate14.errors =
                                                                                                                [
                                                                                                                    {
                                                                                                                        instancePath:
                                                                                                                            instancePath +
                                                                                                                            '/service_type',
                                                                                                                        schemaPath:
                                                                                                                            '#/properties/service_type/enum',
                                                                                                                        keyword:
                                                                                                                            'enum',
                                                                                                                        params: {
                                                                                                                            allowedValues:
                                                                                                                                schema16
                                                                                                                                    .properties
                                                                                                                                    .service_type
                                                                                                                                    .enum,
                                                                                                                        },
                                                                                                                        message:
                                                                                                                            'must be equal to one of the allowed values',
                                                                                                                    },
                                                                                                                ];
                                                                                                            return false;
                                                                                                        }
                                                                                                        var valid0 =
                                                                                                            _errs48 ===
                                                                                                            errors;
                                                                                                    } else {
                                                                                                        var valid0 =
                                                                                                            true;
                                                                                                    }
                                                                                                    if (
                                                                                                        valid0
                                                                                                    ) {
                                                                                                        if (
                                                                                                            data.stun !==
                                                                                                            undefined
                                                                                                        ) {
                                                                                                            let data23 =
                                                                                                                data.stun;
                                                                                                            const _errs50 =
                                                                                                                errors;
                                                                                                            if (
                                                                                                                errors ===
                                                                                                                _errs50
                                                                                                            ) {
                                                                                                                if (
                                                                                                                    Array.isArray(
                                                                                                                        data23,
                                                                                                                    )
                                                                                                                ) {
                                                                                                                    var valid3 =
                                                                                                                        true;
                                                                                                                    const len0 =
                                                                                                                        data23.length;
                                                                                                                    for (
                                                                                                                        let i0 = 0;
                                                                                                                        i0 <
                                                                                                                        len0;
                                                                                                                        i0++
                                                                                                                    ) {
                                                                                                                        let data24 =
                                                                                                                            data23[
                                                                                                                                i0
                                                                                                                            ];
                                                                                                                        const _errs52 =
                                                                                                                            errors;
                                                                                                                        const _errs53 =
                                                                                                                            errors;
                                                                                                                        if (
                                                                                                                            errors ===
                                                                                                                            _errs53
                                                                                                                        ) {
                                                                                                                            if (
                                                                                                                                data24 &&
                                                                                                                                typeof data24 ==
                                                                                                                                    'object' &&
                                                                                                                                !Array.isArray(
                                                                                                                                    data24,
                                                                                                                                )
                                                                                                                            ) {
                                                                                                                                let missing1;
                                                                                                                                if (
                                                                                                                                    data24.url ===
                                                                                                                                        undefined &&
                                                                                                                                    (missing1 =
                                                                                                                                        'url')
                                                                                                                                ) {
                                                                                                                                    validate14.errors =
                                                                                                                                        [
                                                                                                                                            {
                                                                                                                                                instancePath:
                                                                                                                                                    instancePath +
                                                                                                                                                    '/stun/' +
                                                                                                                                                    i0,
                                                                                                                                                schemaPath:
                                                                                                                                                    '#/definitions/request_token/properties/200/definitions/Stun/required',
                                                                                                                                                keyword:
                                                                                                                                                    'required',
                                                                                                                                                params: {
                                                                                                                                                    missingProperty:
                                                                                                                                                        missing1,
                                                                                                                                                },
                                                                                                                                                message:
                                                                                                                                                    "must have required property '" +
                                                                                                                                                    missing1 +
                                                                                                                                                    "'",
                                                                                                                                            },
                                                                                                                                        ];
                                                                                                                                    return false;
                                                                                                                                } else {
                                                                                                                                    const _errs55 =
                                                                                                                                        errors;
                                                                                                                                    for (const key2 in data24) {
                                                                                                                                        if (
                                                                                                                                            !(
                                                                                                                                                key2 ===
                                                                                                                                                'url'
                                                                                                                                            )
                                                                                                                                        ) {
                                                                                                                                            delete data24[
                                                                                                                                                key2
                                                                                                                                            ];
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                    if (
                                                                                                                                        _errs55 ===
                                                                                                                                        errors
                                                                                                                                    ) {
                                                                                                                                        if (
                                                                                                                                            data24.url !==
                                                                                                                                            undefined
                                                                                                                                        ) {
                                                                                                                                            if (
                                                                                                                                                typeof data24.url !==
                                                                                                                                                'string'
                                                                                                                                            ) {
                                                                                                                                                validate14.errors =
                                                                                                                                                    [
                                                                                                                                                        {
                                                                                                                                                            instancePath:
                                                                                                                                                                instancePath +
                                                                                                                                                                '/stun/' +
                                                                                                                                                                i0 +
                                                                                                                                                                '/url',
                                                                                                                                                            schemaPath:
                                                                                                                                                                '#/definitions/request_token/properties/200/definitions/Stun/properties/url/type',
                                                                                                                                                            keyword:
                                                                                                                                                                'type',
                                                                                                                                                            params: {
                                                                                                                                                                type: 'string',
                                                                                                                                                            },
                                                                                                                                                            message:
                                                                                                                                                                'must be string',
                                                                                                                                                        },
                                                                                                                                                    ];
                                                                                                                                                return false;
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            } else {
                                                                                                                                validate14.errors =
                                                                                                                                    [
                                                                                                                                        {
                                                                                                                                            instancePath:
                                                                                                                                                instancePath +
                                                                                                                                                '/stun/' +
                                                                                                                                                i0,
                                                                                                                                            schemaPath:
                                                                                                                                                '#/definitions/request_token/properties/200/definitions/Stun/type',
                                                                                                                                            keyword:
                                                                                                                                                'type',
                                                                                                                                            params: {
                                                                                                                                                type: 'object',
                                                                                                                                            },
                                                                                                                                            message:
                                                                                                                                                'must be object',
                                                                                                                                        },
                                                                                                                                    ];
                                                                                                                                return false;
                                                                                                                            }
                                                                                                                        }
                                                                                                                        var valid3 =
                                                                                                                            _errs52 ===
                                                                                                                            errors;
                                                                                                                        if (
                                                                                                                            !valid3
                                                                                                                        ) {
                                                                                                                            break;
                                                                                                                        }
                                                                                                                    }
                                                                                                                } else {
                                                                                                                    validate14.errors =
                                                                                                                        [
                                                                                                                            {
                                                                                                                                instancePath:
                                                                                                                                    instancePath +
                                                                                                                                    '/stun',
                                                                                                                                schemaPath:
                                                                                                                                    '#/properties/stun/type',
                                                                                                                                keyword:
                                                                                                                                    'type',
                                                                                                                                params: {
                                                                                                                                    type: 'array',
                                                                                                                                },
                                                                                                                                message:
                                                                                                                                    'must be array',
                                                                                                                            },
                                                                                                                        ];
                                                                                                                    return false;
                                                                                                                }
                                                                                                            }
                                                                                                            var valid0 =
                                                                                                                _errs50 ===
                                                                                                                errors;
                                                                                                        } else {
                                                                                                            var valid0 =
                                                                                                                true;
                                                                                                        }
                                                                                                        if (
                                                                                                            valid0
                                                                                                        ) {
                                                                                                            if (
                                                                                                                data.turn !==
                                                                                                                undefined
                                                                                                            ) {
                                                                                                                let data26 =
                                                                                                                    data.turn;
                                                                                                                const _errs58 =
                                                                                                                    errors;
                                                                                                                if (
                                                                                                                    errors ===
                                                                                                                    _errs58
                                                                                                                ) {
                                                                                                                    if (
                                                                                                                        Array.isArray(
                                                                                                                            data26,
                                                                                                                        )
                                                                                                                    ) {
                                                                                                                        var valid6 =
                                                                                                                            true;
                                                                                                                        const len1 =
                                                                                                                            data26.length;
                                                                                                                        for (
                                                                                                                            let i1 = 0;
                                                                                                                            i1 <
                                                                                                                            len1;
                                                                                                                            i1++
                                                                                                                        ) {
                                                                                                                            let data27 =
                                                                                                                                data26[
                                                                                                                                    i1
                                                                                                                                ];
                                                                                                                            const _errs60 =
                                                                                                                                errors;
                                                                                                                            const _errs61 =
                                                                                                                                errors;
                                                                                                                            if (
                                                                                                                                errors ===
                                                                                                                                _errs61
                                                                                                                            ) {
                                                                                                                                if (
                                                                                                                                    data27 &&
                                                                                                                                    typeof data27 ==
                                                                                                                                        'object' &&
                                                                                                                                    !Array.isArray(
                                                                                                                                        data27,
                                                                                                                                    )
                                                                                                                                ) {
                                                                                                                                    let missing2;
                                                                                                                                    if (
                                                                                                                                        data27.urls ===
                                                                                                                                            undefined &&
                                                                                                                                        (missing2 =
                                                                                                                                            'urls')
                                                                                                                                    ) {
                                                                                                                                        validate14.errors =
                                                                                                                                            [
                                                                                                                                                {
                                                                                                                                                    instancePath:
                                                                                                                                                        instancePath +
                                                                                                                                                        '/turn/' +
                                                                                                                                                        i1,
                                                                                                                                                    schemaPath:
                                                                                                                                                        '#/definitions/request_token/properties/200/definitions/Turn/required',
                                                                                                                                                    keyword:
                                                                                                                                                        'required',
                                                                                                                                                    params: {
                                                                                                                                                        missingProperty:
                                                                                                                                                            missing2,
                                                                                                                                                    },
                                                                                                                                                    message:
                                                                                                                                                        "must have required property '" +
                                                                                                                                                        missing2 +
                                                                                                                                                        "'",
                                                                                                                                                },
                                                                                                                                            ];
                                                                                                                                        return false;
                                                                                                                                    } else {
                                                                                                                                        const _errs63 =
                                                                                                                                            errors;
                                                                                                                                        for (const key3 in data27) {
                                                                                                                                            if (
                                                                                                                                                !(
                                                                                                                                                    key3 ===
                                                                                                                                                        'credential' ||
                                                                                                                                                    key3 ===
                                                                                                                                                        'urls' ||
                                                                                                                                                    key3 ===
                                                                                                                                                        'username'
                                                                                                                                                )
                                                                                                                                            ) {
                                                                                                                                                delete data27[
                                                                                                                                                    key3
                                                                                                                                                ];
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                        if (
                                                                                                                                            _errs63 ===
                                                                                                                                            errors
                                                                                                                                        ) {
                                                                                                                                            if (
                                                                                                                                                data27.credential !==
                                                                                                                                                undefined
                                                                                                                                            ) {
                                                                                                                                                const _errs64 =
                                                                                                                                                    errors;
                                                                                                                                                if (
                                                                                                                                                    typeof data27.credential !==
                                                                                                                                                    'string'
                                                                                                                                                ) {
                                                                                                                                                    validate14.errors =
                                                                                                                                                        [
                                                                                                                                                            {
                                                                                                                                                                instancePath:
                                                                                                                                                                    instancePath +
                                                                                                                                                                    '/turn/' +
                                                                                                                                                                    i1 +
                                                                                                                                                                    '/credential',
                                                                                                                                                                schemaPath:
                                                                                                                                                                    '#/definitions/request_token/properties/200/definitions/Turn/properties/credential/type',
                                                                                                                                                                keyword:
                                                                                                                                                                    'type',
                                                                                                                                                                params: {
                                                                                                                                                                    type: 'string',
                                                                                                                                                                },
                                                                                                                                                                message:
                                                                                                                                                                    'must be string',
                                                                                                                                                            },
                                                                                                                                                        ];
                                                                                                                                                    return false;
                                                                                                                                                }
                                                                                                                                                var valid8 =
                                                                                                                                                    _errs64 ===
                                                                                                                                                    errors;
                                                                                                                                            } else {
                                                                                                                                                var valid8 =
                                                                                                                                                    true;
                                                                                                                                            }
                                                                                                                                            if (
                                                                                                                                                valid8
                                                                                                                                            ) {
                                                                                                                                                if (
                                                                                                                                                    data27.urls !==
                                                                                                                                                    undefined
                                                                                                                                                ) {
                                                                                                                                                    let data29 =
                                                                                                                                                        data27.urls;
                                                                                                                                                    const _errs66 =
                                                                                                                                                        errors;
                                                                                                                                                    if (
                                                                                                                                                        errors ===
                                                                                                                                                        _errs66
                                                                                                                                                    ) {
                                                                                                                                                        if (
                                                                                                                                                            Array.isArray(
                                                                                                                                                                data29,
                                                                                                                                                            )
                                                                                                                                                        ) {
                                                                                                                                                            var valid9 =
                                                                                                                                                                true;
                                                                                                                                                            const len2 =
                                                                                                                                                                data29.length;
                                                                                                                                                            for (
                                                                                                                                                                let i2 = 0;
                                                                                                                                                                i2 <
                                                                                                                                                                len2;
                                                                                                                                                                i2++
                                                                                                                                                            ) {
                                                                                                                                                                const _errs68 =
                                                                                                                                                                    errors;
                                                                                                                                                                if (
                                                                                                                                                                    typeof data29[
                                                                                                                                                                        i2
                                                                                                                                                                    ] !==
                                                                                                                                                                    'string'
                                                                                                                                                                ) {
                                                                                                                                                                    validate14.errors =
                                                                                                                                                                        [
                                                                                                                                                                            {
                                                                                                                                                                                instancePath:
                                                                                                                                                                                    instancePath +
                                                                                                                                                                                    '/turn/' +
                                                                                                                                                                                    i1 +
                                                                                                                                                                                    '/urls/' +
                                                                                                                                                                                    i2,
                                                                                                                                                                                schemaPath:
                                                                                                                                                                                    '#/definitions/request_token/properties/200/definitions/Turn/properties/urls/items/type',
                                                                                                                                                                                keyword:
                                                                                                                                                                                    'type',
                                                                                                                                                                                params: {
                                                                                                                                                                                    type: 'string',
                                                                                                                                                                                },
                                                                                                                                                                                message:
                                                                                                                                                                                    'must be string',
                                                                                                                                                                            },
                                                                                                                                                                        ];
                                                                                                                                                                    return false;
                                                                                                                                                                }
                                                                                                                                                                var valid9 =
                                                                                                                                                                    _errs68 ===
                                                                                                                                                                    errors;
                                                                                                                                                                if (
                                                                                                                                                                    !valid9
                                                                                                                                                                ) {
                                                                                                                                                                    break;
                                                                                                                                                                }
                                                                                                                                                            }
                                                                                                                                                        } else {
                                                                                                                                                            validate14.errors =
                                                                                                                                                                [
                                                                                                                                                                    {
                                                                                                                                                                        instancePath:
                                                                                                                                                                            instancePath +
                                                                                                                                                                            '/turn/' +
                                                                                                                                                                            i1 +
                                                                                                                                                                            '/urls',
                                                                                                                                                                        schemaPath:
                                                                                                                                                                            '#/definitions/request_token/properties/200/definitions/Turn/properties/urls/type',
                                                                                                                                                                        keyword:
                                                                                                                                                                            'type',
                                                                                                                                                                        params: {
                                                                                                                                                                            type: 'array',
                                                                                                                                                                        },
                                                                                                                                                                        message:
                                                                                                                                                                            'must be array',
                                                                                                                                                                    },
                                                                                                                                                                ];
                                                                                                                                                            return false;
                                                                                                                                                        }
                                                                                                                                                    }
                                                                                                                                                    var valid8 =
                                                                                                                                                        _errs66 ===
                                                                                                                                                        errors;
                                                                                                                                                } else {
                                                                                                                                                    var valid8 =
                                                                                                                                                        true;
                                                                                                                                                }
                                                                                                                                                if (
                                                                                                                                                    valid8
                                                                                                                                                ) {
                                                                                                                                                    if (
                                                                                                                                                        data27.username !==
                                                                                                                                                        undefined
                                                                                                                                                    ) {
                                                                                                                                                        const _errs70 =
                                                                                                                                                            errors;
                                                                                                                                                        if (
                                                                                                                                                            typeof data27.username !==
                                                                                                                                                            'string'
                                                                                                                                                        ) {
                                                                                                                                                            validate14.errors =
                                                                                                                                                                [
                                                                                                                                                                    {
                                                                                                                                                                        instancePath:
                                                                                                                                                                            instancePath +
                                                                                                                                                                            '/turn/' +
                                                                                                                                                                            i1 +
                                                                                                                                                                            '/username',
                                                                                                                                                                        schemaPath:
                                                                                                                                                                            '#/definitions/request_token/properties/200/definitions/Turn/properties/username/type',
                                                                                                                                                                        keyword:
                                                                                                                                                                            'type',
                                                                                                                                                                        params: {
                                                                                                                                                                            type: 'string',
                                                                                                                                                                        },
                                                                                                                                                                        message:
                                                                                                                                                                            'must be string',
                                                                                                                                                                    },
                                                                                                                                                                ];
                                                                                                                                                            return false;
                                                                                                                                                        }
                                                                                                                                                        var valid8 =
                                                                                                                                                            _errs70 ===
                                                                                                                                                            errors;
                                                                                                                                                    } else {
                                                                                                                                                        var valid8 =
                                                                                                                                                            true;
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                } else {
                                                                                                                                    validate14.errors =
                                                                                                                                        [
                                                                                                                                            {
                                                                                                                                                instancePath:
                                                                                                                                                    instancePath +
                                                                                                                                                    '/turn/' +
                                                                                                                                                    i1,
                                                                                                                                                schemaPath:
                                                                                                                                                    '#/definitions/request_token/properties/200/definitions/Turn/type',
                                                                                                                                                keyword:
                                                                                                                                                    'type',
                                                                                                                                                params: {
                                                                                                                                                    type: 'object',
                                                                                                                                                },
                                                                                                                                                message:
                                                                                                                                                    'must be object',
                                                                                                                                            },
                                                                                                                                        ];
                                                                                                                                    return false;
                                                                                                                                }
                                                                                                                            }
                                                                                                                            var valid6 =
                                                                                                                                _errs60 ===
                                                                                                                                errors;
                                                                                                                            if (
                                                                                                                                !valid6
                                                                                                                            ) {
                                                                                                                                break;
                                                                                                                            }
                                                                                                                        }
                                                                                                                    } else {
                                                                                                                        validate14.errors =
                                                                                                                            [
                                                                                                                                {
                                                                                                                                    instancePath:
                                                                                                                                        instancePath +
                                                                                                                                        '/turn',
                                                                                                                                    schemaPath:
                                                                                                                                        '#/properties/turn/type',
                                                                                                                                    keyword:
                                                                                                                                        'type',
                                                                                                                                    params: {
                                                                                                                                        type: 'array',
                                                                                                                                    },
                                                                                                                                    message:
                                                                                                                                        'must be array',
                                                                                                                                },
                                                                                                                            ];
                                                                                                                        return false;
                                                                                                                    }
                                                                                                                }
                                                                                                                var valid0 =
                                                                                                                    _errs58 ===
                                                                                                                    errors;
                                                                                                            } else {
                                                                                                                var valid0 =
                                                                                                                    true;
                                                                                                            }
                                                                                                            if (
                                                                                                                valid0
                                                                                                            ) {
                                                                                                                if (
                                                                                                                    data.display_name !==
                                                                                                                    undefined
                                                                                                                ) {
                                                                                                                    const _errs72 =
                                                                                                                        errors;
                                                                                                                    if (
                                                                                                                        typeof data.display_name !==
                                                                                                                        'string'
                                                                                                                    ) {
                                                                                                                        validate14.errors =
                                                                                                                            [
                                                                                                                                {
                                                                                                                                    instancePath:
                                                                                                                                        instancePath +
                                                                                                                                        '/display_name',
                                                                                                                                    schemaPath:
                                                                                                                                        '#/properties/display_name/type',
                                                                                                                                    keyword:
                                                                                                                                        'type',
                                                                                                                                    params: {
                                                                                                                                        type: 'string',
                                                                                                                                    },
                                                                                                                                    message:
                                                                                                                                        'must be string',
                                                                                                                                },
                                                                                                                            ];
                                                                                                                        return false;
                                                                                                                    }
                                                                                                                    var valid0 =
                                                                                                                        _errs72 ===
                                                                                                                        errors;
                                                                                                                } else {
                                                                                                                    var valid0 =
                                                                                                                        true;
                                                                                                                }
                                                                                                                if (
                                                                                                                    valid0
                                                                                                                ) {
                                                                                                                    if (
                                                                                                                        data.analytics_enabled !==
                                                                                                                        undefined
                                                                                                                    ) {
                                                                                                                        const _errs74 =
                                                                                                                            errors;
                                                                                                                        if (
                                                                                                                            typeof data.analytics_enabled !==
                                                                                                                            'boolean'
                                                                                                                        ) {
                                                                                                                            validate14.errors =
                                                                                                                                [
                                                                                                                                    {
                                                                                                                                        instancePath:
                                                                                                                                            instancePath +
                                                                                                                                            '/analytics_enabled',
                                                                                                                                        schemaPath:
                                                                                                                                            '#/properties/analytics_enabled/type',
                                                                                                                                        keyword:
                                                                                                                                            'type',
                                                                                                                                        params: {
                                                                                                                                            type: 'boolean',
                                                                                                                                        },
                                                                                                                                        message:
                                                                                                                                            'must be boolean',
                                                                                                                                    },
                                                                                                                                ];
                                                                                                                            return false;
                                                                                                                        }
                                                                                                                        var valid0 =
                                                                                                                            _errs74 ===
                                                                                                                            errors;
                                                                                                                    } else {
                                                                                                                        var valid0 =
                                                                                                                            true;
                                                                                                                    }
                                                                                                                    if (
                                                                                                                        valid0
                                                                                                                    ) {
                                                                                                                        if (
                                                                                                                            data.current_service_type !==
                                                                                                                            undefined
                                                                                                                        ) {
                                                                                                                            let data34 =
                                                                                                                                data.current_service_type;
                                                                                                                            const _errs76 =
                                                                                                                                errors;
                                                                                                                            if (
                                                                                                                                typeof data34 !==
                                                                                                                                'string'
                                                                                                                            ) {
                                                                                                                                validate14.errors =
                                                                                                                                    [
                                                                                                                                        {
                                                                                                                                            instancePath:
                                                                                                                                                instancePath +
                                                                                                                                                '/current_service_type',
                                                                                                                                            schemaPath:
                                                                                                                                                '#/properties/current_service_type/type',
                                                                                                                                            keyword:
                                                                                                                                                'type',
                                                                                                                                            params: {
                                                                                                                                                type: 'string',
                                                                                                                                            },
                                                                                                                                            message:
                                                                                                                                                'must be string',
                                                                                                                                        },
                                                                                                                                    ];
                                                                                                                                return false;
                                                                                                                            }
                                                                                                                            if (
                                                                                                                                !(
                                                                                                                                    data34 ===
                                                                                                                                        'conference' ||
                                                                                                                                    data34 ===
                                                                                                                                        'gateway' ||
                                                                                                                                    data34 ===
                                                                                                                                        'test_call' ||
                                                                                                                                    data34 ===
                                                                                                                                        'waiting_room' ||
                                                                                                                                    data34 ===
                                                                                                                                        'ivr' ||
                                                                                                                                    data34 ===
                                                                                                                                        'lecture' ||
                                                                                                                                    data34 ===
                                                                                                                                        'media_playback'
                                                                                                                                )
                                                                                                                            ) {
                                                                                                                                validate14.errors =
                                                                                                                                    [
                                                                                                                                        {
                                                                                                                                            instancePath:
                                                                                                                                                instancePath +
                                                                                                                                                '/current_service_type',
                                                                                                                                            schemaPath:
                                                                                                                                                '#/properties/current_service_type/enum',
                                                                                                                                            keyword:
                                                                                                                                                'enum',
                                                                                                                                            params: {
                                                                                                                                                allowedValues:
                                                                                                                                                    schema16
                                                                                                                                                        .properties
                                                                                                                                                        .current_service_type
                                                                                                                                                        .enum,
                                                                                                                                            },
                                                                                                                                            message:
                                                                                                                                                'must be equal to one of the allowed values',
                                                                                                                                        },
                                                                                                                                    ];
                                                                                                                                return false;
                                                                                                                            }
                                                                                                                            var valid0 =
                                                                                                                                _errs76 ===
                                                                                                                                errors;
                                                                                                                        } else {
                                                                                                                            var valid0 =
                                                                                                                                true;
                                                                                                                        }
                                                                                                                        if (
                                                                                                                            valid0
                                                                                                                        ) {
                                                                                                                            if (
                                                                                                                                data.direct_media !==
                                                                                                                                undefined
                                                                                                                            ) {
                                                                                                                                const _errs78 =
                                                                                                                                    errors;
                                                                                                                                if (
                                                                                                                                    typeof data.direct_media !==
                                                                                                                                    'boolean'
                                                                                                                                ) {
                                                                                                                                    validate14.errors =
                                                                                                                                        [
                                                                                                                                            {
                                                                                                                                                instancePath:
                                                                                                                                                    instancePath +
                                                                                                                                                    '/direct_media',
                                                                                                                                                schemaPath:
                                                                                                                                                    '#/properties/direct_media/type',
                                                                                                                                                keyword:
                                                                                                                                                    'type',
                                                                                                                                                params: {
                                                                                                                                                    type: 'boolean',
                                                                                                                                                },
                                                                                                                                                message:
                                                                                                                                                    'must be boolean',
                                                                                                                                            },
                                                                                                                                        ];
                                                                                                                                    return false;
                                                                                                                                }
                                                                                                                                var valid0 =
                                                                                                                                    _errs78 ===
                                                                                                                                    errors;
                                                                                                                            } else {
                                                                                                                                var valid0 =
                                                                                                                                    true;
                                                                                                                            }
                                                                                                                            if (
                                                                                                                                valid0
                                                                                                                            ) {
                                                                                                                                if (
                                                                                                                                    data.service_name !==
                                                                                                                                    undefined
                                                                                                                                ) {
                                                                                                                                    const _errs80 =
                                                                                                                                        errors;
                                                                                                                                    if (
                                                                                                                                        typeof data.service_name !==
                                                                                                                                        'string'
                                                                                                                                    ) {
                                                                                                                                        validate14.errors =
                                                                                                                                            [
                                                                                                                                                {
                                                                                                                                                    instancePath:
                                                                                                                                                        instancePath +
                                                                                                                                                        '/service_name',
                                                                                                                                                    schemaPath:
                                                                                                                                                        '#/properties/service_name/type',
                                                                                                                                                    keyword:
                                                                                                                                                        'type',
                                                                                                                                                    params: {
                                                                                                                                                        type: 'string',
                                                                                                                                                    },
                                                                                                                                                    message:
                                                                                                                                                        'must be string',
                                                                                                                                                },
                                                                                                                                            ];
                                                                                                                                        return false;
                                                                                                                                    }
                                                                                                                                    var valid0 =
                                                                                                                                        _errs80 ===
                                                                                                                                        errors;
                                                                                                                                } else {
                                                                                                                                    var valid0 =
                                                                                                                                        true;
                                                                                                                                }
                                                                                                                                if (
                                                                                                                                    valid0
                                                                                                                                ) {
                                                                                                                                    if (
                                                                                                                                        data.pex_datachannel_id !==
                                                                                                                                        undefined
                                                                                                                                    ) {
                                                                                                                                        const _errs82 =
                                                                                                                                            errors;
                                                                                                                                        if (
                                                                                                                                            !(
                                                                                                                                                typeof data.pex_datachannel_id ==
                                                                                                                                                'number'
                                                                                                                                            )
                                                                                                                                        ) {
                                                                                                                                            validate14.errors =
                                                                                                                                                [
                                                                                                                                                    {
                                                                                                                                                        instancePath:
                                                                                                                                                            instancePath +
                                                                                                                                                            '/pex_datachannel_id',
                                                                                                                                                        schemaPath:
                                                                                                                                                            '#/properties/pex_datachannel_id/type',
                                                                                                                                                        keyword:
                                                                                                                                                            'type',
                                                                                                                                                        params: {
                                                                                                                                                            type: 'number',
                                                                                                                                                        },
                                                                                                                                                        message:
                                                                                                                                                            'must be number',
                                                                                                                                                    },
                                                                                                                                                ];
                                                                                                                                            return false;
                                                                                                                                        }
                                                                                                                                        var valid0 =
                                                                                                                                            _errs82 ===
                                                                                                                                            errors;
                                                                                                                                    } else {
                                                                                                                                        var valid0 =
                                                                                                                                            true;
                                                                                                                                    }
                                                                                                                                    if (
                                                                                                                                        valid0
                                                                                                                                    ) {
                                                                                                                                        if (
                                                                                                                                            data.client_stats_update_interval !==
                                                                                                                                            undefined
                                                                                                                                        ) {
                                                                                                                                            const _errs84 =
                                                                                                                                                errors;
                                                                                                                                            if (
                                                                                                                                                !(
                                                                                                                                                    typeof data.client_stats_update_interval ==
                                                                                                                                                    'number'
                                                                                                                                                )
                                                                                                                                            ) {
                                                                                                                                                validate14.errors =
                                                                                                                                                    [
                                                                                                                                                        {
                                                                                                                                                            instancePath:
                                                                                                                                                                instancePath +
                                                                                                                                                                '/client_stats_update_interval',
                                                                                                                                                            schemaPath:
                                                                                                                                                                '#/properties/client_stats_update_interval/type',
                                                                                                                                                            keyword:
                                                                                                                                                                'type',
                                                                                                                                                            params: {
                                                                                                                                                                type: 'number',
                                                                                                                                                            },
                                                                                                                                                            message:
                                                                                                                                                                'must be number',
                                                                                                                                                        },
                                                                                                                                                    ];
                                                                                                                                                return false;
                                                                                                                                            }
                                                                                                                                            var valid0 =
                                                                                                                                                _errs84 ===
                                                                                                                                                errors;
                                                                                                                                        } else {
                                                                                                                                            var valid0 =
                                                                                                                                                true;
                                                                                                                                        }
                                                                                                                                        if (
                                                                                                                                            valid0
                                                                                                                                        ) {
                                                                                                                                            if (
                                                                                                                                                data.use_relay_candidates_only !==
                                                                                                                                                undefined
                                                                                                                                            ) {
                                                                                                                                                const _errs86 =
                                                                                                                                                    errors;
                                                                                                                                                if (
                                                                                                                                                    typeof data.use_relay_candidates_only !==
                                                                                                                                                    'boolean'
                                                                                                                                                ) {
                                                                                                                                                    validate14.errors =
                                                                                                                                                        [
                                                                                                                                                            {
                                                                                                                                                                instancePath:
                                                                                                                                                                    instancePath +
                                                                                                                                                                    '/use_relay_candidates_only',
                                                                                                                                                                schemaPath:
                                                                                                                                                                    '#/properties/use_relay_candidates_only/type',
                                                                                                                                                                keyword:
                                                                                                                                                                    'type',
                                                                                                                                                                params: {
                                                                                                                                                                    type: 'boolean',
                                                                                                                                                                },
                                                                                                                                                                message:
                                                                                                                                                                    'must be boolean',
                                                                                                                                                            },
                                                                                                                                                        ];
                                                                                                                                                    return false;
                                                                                                                                                }
                                                                                                                                                var valid0 =
                                                                                                                                                    _errs86 ===
                                                                                                                                                    errors;
                                                                                                                                            } else {
                                                                                                                                                var valid0 =
                                                                                                                                                    true;
                                                                                                                                            }
                                                                                                                                            if (
                                                                                                                                                valid0
                                                                                                                                            ) {
                                                                                                                                                if (
                                                                                                                                                    data.breakout_rooms !==
                                                                                                                                                    undefined
                                                                                                                                                ) {
                                                                                                                                                    const _errs88 =
                                                                                                                                                        errors;
                                                                                                                                                    if (
                                                                                                                                                        typeof data.breakout_rooms !==
                                                                                                                                                        'boolean'
                                                                                                                                                    ) {
                                                                                                                                                        validate14.errors =
                                                                                                                                                            [
                                                                                                                                                                {
                                                                                                                                                                    instancePath:
                                                                                                                                                                        instancePath +
                                                                                                                                                                        '/breakout_rooms',
                                                                                                                                                                    schemaPath:
                                                                                                                                                                        '#/properties/breakout_rooms/type',
                                                                                                                                                                    keyword:
                                                                                                                                                                        'type',
                                                                                                                                                                    params: {
                                                                                                                                                                        type: 'boolean',
                                                                                                                                                                    },
                                                                                                                                                                    message:
                                                                                                                                                                        'must be boolean',
                                                                                                                                                                },
                                                                                                                                                            ];
                                                                                                                                                        return false;
                                                                                                                                                    }
                                                                                                                                                    var valid0 =
                                                                                                                                                        _errs88 ===
                                                                                                                                                        errors;
                                                                                                                                                } else {
                                                                                                                                                    var valid0 =
                                                                                                                                                        true;
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                        }
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            }
                                                                                                                        }
                                                                                                                    }
                                                                                                                }
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        } else {
            validate14.errors = [
                {
                    instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: {type: 'object'},
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate14.errors = vErrors;
    return errors === 0;
}
function validate13(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                validate13.errors = [
                    {
                        instancePath,
                        schemaPath: '#/required',
                        keyword: 'required',
                        params: {missingProperty: missing0},
                        message:
                            "must have required property '" + missing0 + "'",
                    },
                ];
                return false;
            } else {
                const _errs1 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs1 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs2 = errors;
                        if (typeof data0 !== 'string') {
                            validate13.errors = [
                                {
                                    instancePath: instancePath + '/status',
                                    schemaPath: '#/properties/status/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                },
                            ];
                            return false;
                        }
                        if (!(data0 === 'success')) {
                            validate13.errors = [
                                {
                                    instancePath: instancePath + '/status',
                                    schemaPath: '#/properties/status/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema15.properties.status.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                },
                            ];
                            return false;
                        }
                        var valid0 = _errs2 === errors;
                    } else {
                        var valid0 = true;
                    }
                    if (valid0) {
                        if (data.result !== undefined) {
                            const _errs4 = errors;
                            if (
                                !validate14(data.result, {
                                    instancePath: instancePath + '/result',
                                    parentData: data,
                                    parentDataProperty: 'result',
                                    rootData,
                                })
                            ) {
                                vErrors =
                                    vErrors === null
                                        ? validate14.errors
                                        : vErrors.concat(validate14.errors);
                                errors = vErrors.length;
                            }
                            var valid0 = _errs4 === errors;
                        } else {
                            var valid0 = true;
                        }
                    }
                }
            }
        } else {
            validate13.errors = [
                {
                    instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: {type: 'object'},
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate13.errors = vErrors;
    return errors === 0;
}
function validate12(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    if (
        !validate13(data, {
            instancePath,
            parentData,
            parentDataProperty,
            rootData,
        })
    ) {
        vErrors =
            vErrors === null
                ? validate13.errors
                : vErrors.concat(validate13.errors);
        errors = vErrors.length;
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err0 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err0];
        } else {
            vErrors.push(err0);
        }
        errors++;
        validate12.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate12.errors = vErrors;
    return errors === 0;
}
export const validateRequestToken403 = validate17;
const schema20 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '403': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success', 'failed'],
                },
                result: {
                    oneOf: [
                        {
                            $ref: '#/definitions/request_token/properties/403/definitions/Result',
                        },
                        {
                            $ref: '#/definitions/request_token/properties/403/definitions/ResultIdp',
                        },
                        {
                            $ref: '#/definitions/request_token/properties/403/definitions/ResultRedirect',
                        },
                        {
                            $ref: '#/definitions/request_token/properties/403/definitions/ResultExtension',
                        },
                        {
                            $ref: '#/definitions/request_token/properties/415/definitions/415/properties/result',
                        },
                    ],
                },
            },
            required: ['status', 'result'],
            title: 'Status403',
        },
        Result: {
            type: 'object',
            description:
                'If the conference is PIN-protected, the PIN must be specified in a `pin` HTTP header. If the PIN is required but is incorrect or missing, a `403 Forbidden` error is returned.',
            additionalProperties: false,
            properties: {
                conference_extension: {
                    type: 'string',
                    enum: ['standard', 'mssip'],
                },
                guest_pin: {
                    type: 'string',
                    description: 'Guest pin',
                    enum: ['required', 'none'],
                },
                pin: {
                    type: 'string',
                    description: 'Host pin',
                    enum: ['required', 'none'],
                },
                version: {
                    $ref: '#/definitions/request_token/properties/403/definitions/Version',
                },
            },
            required: ['version', 'guest_pin', 'pin'],
            title: 'Result',
        },
        ResultIdp: {
            type: 'object',
            description:
                'If the conference is PIN-protected, the PIN must be specified in a `pin` HTTP header. If the PIN is required but is incorrect or missing, a `403 Forbidden` error is returned.',
            additionalProperties: false,
            properties: {
                idp: {
                    type: 'array',
                    description: 'Idp providers',
                    items: {
                        $ref: '#/definitions/request_token/properties/403/definitions/Idp',
                    },
                },
                version: {
                    $ref: '#/definitions/request_token/properties/403/definitions/Version',
                },
            },
            required: ['idp', 'version'],
            title: 'Result',
        },
        ResultRedirect: {
            type: 'object',
            description:
                'If the conference is PIN-protected, the PIN must be specified in a `pin` HTTP header. If the PIN is required but is incorrect or missing, a `403 Forbidden` error is returned.',
            additionalProperties: false,
            properties: {
                redirect_url: {
                    type: 'string',
                    description:
                        "`<IdP's SSO URL>?SAMLRequest=<Base64 encoded SAML AuthNRequest message>`",
                },
                redirect_idp: {
                    $ref: '#/definitions/request_token/properties/403/definitions/RedirectIdp',
                },
                version: {
                    $ref: '#/definitions/request_token/properties/403/definitions/Version',
                },
                disable_popup_flow: {
                    type: 'boolean',
                    description:
                        'If true, the IDP supports a direct redirect flow without popup. The sso_redirect_target is validated at this point.',
                },
            },
            required: ['redirect_url', 'redirect_idp', 'version'],
            title: 'ResultRedirect',
        },
        Idp: {
            type: 'object',
            additionalProperties: false,
            properties: {
                name: {type: 'string'},
                img: {type: 'string'},
                uuid: {type: 'string'},
                disable_popup_flow: {
                    type: 'boolean',
                    description:
                        'If true, the IDP supports a direct redirect flow without popup. The sso_redirect_target is not validated at this point.',
                },
            },
            required: ['name', 'uuid'],
            title: 'Idp',
        },
        RedirectIdp: {
            type: 'object',
            additionalProperties: false,
            properties: {name: {type: 'string'}, uuid: {type: 'string'}},
            required: ['name', 'uuid'],
            title: 'RedirectIdp',
        },
        ResultExtension: {
            type: 'object',
            additionalProperties: false,
            properties: {
                conference_extension: {type: 'string', enum: ['required']},
                conference_extension_type: {
                    type: 'string',
                    enum: ['mssip', 'standard'],
                },
            },
            required: ['conference_extension', 'conference_extension_type'],
            title: 'ResultExtension',
        },
        Version: {
            type: 'object',
            description:
                'This result contains the token (abridged in the above example) to use to authenticate all future requests, and an expiry time (in seconds) after which this token becomes invalid.',
            additionalProperties: false,
            properties: {
                version_id: {type: 'string'},
                pseudo_version: {type: 'string'},
            },
            required: [],
            title: 'Version',
        },
    },
    oneOf: [
        {$ref: '#/definitions/request_token/properties/403/definitions/403'},
    ],
};
const schema21 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success', 'failed'],
        },
        result: {
            oneOf: [
                {
                    $ref: '#/definitions/request_token/properties/403/definitions/Result',
                },
                {
                    $ref: '#/definitions/request_token/properties/403/definitions/ResultIdp',
                },
                {
                    $ref: '#/definitions/request_token/properties/403/definitions/ResultRedirect',
                },
                {
                    $ref: '#/definitions/request_token/properties/403/definitions/ResultExtension',
                },
                {
                    $ref: '#/definitions/request_token/properties/415/definitions/415/properties/result',
                },
            ],
        },
    },
    required: ['status', 'result'],
    title: 'Status403',
};
const schema30 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        conference_extension: {type: 'string', enum: ['required']},
        conference_extension_type: {
            type: 'string',
            enum: ['mssip', 'standard'],
        },
    },
    required: ['conference_extension', 'conference_extension_type'],
    title: 'ResultExtension',
};
const schema31 = {type: 'string', description: 'Error strings'};
const schema22 = {
    type: 'object',
    description:
        'If the conference is PIN-protected, the PIN must be specified in a `pin` HTTP header. If the PIN is required but is incorrect or missing, a `403 Forbidden` error is returned.',
    additionalProperties: false,
    properties: {
        conference_extension: {type: 'string', enum: ['standard', 'mssip']},
        guest_pin: {
            type: 'string',
            description: 'Guest pin',
            enum: ['required', 'none'],
        },
        pin: {
            type: 'string',
            description: 'Host pin',
            enum: ['required', 'none'],
        },
        version: {
            $ref: '#/definitions/request_token/properties/403/definitions/Version',
        },
    },
    required: ['version', 'guest_pin', 'pin'],
    title: 'Result',
};
const schema23 = {
    type: 'object',
    description:
        'This result contains the token (abridged in the above example) to use to authenticate all future requests, and an expiry time (in seconds) after which this token becomes invalid.',
    additionalProperties: false,
    properties: {
        version_id: {type: 'string'},
        pseudo_version: {type: 'string'},
    },
    required: [],
    title: 'Version',
};
function validate19(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.version === undefined && (missing0 = 'version')) ||
                (data.guest_pin === undefined && (missing0 = 'guest_pin')) ||
                (data.pin === undefined && (missing0 = 'pin'))
            ) {
                validate19.errors = [
                    {
                        instancePath,
                        schemaPath: '#/required',
                        keyword: 'required',
                        params: {missingProperty: missing0},
                        message:
                            "must have required property '" + missing0 + "'",
                    },
                ];
                return false;
            } else {
                const _errs1 = errors;
                for (const key0 in data) {
                    if (
                        !(
                            key0 === 'conference_extension' ||
                            key0 === 'guest_pin' ||
                            key0 === 'pin' ||
                            key0 === 'version'
                        )
                    ) {
                        delete data[key0];
                    }
                }
                if (_errs1 === errors) {
                    if (data.conference_extension !== undefined) {
                        let data0 = data.conference_extension;
                        const _errs2 = errors;
                        if (typeof data0 !== 'string') {
                            validate19.errors = [
                                {
                                    instancePath:
                                        instancePath + '/conference_extension',
                                    schemaPath:
                                        '#/properties/conference_extension/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                },
                            ];
                            return false;
                        }
                        if (!(data0 === 'standard' || data0 === 'mssip')) {
                            validate19.errors = [
                                {
                                    instancePath:
                                        instancePath + '/conference_extension',
                                    schemaPath:
                                        '#/properties/conference_extension/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema22.properties
                                                .conference_extension.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                },
                            ];
                            return false;
                        }
                        var valid0 = _errs2 === errors;
                    } else {
                        var valid0 = true;
                    }
                    if (valid0) {
                        if (data.guest_pin !== undefined) {
                            let data1 = data.guest_pin;
                            const _errs4 = errors;
                            if (typeof data1 !== 'string') {
                                validate19.errors = [
                                    {
                                        instancePath:
                                            instancePath + '/guest_pin',
                                        schemaPath:
                                            '#/properties/guest_pin/type',
                                        keyword: 'type',
                                        params: {type: 'string'},
                                        message: 'must be string',
                                    },
                                ];
                                return false;
                            }
                            if (!(data1 === 'required' || data1 === 'none')) {
                                validate19.errors = [
                                    {
                                        instancePath:
                                            instancePath + '/guest_pin',
                                        schemaPath:
                                            '#/properties/guest_pin/enum',
                                        keyword: 'enum',
                                        params: {
                                            allowedValues:
                                                schema22.properties.guest_pin
                                                    .enum,
                                        },
                                        message:
                                            'must be equal to one of the allowed values',
                                    },
                                ];
                                return false;
                            }
                            var valid0 = _errs4 === errors;
                        } else {
                            var valid0 = true;
                        }
                        if (valid0) {
                            if (data.pin !== undefined) {
                                let data2 = data.pin;
                                const _errs6 = errors;
                                if (typeof data2 !== 'string') {
                                    validate19.errors = [
                                        {
                                            instancePath: instancePath + '/pin',
                                            schemaPath: '#/properties/pin/type',
                                            keyword: 'type',
                                            params: {type: 'string'},
                                            message: 'must be string',
                                        },
                                    ];
                                    return false;
                                }
                                if (
                                    !(data2 === 'required' || data2 === 'none')
                                ) {
                                    validate19.errors = [
                                        {
                                            instancePath: instancePath + '/pin',
                                            schemaPath: '#/properties/pin/enum',
                                            keyword: 'enum',
                                            params: {
                                                allowedValues:
                                                    schema22.properties.pin
                                                        .enum,
                                            },
                                            message:
                                                'must be equal to one of the allowed values',
                                        },
                                    ];
                                    return false;
                                }
                                var valid0 = _errs6 === errors;
                            } else {
                                var valid0 = true;
                            }
                            if (valid0) {
                                if (data.version !== undefined) {
                                    let data3 = data.version;
                                    const _errs8 = errors;
                                    const _errs9 = errors;
                                    if (errors === _errs9) {
                                        if (
                                            data3 &&
                                            typeof data3 == 'object' &&
                                            !Array.isArray(data3)
                                        ) {
                                            const _errs11 = errors;
                                            for (const key1 in data3) {
                                                if (
                                                    !(
                                                        key1 === 'version_id' ||
                                                        key1 ===
                                                            'pseudo_version'
                                                    )
                                                ) {
                                                    delete data3[key1];
                                                }
                                            }
                                            if (_errs11 === errors) {
                                                if (
                                                    data3.version_id !==
                                                    undefined
                                                ) {
                                                    const _errs12 = errors;
                                                    if (
                                                        typeof data3.version_id !==
                                                        'string'
                                                    ) {
                                                        validate19.errors = [
                                                            {
                                                                instancePath:
                                                                    instancePath +
                                                                    '/version/version_id',
                                                                schemaPath:
                                                                    '#/definitions/request_token/properties/403/definitions/Version/properties/version_id/type',
                                                                keyword: 'type',
                                                                params: {
                                                                    type: 'string',
                                                                },
                                                                message:
                                                                    'must be string',
                                                            },
                                                        ];
                                                        return false;
                                                    }
                                                    var valid2 =
                                                        _errs12 === errors;
                                                } else {
                                                    var valid2 = true;
                                                }
                                                if (valid2) {
                                                    if (
                                                        data3.pseudo_version !==
                                                        undefined
                                                    ) {
                                                        const _errs14 = errors;
                                                        if (
                                                            typeof data3.pseudo_version !==
                                                            'string'
                                                        ) {
                                                            validate19.errors =
                                                                [
                                                                    {
                                                                        instancePath:
                                                                            instancePath +
                                                                            '/version/pseudo_version',
                                                                        schemaPath:
                                                                            '#/definitions/request_token/properties/403/definitions/Version/properties/pseudo_version/type',
                                                                        keyword:
                                                                            'type',
                                                                        params: {
                                                                            type: 'string',
                                                                        },
                                                                        message:
                                                                            'must be string',
                                                                    },
                                                                ];
                                                            return false;
                                                        }
                                                        var valid2 =
                                                            _errs14 === errors;
                                                    } else {
                                                        var valid2 = true;
                                                    }
                                                }
                                            }
                                        } else {
                                            validate19.errors = [
                                                {
                                                    instancePath:
                                                        instancePath +
                                                        '/version',
                                                    schemaPath:
                                                        '#/definitions/request_token/properties/403/definitions/Version/type',
                                                    keyword: 'type',
                                                    params: {type: 'object'},
                                                    message: 'must be object',
                                                },
                                            ];
                                            return false;
                                        }
                                    }
                                    var valid0 = _errs8 === errors;
                                } else {
                                    var valid0 = true;
                                }
                            }
                        }
                    }
                }
            }
        } else {
            validate19.errors = [
                {
                    instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: {type: 'object'},
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate19.errors = vErrors;
    return errors === 0;
}
const schema24 = {
    type: 'object',
    description:
        'If the conference is PIN-protected, the PIN must be specified in a `pin` HTTP header. If the PIN is required but is incorrect or missing, a `403 Forbidden` error is returned.',
    additionalProperties: false,
    properties: {
        idp: {
            type: 'array',
            description: 'Idp providers',
            items: {
                $ref: '#/definitions/request_token/properties/403/definitions/Idp',
            },
        },
        version: {
            $ref: '#/definitions/request_token/properties/403/definitions/Version',
        },
    },
    required: ['idp', 'version'],
    title: 'Result',
};
const schema25 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        name: {type: 'string'},
        img: {type: 'string'},
        uuid: {type: 'string'},
        disable_popup_flow: {
            type: 'boolean',
            description:
                'If true, the IDP supports a direct redirect flow without popup. The sso_redirect_target is not validated at this point.',
        },
    },
    required: ['name', 'uuid'],
    title: 'Idp',
};
function validate21(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.idp === undefined && (missing0 = 'idp')) ||
                (data.version === undefined && (missing0 = 'version'))
            ) {
                validate21.errors = [
                    {
                        instancePath,
                        schemaPath: '#/required',
                        keyword: 'required',
                        params: {missingProperty: missing0},
                        message:
                            "must have required property '" + missing0 + "'",
                    },
                ];
                return false;
            } else {
                const _errs1 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'idp' || key0 === 'version')) {
                        delete data[key0];
                    }
                }
                if (_errs1 === errors) {
                    if (data.idp !== undefined) {
                        let data0 = data.idp;
                        const _errs2 = errors;
                        if (errors === _errs2) {
                            if (Array.isArray(data0)) {
                                var valid1 = true;
                                const len0 = data0.length;
                                for (let i0 = 0; i0 < len0; i0++) {
                                    let data1 = data0[i0];
                                    const _errs4 = errors;
                                    const _errs5 = errors;
                                    if (errors === _errs5) {
                                        if (
                                            data1 &&
                                            typeof data1 == 'object' &&
                                            !Array.isArray(data1)
                                        ) {
                                            let missing1;
                                            if (
                                                (data1.name === undefined &&
                                                    (missing1 = 'name')) ||
                                                (data1.uuid === undefined &&
                                                    (missing1 = 'uuid'))
                                            ) {
                                                validate21.errors = [
                                                    {
                                                        instancePath:
                                                            instancePath +
                                                            '/idp/' +
                                                            i0,
                                                        schemaPath:
                                                            '#/definitions/request_token/properties/403/definitions/Idp/required',
                                                        keyword: 'required',
                                                        params: {
                                                            missingProperty:
                                                                missing1,
                                                        },
                                                        message:
                                                            "must have required property '" +
                                                            missing1 +
                                                            "'",
                                                    },
                                                ];
                                                return false;
                                            } else {
                                                const _errs7 = errors;
                                                for (const key1 in data1) {
                                                    if (
                                                        !(
                                                            key1 === 'name' ||
                                                            key1 === 'img' ||
                                                            key1 === 'uuid' ||
                                                            key1 ===
                                                                'disable_popup_flow'
                                                        )
                                                    ) {
                                                        delete data1[key1];
                                                    }
                                                }
                                                if (_errs7 === errors) {
                                                    if (
                                                        data1.name !== undefined
                                                    ) {
                                                        const _errs8 = errors;
                                                        if (
                                                            typeof data1.name !==
                                                            'string'
                                                        ) {
                                                            validate21.errors =
                                                                [
                                                                    {
                                                                        instancePath:
                                                                            instancePath +
                                                                            '/idp/' +
                                                                            i0 +
                                                                            '/name',
                                                                        schemaPath:
                                                                            '#/definitions/request_token/properties/403/definitions/Idp/properties/name/type',
                                                                        keyword:
                                                                            'type',
                                                                        params: {
                                                                            type: 'string',
                                                                        },
                                                                        message:
                                                                            'must be string',
                                                                    },
                                                                ];
                                                            return false;
                                                        }
                                                        var valid3 =
                                                            _errs8 === errors;
                                                    } else {
                                                        var valid3 = true;
                                                    }
                                                    if (valid3) {
                                                        if (
                                                            data1.img !==
                                                            undefined
                                                        ) {
                                                            const _errs10 =
                                                                errors;
                                                            if (
                                                                typeof data1.img !==
                                                                'string'
                                                            ) {
                                                                validate21.errors =
                                                                    [
                                                                        {
                                                                            instancePath:
                                                                                instancePath +
                                                                                '/idp/' +
                                                                                i0 +
                                                                                '/img',
                                                                            schemaPath:
                                                                                '#/definitions/request_token/properties/403/definitions/Idp/properties/img/type',
                                                                            keyword:
                                                                                'type',
                                                                            params: {
                                                                                type: 'string',
                                                                            },
                                                                            message:
                                                                                'must be string',
                                                                        },
                                                                    ];
                                                                return false;
                                                            }
                                                            var valid3 =
                                                                _errs10 ===
                                                                errors;
                                                        } else {
                                                            var valid3 = true;
                                                        }
                                                        if (valid3) {
                                                            if (
                                                                data1.uuid !==
                                                                undefined
                                                            ) {
                                                                const _errs12 =
                                                                    errors;
                                                                if (
                                                                    typeof data1.uuid !==
                                                                    'string'
                                                                ) {
                                                                    validate21.errors =
                                                                        [
                                                                            {
                                                                                instancePath:
                                                                                    instancePath +
                                                                                    '/idp/' +
                                                                                    i0 +
                                                                                    '/uuid',
                                                                                schemaPath:
                                                                                    '#/definitions/request_token/properties/403/definitions/Idp/properties/uuid/type',
                                                                                keyword:
                                                                                    'type',
                                                                                params: {
                                                                                    type: 'string',
                                                                                },
                                                                                message:
                                                                                    'must be string',
                                                                            },
                                                                        ];
                                                                    return false;
                                                                }
                                                                var valid3 =
                                                                    _errs12 ===
                                                                    errors;
                                                            } else {
                                                                var valid3 =
                                                                    true;
                                                            }
                                                            if (valid3) {
                                                                if (
                                                                    data1.disable_popup_flow !==
                                                                    undefined
                                                                ) {
                                                                    const _errs14 =
                                                                        errors;
                                                                    if (
                                                                        typeof data1.disable_popup_flow !==
                                                                        'boolean'
                                                                    ) {
                                                                        validate21.errors =
                                                                            [
                                                                                {
                                                                                    instancePath:
                                                                                        instancePath +
                                                                                        '/idp/' +
                                                                                        i0 +
                                                                                        '/disable_popup_flow',
                                                                                    schemaPath:
                                                                                        '#/definitions/request_token/properties/403/definitions/Idp/properties/disable_popup_flow/type',
                                                                                    keyword:
                                                                                        'type',
                                                                                    params: {
                                                                                        type: 'boolean',
                                                                                    },
                                                                                    message:
                                                                                        'must be boolean',
                                                                                },
                                                                            ];
                                                                        return false;
                                                                    }
                                                                    var valid3 =
                                                                        _errs14 ===
                                                                        errors;
                                                                } else {
                                                                    var valid3 =
                                                                        true;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        } else {
                                            validate21.errors = [
                                                {
                                                    instancePath:
                                                        instancePath +
                                                        '/idp/' +
                                                        i0,
                                                    schemaPath:
                                                        '#/definitions/request_token/properties/403/definitions/Idp/type',
                                                    keyword: 'type',
                                                    params: {type: 'object'},
                                                    message: 'must be object',
                                                },
                                            ];
                                            return false;
                                        }
                                    }
                                    var valid1 = _errs4 === errors;
                                    if (!valid1) {
                                        break;
                                    }
                                }
                            } else {
                                validate21.errors = [
                                    {
                                        instancePath: instancePath + '/idp',
                                        schemaPath: '#/properties/idp/type',
                                        keyword: 'type',
                                        params: {type: 'array'},
                                        message: 'must be array',
                                    },
                                ];
                                return false;
                            }
                        }
                        var valid0 = _errs2 === errors;
                    } else {
                        var valid0 = true;
                    }
                    if (valid0) {
                        if (data.version !== undefined) {
                            let data6 = data.version;
                            const _errs16 = errors;
                            const _errs17 = errors;
                            if (errors === _errs17) {
                                if (
                                    data6 &&
                                    typeof data6 == 'object' &&
                                    !Array.isArray(data6)
                                ) {
                                    const _errs19 = errors;
                                    for (const key2 in data6) {
                                        if (
                                            !(
                                                key2 === 'version_id' ||
                                                key2 === 'pseudo_version'
                                            )
                                        ) {
                                            delete data6[key2];
                                        }
                                    }
                                    if (_errs19 === errors) {
                                        if (data6.version_id !== undefined) {
                                            const _errs20 = errors;
                                            if (
                                                typeof data6.version_id !==
                                                'string'
                                            ) {
                                                validate21.errors = [
                                                    {
                                                        instancePath:
                                                            instancePath +
                                                            '/version/version_id',
                                                        schemaPath:
                                                            '#/definitions/request_token/properties/403/definitions/Version/properties/version_id/type',
                                                        keyword: 'type',
                                                        params: {
                                                            type: 'string',
                                                        },
                                                        message:
                                                            'must be string',
                                                    },
                                                ];
                                                return false;
                                            }
                                            var valid5 = _errs20 === errors;
                                        } else {
                                            var valid5 = true;
                                        }
                                        if (valid5) {
                                            if (
                                                data6.pseudo_version !==
                                                undefined
                                            ) {
                                                const _errs22 = errors;
                                                if (
                                                    typeof data6.pseudo_version !==
                                                    'string'
                                                ) {
                                                    validate21.errors = [
                                                        {
                                                            instancePath:
                                                                instancePath +
                                                                '/version/pseudo_version',
                                                            schemaPath:
                                                                '#/definitions/request_token/properties/403/definitions/Version/properties/pseudo_version/type',
                                                            keyword: 'type',
                                                            params: {
                                                                type: 'string',
                                                            },
                                                            message:
                                                                'must be string',
                                                        },
                                                    ];
                                                    return false;
                                                }
                                                var valid5 = _errs22 === errors;
                                            } else {
                                                var valid5 = true;
                                            }
                                        }
                                    }
                                } else {
                                    validate21.errors = [
                                        {
                                            instancePath:
                                                instancePath + '/version',
                                            schemaPath:
                                                '#/definitions/request_token/properties/403/definitions/Version/type',
                                            keyword: 'type',
                                            params: {type: 'object'},
                                            message: 'must be object',
                                        },
                                    ];
                                    return false;
                                }
                            }
                            var valid0 = _errs16 === errors;
                        } else {
                            var valid0 = true;
                        }
                    }
                }
            }
        } else {
            validate21.errors = [
                {
                    instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: {type: 'object'},
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate21.errors = vErrors;
    return errors === 0;
}
const schema27 = {
    type: 'object',
    description:
        'If the conference is PIN-protected, the PIN must be specified in a `pin` HTTP header. If the PIN is required but is incorrect or missing, a `403 Forbidden` error is returned.',
    additionalProperties: false,
    properties: {
        redirect_url: {
            type: 'string',
            description:
                "`<IdP's SSO URL>?SAMLRequest=<Base64 encoded SAML AuthNRequest message>`",
        },
        redirect_idp: {
            $ref: '#/definitions/request_token/properties/403/definitions/RedirectIdp',
        },
        version: {
            $ref: '#/definitions/request_token/properties/403/definitions/Version',
        },
        disable_popup_flow: {
            type: 'boolean',
            description:
                'If true, the IDP supports a direct redirect flow without popup. The sso_redirect_target is validated at this point.',
        },
    },
    required: ['redirect_url', 'redirect_idp', 'version'],
    title: 'ResultRedirect',
};
const schema28 = {
    type: 'object',
    additionalProperties: false,
    properties: {name: {type: 'string'}, uuid: {type: 'string'}},
    required: ['name', 'uuid'],
    title: 'RedirectIdp',
};
function validate23(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.redirect_url === undefined &&
                    (missing0 = 'redirect_url')) ||
                (data.redirect_idp === undefined &&
                    (missing0 = 'redirect_idp')) ||
                (data.version === undefined && (missing0 = 'version'))
            ) {
                validate23.errors = [
                    {
                        instancePath,
                        schemaPath: '#/required',
                        keyword: 'required',
                        params: {missingProperty: missing0},
                        message:
                            "must have required property '" + missing0 + "'",
                    },
                ];
                return false;
            } else {
                const _errs1 = errors;
                for (const key0 in data) {
                    if (
                        !(
                            key0 === 'redirect_url' ||
                            key0 === 'redirect_idp' ||
                            key0 === 'version' ||
                            key0 === 'disable_popup_flow'
                        )
                    ) {
                        delete data[key0];
                    }
                }
                if (_errs1 === errors) {
                    if (data.redirect_url !== undefined) {
                        const _errs2 = errors;
                        if (typeof data.redirect_url !== 'string') {
                            validate23.errors = [
                                {
                                    instancePath:
                                        instancePath + '/redirect_url',
                                    schemaPath:
                                        '#/properties/redirect_url/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                },
                            ];
                            return false;
                        }
                        var valid0 = _errs2 === errors;
                    } else {
                        var valid0 = true;
                    }
                    if (valid0) {
                        if (data.redirect_idp !== undefined) {
                            let data1 = data.redirect_idp;
                            const _errs4 = errors;
                            const _errs5 = errors;
                            if (errors === _errs5) {
                                if (
                                    data1 &&
                                    typeof data1 == 'object' &&
                                    !Array.isArray(data1)
                                ) {
                                    let missing1;
                                    if (
                                        (data1.name === undefined &&
                                            (missing1 = 'name')) ||
                                        (data1.uuid === undefined &&
                                            (missing1 = 'uuid'))
                                    ) {
                                        validate23.errors = [
                                            {
                                                instancePath:
                                                    instancePath +
                                                    '/redirect_idp',
                                                schemaPath:
                                                    '#/definitions/request_token/properties/403/definitions/RedirectIdp/required',
                                                keyword: 'required',
                                                params: {
                                                    missingProperty: missing1,
                                                },
                                                message:
                                                    "must have required property '" +
                                                    missing1 +
                                                    "'",
                                            },
                                        ];
                                        return false;
                                    } else {
                                        const _errs7 = errors;
                                        for (const key1 in data1) {
                                            if (
                                                !(
                                                    key1 === 'name' ||
                                                    key1 === 'uuid'
                                                )
                                            ) {
                                                delete data1[key1];
                                            }
                                        }
                                        if (_errs7 === errors) {
                                            if (data1.name !== undefined) {
                                                const _errs8 = errors;
                                                if (
                                                    typeof data1.name !==
                                                    'string'
                                                ) {
                                                    validate23.errors = [
                                                        {
                                                            instancePath:
                                                                instancePath +
                                                                '/redirect_idp/name',
                                                            schemaPath:
                                                                '#/definitions/request_token/properties/403/definitions/RedirectIdp/properties/name/type',
                                                            keyword: 'type',
                                                            params: {
                                                                type: 'string',
                                                            },
                                                            message:
                                                                'must be string',
                                                        },
                                                    ];
                                                    return false;
                                                }
                                                var valid2 = _errs8 === errors;
                                            } else {
                                                var valid2 = true;
                                            }
                                            if (valid2) {
                                                if (data1.uuid !== undefined) {
                                                    const _errs10 = errors;
                                                    if (
                                                        typeof data1.uuid !==
                                                        'string'
                                                    ) {
                                                        validate23.errors = [
                                                            {
                                                                instancePath:
                                                                    instancePath +
                                                                    '/redirect_idp/uuid',
                                                                schemaPath:
                                                                    '#/definitions/request_token/properties/403/definitions/RedirectIdp/properties/uuid/type',
                                                                keyword: 'type',
                                                                params: {
                                                                    type: 'string',
                                                                },
                                                                message:
                                                                    'must be string',
                                                            },
                                                        ];
                                                        return false;
                                                    }
                                                    var valid2 =
                                                        _errs10 === errors;
                                                } else {
                                                    var valid2 = true;
                                                }
                                            }
                                        }
                                    }
                                } else {
                                    validate23.errors = [
                                        {
                                            instancePath:
                                                instancePath + '/redirect_idp',
                                            schemaPath:
                                                '#/definitions/request_token/properties/403/definitions/RedirectIdp/type',
                                            keyword: 'type',
                                            params: {type: 'object'},
                                            message: 'must be object',
                                        },
                                    ];
                                    return false;
                                }
                            }
                            var valid0 = _errs4 === errors;
                        } else {
                            var valid0 = true;
                        }
                        if (valid0) {
                            if (data.version !== undefined) {
                                let data4 = data.version;
                                const _errs12 = errors;
                                const _errs13 = errors;
                                if (errors === _errs13) {
                                    if (
                                        data4 &&
                                        typeof data4 == 'object' &&
                                        !Array.isArray(data4)
                                    ) {
                                        const _errs15 = errors;
                                        for (const key2 in data4) {
                                            if (
                                                !(
                                                    key2 === 'version_id' ||
                                                    key2 === 'pseudo_version'
                                                )
                                            ) {
                                                delete data4[key2];
                                            }
                                        }
                                        if (_errs15 === errors) {
                                            if (
                                                data4.version_id !== undefined
                                            ) {
                                                const _errs16 = errors;
                                                if (
                                                    typeof data4.version_id !==
                                                    'string'
                                                ) {
                                                    validate23.errors = [
                                                        {
                                                            instancePath:
                                                                instancePath +
                                                                '/version/version_id',
                                                            schemaPath:
                                                                '#/definitions/request_token/properties/403/definitions/Version/properties/version_id/type',
                                                            keyword: 'type',
                                                            params: {
                                                                type: 'string',
                                                            },
                                                            message:
                                                                'must be string',
                                                        },
                                                    ];
                                                    return false;
                                                }
                                                var valid4 = _errs16 === errors;
                                            } else {
                                                var valid4 = true;
                                            }
                                            if (valid4) {
                                                if (
                                                    data4.pseudo_version !==
                                                    undefined
                                                ) {
                                                    const _errs18 = errors;
                                                    if (
                                                        typeof data4.pseudo_version !==
                                                        'string'
                                                    ) {
                                                        validate23.errors = [
                                                            {
                                                                instancePath:
                                                                    instancePath +
                                                                    '/version/pseudo_version',
                                                                schemaPath:
                                                                    '#/definitions/request_token/properties/403/definitions/Version/properties/pseudo_version/type',
                                                                keyword: 'type',
                                                                params: {
                                                                    type: 'string',
                                                                },
                                                                message:
                                                                    'must be string',
                                                            },
                                                        ];
                                                        return false;
                                                    }
                                                    var valid4 =
                                                        _errs18 === errors;
                                                } else {
                                                    var valid4 = true;
                                                }
                                            }
                                        }
                                    } else {
                                        validate23.errors = [
                                            {
                                                instancePath:
                                                    instancePath + '/version',
                                                schemaPath:
                                                    '#/definitions/request_token/properties/403/definitions/Version/type',
                                                keyword: 'type',
                                                params: {type: 'object'},
                                                message: 'must be object',
                                            },
                                        ];
                                        return false;
                                    }
                                }
                                var valid0 = _errs12 === errors;
                            } else {
                                var valid0 = true;
                            }
                            if (valid0) {
                                if (data.disable_popup_flow !== undefined) {
                                    const _errs20 = errors;
                                    if (
                                        typeof data.disable_popup_flow !==
                                        'boolean'
                                    ) {
                                        validate23.errors = [
                                            {
                                                instancePath:
                                                    instancePath +
                                                    '/disable_popup_flow',
                                                schemaPath:
                                                    '#/properties/disable_popup_flow/type',
                                                keyword: 'type',
                                                params: {type: 'boolean'},
                                                message: 'must be boolean',
                                            },
                                        ];
                                        return false;
                                    }
                                    var valid0 = _errs20 === errors;
                                } else {
                                    var valid0 = true;
                                }
                            }
                        }
                    }
                }
            }
        } else {
            validate23.errors = [
                {
                    instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: {type: 'object'},
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate23.errors = vErrors;
    return errors === 0;
}
function validate18(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                validate18.errors = [
                    {
                        instancePath,
                        schemaPath: '#/required',
                        keyword: 'required',
                        params: {missingProperty: missing0},
                        message:
                            "must have required property '" + missing0 + "'",
                    },
                ];
                return false;
            } else {
                const _errs1 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs1 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs2 = errors;
                        if (typeof data0 !== 'string') {
                            validate18.errors = [
                                {
                                    instancePath: instancePath + '/status',
                                    schemaPath: '#/properties/status/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                },
                            ];
                            return false;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            validate18.errors = [
                                {
                                    instancePath: instancePath + '/status',
                                    schemaPath: '#/properties/status/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema21.properties.status.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                },
                            ];
                            return false;
                        }
                        var valid0 = _errs2 === errors;
                    } else {
                        var valid0 = true;
                    }
                    if (valid0) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs4 = errors;
                            const _errs5 = errors;
                            let valid1 = false;
                            let passing0 = null;
                            const _errs6 = errors;
                            if (
                                !validate19(data1, {
                                    instancePath: instancePath + '/result',
                                    parentData: data,
                                    parentDataProperty: 'result',
                                    rootData,
                                })
                            ) {
                                vErrors =
                                    vErrors === null
                                        ? validate19.errors
                                        : vErrors.concat(validate19.errors);
                                errors = vErrors.length;
                            }
                            var _valid0 = _errs6 === errors;
                            if (_valid0) {
                                valid1 = true;
                                passing0 = 0;
                            }
                            const _errs7 = errors;
                            if (
                                !validate21(data1, {
                                    instancePath: instancePath + '/result',
                                    parentData: data,
                                    parentDataProperty: 'result',
                                    rootData,
                                })
                            ) {
                                vErrors =
                                    vErrors === null
                                        ? validate21.errors
                                        : vErrors.concat(validate21.errors);
                                errors = vErrors.length;
                            }
                            var _valid0 = _errs7 === errors;
                            if (_valid0 && valid1) {
                                valid1 = false;
                                passing0 = [passing0, 1];
                            } else {
                                if (_valid0) {
                                    valid1 = true;
                                    passing0 = 1;
                                }
                                const _errs8 = errors;
                                if (
                                    !validate23(data1, {
                                        instancePath: instancePath + '/result',
                                        parentData: data,
                                        parentDataProperty: 'result',
                                        rootData,
                                    })
                                ) {
                                    vErrors =
                                        vErrors === null
                                            ? validate23.errors
                                            : vErrors.concat(validate23.errors);
                                    errors = vErrors.length;
                                }
                                var _valid0 = _errs8 === errors;
                                if (_valid0 && valid1) {
                                    valid1 = false;
                                    passing0 = [passing0, 2];
                                } else {
                                    if (_valid0) {
                                        valid1 = true;
                                        passing0 = 2;
                                    }
                                    const _errs9 = errors;
                                    const _errs10 = errors;
                                    if (errors === _errs10) {
                                        if (
                                            data1 &&
                                            typeof data1 == 'object' &&
                                            !Array.isArray(data1)
                                        ) {
                                            let missing1;
                                            if (
                                                (data1.conference_extension ===
                                                    undefined &&
                                                    (missing1 =
                                                        'conference_extension')) ||
                                                (data1.conference_extension_type ===
                                                    undefined &&
                                                    (missing1 =
                                                        'conference_extension_type'))
                                            ) {
                                                const err0 = {
                                                    instancePath:
                                                        instancePath +
                                                        '/result',
                                                    schemaPath:
                                                        '#/definitions/request_token/properties/403/definitions/ResultExtension/required',
                                                    keyword: 'required',
                                                    params: {
                                                        missingProperty:
                                                            missing1,
                                                    },
                                                    message:
                                                        "must have required property '" +
                                                        missing1 +
                                                        "'",
                                                };
                                                if (vErrors === null) {
                                                    vErrors = [err0];
                                                } else {
                                                    vErrors.push(err0);
                                                }
                                                errors++;
                                            } else {
                                                const _errs12 = errors;
                                                for (const key1 in data1) {
                                                    if (
                                                        !(
                                                            key1 ===
                                                                'conference_extension' ||
                                                            key1 ===
                                                                'conference_extension_type'
                                                        )
                                                    ) {
                                                        delete data1[key1];
                                                    }
                                                }
                                                if (_errs12 === errors) {
                                                    if (
                                                        data1.conference_extension !==
                                                        undefined
                                                    ) {
                                                        let data2 =
                                                            data1.conference_extension;
                                                        const _errs13 = errors;
                                                        if (
                                                            typeof data2 !==
                                                            'string'
                                                        ) {
                                                            const err1 = {
                                                                instancePath:
                                                                    instancePath +
                                                                    '/result/conference_extension',
                                                                schemaPath:
                                                                    '#/definitions/request_token/properties/403/definitions/ResultExtension/properties/conference_extension/type',
                                                                keyword: 'type',
                                                                params: {
                                                                    type: 'string',
                                                                },
                                                                message:
                                                                    'must be string',
                                                            };
                                                            if (
                                                                vErrors === null
                                                            ) {
                                                                vErrors = [
                                                                    err1,
                                                                ];
                                                            } else {
                                                                vErrors.push(
                                                                    err1,
                                                                );
                                                            }
                                                            errors++;
                                                        }
                                                        if (
                                                            !(
                                                                data2 ===
                                                                'required'
                                                            )
                                                        ) {
                                                            const err2 = {
                                                                instancePath:
                                                                    instancePath +
                                                                    '/result/conference_extension',
                                                                schemaPath:
                                                                    '#/definitions/request_token/properties/403/definitions/ResultExtension/properties/conference_extension/enum',
                                                                keyword: 'enum',
                                                                params: {
                                                                    allowedValues:
                                                                        schema30
                                                                            .properties
                                                                            .conference_extension
                                                                            .enum,
                                                                },
                                                                message:
                                                                    'must be equal to one of the allowed values',
                                                            };
                                                            if (
                                                                vErrors === null
                                                            ) {
                                                                vErrors = [
                                                                    err2,
                                                                ];
                                                            } else {
                                                                vErrors.push(
                                                                    err2,
                                                                );
                                                            }
                                                            errors++;
                                                        }
                                                        var valid3 =
                                                            _errs13 === errors;
                                                    } else {
                                                        var valid3 = true;
                                                    }
                                                    if (valid3) {
                                                        if (
                                                            data1.conference_extension_type !==
                                                            undefined
                                                        ) {
                                                            let data3 =
                                                                data1.conference_extension_type;
                                                            const _errs15 =
                                                                errors;
                                                            if (
                                                                typeof data3 !==
                                                                'string'
                                                            ) {
                                                                const err3 = {
                                                                    instancePath:
                                                                        instancePath +
                                                                        '/result/conference_extension_type',
                                                                    schemaPath:
                                                                        '#/definitions/request_token/properties/403/definitions/ResultExtension/properties/conference_extension_type/type',
                                                                    keyword:
                                                                        'type',
                                                                    params: {
                                                                        type: 'string',
                                                                    },
                                                                    message:
                                                                        'must be string',
                                                                };
                                                                if (
                                                                    vErrors ===
                                                                    null
                                                                ) {
                                                                    vErrors = [
                                                                        err3,
                                                                    ];
                                                                } else {
                                                                    vErrors.push(
                                                                        err3,
                                                                    );
                                                                }
                                                                errors++;
                                                            }
                                                            if (
                                                                !(
                                                                    data3 ===
                                                                        'mssip' ||
                                                                    data3 ===
                                                                        'standard'
                                                                )
                                                            ) {
                                                                const err4 = {
                                                                    instancePath:
                                                                        instancePath +
                                                                        '/result/conference_extension_type',
                                                                    schemaPath:
                                                                        '#/definitions/request_token/properties/403/definitions/ResultExtension/properties/conference_extension_type/enum',
                                                                    keyword:
                                                                        'enum',
                                                                    params: {
                                                                        allowedValues:
                                                                            schema30
                                                                                .properties
                                                                                .conference_extension_type
                                                                                .enum,
                                                                    },
                                                                    message:
                                                                        'must be equal to one of the allowed values',
                                                                };
                                                                if (
                                                                    vErrors ===
                                                                    null
                                                                ) {
                                                                    vErrors = [
                                                                        err4,
                                                                    ];
                                                                } else {
                                                                    vErrors.push(
                                                                        err4,
                                                                    );
                                                                }
                                                                errors++;
                                                            }
                                                            var valid3 =
                                                                _errs15 ===
                                                                errors;
                                                        } else {
                                                            var valid3 = true;
                                                        }
                                                    }
                                                }
                                            }
                                        } else {
                                            const err5 = {
                                                instancePath:
                                                    instancePath + '/result',
                                                schemaPath:
                                                    '#/definitions/request_token/properties/403/definitions/ResultExtension/type',
                                                keyword: 'type',
                                                params: {type: 'object'},
                                                message: 'must be object',
                                            };
                                            if (vErrors === null) {
                                                vErrors = [err5];
                                            } else {
                                                vErrors.push(err5);
                                            }
                                            errors++;
                                        }
                                    }
                                    var _valid0 = _errs9 === errors;
                                    if (_valid0 && valid1) {
                                        valid1 = false;
                                        passing0 = [passing0, 3];
                                    } else {
                                        if (_valid0) {
                                            valid1 = true;
                                            passing0 = 3;
                                        }
                                        const _errs17 = errors;
                                        if (typeof data1 !== 'string') {
                                            const err6 = {
                                                instancePath:
                                                    instancePath + '/result',
                                                schemaPath:
                                                    '#/definitions/request_token/properties/415/definitions/415/properties/result/type',
                                                keyword: 'type',
                                                params: {type: 'string'},
                                                message: 'must be string',
                                            };
                                            if (vErrors === null) {
                                                vErrors = [err6];
                                            } else {
                                                vErrors.push(err6);
                                            }
                                            errors++;
                                        }
                                        var _valid0 = _errs17 === errors;
                                        if (_valid0 && valid1) {
                                            valid1 = false;
                                            passing0 = [passing0, 4];
                                        } else {
                                            if (_valid0) {
                                                valid1 = true;
                                                passing0 = 4;
                                            }
                                        }
                                    }
                                }
                            }
                            if (!valid1) {
                                const err7 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath: '#/properties/result/oneOf',
                                    keyword: 'oneOf',
                                    params: {passingSchemas: passing0},
                                    message:
                                        'must match exactly one schema in oneOf',
                                };
                                if (vErrors === null) {
                                    vErrors = [err7];
                                } else {
                                    vErrors.push(err7);
                                }
                                errors++;
                                validate18.errors = vErrors;
                                return false;
                            } else {
                                errors = _errs5;
                                if (vErrors !== null) {
                                    if (_errs5) {
                                        vErrors.length = _errs5;
                                    } else {
                                        vErrors = null;
                                    }
                                }
                            }
                            var valid0 = _errs4 === errors;
                        } else {
                            var valid0 = true;
                        }
                    }
                }
            }
        } else {
            validate18.errors = [
                {
                    instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: {type: 'object'},
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate18.errors = vErrors;
    return errors === 0;
}
function validate17(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    if (
        !validate18(data, {
            instancePath,
            parentData,
            parentDataProperty,
            rootData,
        })
    ) {
        vErrors =
            vErrors === null
                ? validate18.errors
                : vErrors.concat(validate18.errors);
        errors = vErrors.length;
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err0 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err0];
        } else {
            vErrors.push(err0);
        }
        errors++;
        validate17.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate17.errors = vErrors;
    return errors === 0;
}
export const validateRequestToken404 = validate26;
const schema32 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '404': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['failed'],
                },
                result: {type: 'string', description: 'Error string'},
            },
            required: ['status', 'result'],
            title: 'Status404',
        },
    },
    oneOf: [
        {$ref: '#/definitions/request_token/properties/404/definitions/404'},
    ],
};
const schema33 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['failed'],
        },
        result: {type: 'string', description: 'Error string'},
    },
    required: ['status', 'result'],
    title: 'Status404',
};
function validate26(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/request_token/properties/404/definitions/404/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/request_token/properties/404/definitions/404/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/request_token/properties/404/definitions/404/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema33.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/request_token/properties/404/definitions/404/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/request_token/properties/404/definitions/404/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate26.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate26.errors = vErrors;
    return errors === 0;
}
export const validateRequestToken415 = validate27;
const schema34 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '415': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. failed.',
                    enum: ['failed'],
                },
                result: {type: 'string', description: 'Error strings'},
            },
            required: ['status', 'result'],
            title: 'Status415',
        },
    },
    oneOf: [
        {$ref: '#/definitions/request_token/properties/415/definitions/415'},
    ],
};
const schema35 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. failed.',
            enum: ['failed'],
        },
        result: {type: 'string', description: 'Error strings'},
    },
    required: ['status', 'result'],
    title: 'Status415',
};
function validate27(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/request_token/properties/415/definitions/415/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/request_token/properties/415/definitions/415/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'failed')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/request_token/properties/415/definitions/415/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema35.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'string') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/request_token/properties/415/definitions/415/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/request_token/properties/415/definitions/415/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate27.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate27.errors = vErrors;
    return errors === 0;
}
export const validateRequestToken502 = validate28;
const schema36 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '5xx': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. failed.',
                    enum: ['failed'],
                },
                result: {
                    $ref: '#/definitions/request_token/properties/415/definitions/415/properties/result',
                },
            },
            required: ['status', 'result'],
            title: 'Status5xx',
        },
    },
    oneOf: [
        {$ref: '#/definitions/request_token/properties/502/definitions/5xx'},
    ],
};
const schema37 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. failed.',
            enum: ['failed'],
        },
        result: {
            $ref: '#/definitions/request_token/properties/415/definitions/415/properties/result',
        },
    },
    required: ['status', 'result'],
    title: 'Status5xx',
};
function validate29(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                validate29.errors = [
                    {
                        instancePath,
                        schemaPath: '#/required',
                        keyword: 'required',
                        params: {missingProperty: missing0},
                        message:
                            "must have required property '" + missing0 + "'",
                    },
                ];
                return false;
            } else {
                const _errs1 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs1 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs2 = errors;
                        if (typeof data0 !== 'string') {
                            validate29.errors = [
                                {
                                    instancePath: instancePath + '/status',
                                    schemaPath: '#/properties/status/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                },
                            ];
                            return false;
                        }
                        if (!(data0 === 'failed')) {
                            validate29.errors = [
                                {
                                    instancePath: instancePath + '/status',
                                    schemaPath: '#/properties/status/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema37.properties.status.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                },
                            ];
                            return false;
                        }
                        var valid0 = _errs2 === errors;
                    } else {
                        var valid0 = true;
                    }
                    if (valid0) {
                        if (data.result !== undefined) {
                            const _errs4 = errors;
                            if (typeof data.result !== 'string') {
                                validate29.errors = [
                                    {
                                        instancePath: instancePath + '/result',
                                        schemaPath:
                                            '#/definitions/request_token/properties/415/definitions/415/properties/result/type',
                                        keyword: 'type',
                                        params: {type: 'string'},
                                        message: 'must be string',
                                    },
                                ];
                                return false;
                            }
                            var valid0 = _errs4 === errors;
                        } else {
                            var valid0 = true;
                        }
                    }
                }
            }
        } else {
            validate29.errors = [
                {
                    instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: {type: 'object'},
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate29.errors = vErrors;
    return errors === 0;
}
function validate28(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    if (
        !validate29(data, {
            instancePath,
            parentData,
            parentDataProperty,
            rootData,
        })
    ) {
        vErrors =
            vErrors === null
                ? validate29.errors
                : vErrors.concat(validate29.errors);
        errors = vErrors.length;
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err0 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err0];
        } else {
            vErrors.push(err0);
        }
        errors++;
        validate28.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate28.errors = vErrors;
    return errors === 0;
}
export const validateRequestToken503 = validate31;
function validate31(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    if (
        !validate29(data, {
            instancePath,
            parentData,
            parentDataProperty,
            rootData,
        })
    ) {
        vErrors =
            vErrors === null
                ? validate29.errors
                : vErrors.concat(validate29.errors);
        errors = vErrors.length;
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err0 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err0];
        } else {
            vErrors.push(err0);
        }
        errors++;
        validate31.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate31.errors = vErrors;
    return errors === 0;
}
export const validateRequestToken504 = validate33;
function validate33(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    if (
        !validate29(data, {
            instancePath,
            parentData,
            parentDataProperty,
            rootData,
        })
    ) {
        vErrors =
            vErrors === null
                ? validate29.errors
                : vErrors.concat(validate29.errors);
        errors = vErrors.length;
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err0 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err0];
        } else {
            vErrors.push(err0);
        }
        errors++;
        validate33.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate33.errors = vErrors;
    return errors === 0;
}
export const validateRequestToken529 = validate35;
function validate35(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    if (
        !validate29(data, {
            instancePath,
            parentData,
            parentDataProperty,
            rootData,
        })
    ) {
        vErrors =
            vErrors === null
                ? validate29.errors
                : vErrors.concat(validate29.errors);
        errors = vErrors.length;
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err0 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err0];
        } else {
            vErrors.push(err0);
        }
        errors++;
        validate35.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate35.errors = vErrors;
    return errors === 0;
}
export const validateRefreshToken200 = validate37;
const schema42 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '200': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success'],
                },
                result: {
                    $ref: '#/definitions/refresh_token/properties/200/definitions/Result',
                },
            },
            required: ['status', 'result'],
            title: 'Status200',
        },
        Result: {
            type: 'object',
            additionalProperties: false,
            properties: {
                token: {
                    type: 'string',
                    description:
                        'The new authentication token for future requests.',
                },
                role: {
                    type: 'string',
                    enum: ['HOST', 'GUEST'],
                    description:
                        'Whether the participant is connecting as a `HOST` or a `GUEST`.',
                },
                expires: {
                    type: 'string',
                    description: 'Validity lifetime in seconds.',
                },
                client_stats_update_interval: {
                    type: 'number',
                    description: 'Signals the statistics update interval',
                },
            },
            required: ['token', 'expires'],
            title: 'Result',
        },
    },
    oneOf: [
        {$ref: '#/definitions/refresh_token/properties/200/definitions/200'},
    ],
};
const schema43 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success'],
        },
        result: {
            $ref: '#/definitions/refresh_token/properties/200/definitions/Result',
        },
    },
    required: ['status', 'result'],
    title: 'Status200',
};
const schema44 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        token: {
            type: 'string',
            description: 'The new authentication token for future requests.',
        },
        role: {
            type: 'string',
            enum: ['HOST', 'GUEST'],
            description:
                'Whether the participant is connecting as a `HOST` or a `GUEST`.',
        },
        expires: {type: 'string', description: 'Validity lifetime in seconds.'},
        client_stats_update_interval: {
            type: 'number',
            description: 'Signals the statistics update interval',
        },
    },
    required: ['token', 'expires'],
    title: 'Result',
};
function validate38(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                validate38.errors = [
                    {
                        instancePath,
                        schemaPath: '#/required',
                        keyword: 'required',
                        params: {missingProperty: missing0},
                        message:
                            "must have required property '" + missing0 + "'",
                    },
                ];
                return false;
            } else {
                const _errs1 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs1 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs2 = errors;
                        if (typeof data0 !== 'string') {
                            validate38.errors = [
                                {
                                    instancePath: instancePath + '/status',
                                    schemaPath: '#/properties/status/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                },
                            ];
                            return false;
                        }
                        if (!(data0 === 'success')) {
                            validate38.errors = [
                                {
                                    instancePath: instancePath + '/status',
                                    schemaPath: '#/properties/status/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema43.properties.status.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                },
                            ];
                            return false;
                        }
                        var valid0 = _errs2 === errors;
                    } else {
                        var valid0 = true;
                    }
                    if (valid0) {
                        if (data.result !== undefined) {
                            let data1 = data.result;
                            const _errs4 = errors;
                            const _errs5 = errors;
                            if (errors === _errs5) {
                                if (
                                    data1 &&
                                    typeof data1 == 'object' &&
                                    !Array.isArray(data1)
                                ) {
                                    let missing1;
                                    if (
                                        (data1.token === undefined &&
                                            (missing1 = 'token')) ||
                                        (data1.expires === undefined &&
                                            (missing1 = 'expires'))
                                    ) {
                                        validate38.errors = [
                                            {
                                                instancePath:
                                                    instancePath + '/result',
                                                schemaPath:
                                                    '#/definitions/refresh_token/properties/200/definitions/Result/required',
                                                keyword: 'required',
                                                params: {
                                                    missingProperty: missing1,
                                                },
                                                message:
                                                    "must have required property '" +
                                                    missing1 +
                                                    "'",
                                            },
                                        ];
                                        return false;
                                    } else {
                                        const _errs7 = errors;
                                        for (const key1 in data1) {
                                            if (
                                                !(
                                                    key1 === 'token' ||
                                                    key1 === 'role' ||
                                                    key1 === 'expires' ||
                                                    key1 ===
                                                        'client_stats_update_interval'
                                                )
                                            ) {
                                                delete data1[key1];
                                            }
                                        }
                                        if (_errs7 === errors) {
                                            if (data1.token !== undefined) {
                                                const _errs8 = errors;
                                                if (
                                                    typeof data1.token !==
                                                    'string'
                                                ) {
                                                    validate38.errors = [
                                                        {
                                                            instancePath:
                                                                instancePath +
                                                                '/result/token',
                                                            schemaPath:
                                                                '#/definitions/refresh_token/properties/200/definitions/Result/properties/token/type',
                                                            keyword: 'type',
                                                            params: {
                                                                type: 'string',
                                                            },
                                                            message:
                                                                'must be string',
                                                        },
                                                    ];
                                                    return false;
                                                }
                                                var valid2 = _errs8 === errors;
                                            } else {
                                                var valid2 = true;
                                            }
                                            if (valid2) {
                                                if (data1.role !== undefined) {
                                                    let data3 = data1.role;
                                                    const _errs10 = errors;
                                                    if (
                                                        typeof data3 !==
                                                        'string'
                                                    ) {
                                                        validate38.errors = [
                                                            {
                                                                instancePath:
                                                                    instancePath +
                                                                    '/result/role',
                                                                schemaPath:
                                                                    '#/definitions/refresh_token/properties/200/definitions/Result/properties/role/type',
                                                                keyword: 'type',
                                                                params: {
                                                                    type: 'string',
                                                                },
                                                                message:
                                                                    'must be string',
                                                            },
                                                        ];
                                                        return false;
                                                    }
                                                    if (
                                                        !(
                                                            data3 === 'HOST' ||
                                                            data3 === 'GUEST'
                                                        )
                                                    ) {
                                                        validate38.errors = [
                                                            {
                                                                instancePath:
                                                                    instancePath +
                                                                    '/result/role',
                                                                schemaPath:
                                                                    '#/definitions/refresh_token/properties/200/definitions/Result/properties/role/enum',
                                                                keyword: 'enum',
                                                                params: {
                                                                    allowedValues:
                                                                        schema44
                                                                            .properties
                                                                            .role
                                                                            .enum,
                                                                },
                                                                message:
                                                                    'must be equal to one of the allowed values',
                                                            },
                                                        ];
                                                        return false;
                                                    }
                                                    var valid2 =
                                                        _errs10 === errors;
                                                } else {
                                                    var valid2 = true;
                                                }
                                                if (valid2) {
                                                    if (
                                                        data1.expires !==
                                                        undefined
                                                    ) {
                                                        const _errs12 = errors;
                                                        if (
                                                            typeof data1.expires !==
                                                            'string'
                                                        ) {
                                                            validate38.errors =
                                                                [
                                                                    {
                                                                        instancePath:
                                                                            instancePath +
                                                                            '/result/expires',
                                                                        schemaPath:
                                                                            '#/definitions/refresh_token/properties/200/definitions/Result/properties/expires/type',
                                                                        keyword:
                                                                            'type',
                                                                        params: {
                                                                            type: 'string',
                                                                        },
                                                                        message:
                                                                            'must be string',
                                                                    },
                                                                ];
                                                            return false;
                                                        }
                                                        var valid2 =
                                                            _errs12 === errors;
                                                    } else {
                                                        var valid2 = true;
                                                    }
                                                    if (valid2) {
                                                        if (
                                                            data1.client_stats_update_interval !==
                                                            undefined
                                                        ) {
                                                            const _errs14 =
                                                                errors;
                                                            if (
                                                                !(
                                                                    typeof data1.client_stats_update_interval ==
                                                                    'number'
                                                                )
                                                            ) {
                                                                validate38.errors =
                                                                    [
                                                                        {
                                                                            instancePath:
                                                                                instancePath +
                                                                                '/result/client_stats_update_interval',
                                                                            schemaPath:
                                                                                '#/definitions/refresh_token/properties/200/definitions/Result/properties/client_stats_update_interval/type',
                                                                            keyword:
                                                                                'type',
                                                                            params: {
                                                                                type: 'number',
                                                                            },
                                                                            message:
                                                                                'must be number',
                                                                        },
                                                                    ];
                                                                return false;
                                                            }
                                                            var valid2 =
                                                                _errs14 ===
                                                                errors;
                                                        } else {
                                                            var valid2 = true;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                } else {
                                    validate38.errors = [
                                        {
                                            instancePath:
                                                instancePath + '/result',
                                            schemaPath:
                                                '#/definitions/refresh_token/properties/200/definitions/Result/type',
                                            keyword: 'type',
                                            params: {type: 'object'},
                                            message: 'must be object',
                                        },
                                    ];
                                    return false;
                                }
                            }
                            var valid0 = _errs4 === errors;
                        } else {
                            var valid0 = true;
                        }
                    }
                }
            }
        } else {
            validate38.errors = [
                {
                    instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: {type: 'object'},
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate38.errors = vErrors;
    return errors === 0;
}
function validate37(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    if (
        !validate38(data, {
            instancePath,
            parentData,
            parentDataProperty,
            rootData,
        })
    ) {
        vErrors =
            vErrors === null
                ? validate38.errors
                : vErrors.concat(validate38.errors);
        errors = vErrors.length;
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err0 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err0];
        } else {
            vErrors.push(err0);
        }
        errors++;
        validate37.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate37.errors = vErrors;
    return errors === 0;
}
export const validateRefreshToken403 = validate40;
const schema45 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '403': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success', 'failed'],
                },
                result: {
                    $ref: '#/definitions/request_token/properties/415/definitions/415/properties/result',
                },
            },
            required: ['status', 'result'],
            title: 'Status403',
        },
    },
    oneOf: [
        {$ref: '#/definitions/refresh_token/properties/403/definitions/403'},
    ],
};
const schema46 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success', 'failed'],
        },
        result: {
            $ref: '#/definitions/request_token/properties/415/definitions/415/properties/result',
        },
    },
    required: ['status', 'result'],
    title: 'Status403',
};
function validate41(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    if (errors === 0) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                validate41.errors = [
                    {
                        instancePath,
                        schemaPath: '#/required',
                        keyword: 'required',
                        params: {missingProperty: missing0},
                        message:
                            "must have required property '" + missing0 + "'",
                    },
                ];
                return false;
            } else {
                const _errs1 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs1 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs2 = errors;
                        if (typeof data0 !== 'string') {
                            validate41.errors = [
                                {
                                    instancePath: instancePath + '/status',
                                    schemaPath: '#/properties/status/type',
                                    keyword: 'type',
                                    params: {type: 'string'},
                                    message: 'must be string',
                                },
                            ];
                            return false;
                        }
                        if (!(data0 === 'success' || data0 === 'failed')) {
                            validate41.errors = [
                                {
                                    instancePath: instancePath + '/status',
                                    schemaPath: '#/properties/status/enum',
                                    keyword: 'enum',
                                    params: {
                                        allowedValues:
                                            schema46.properties.status.enum,
                                    },
                                    message:
                                        'must be equal to one of the allowed values',
                                },
                            ];
                            return false;
                        }
                        var valid0 = _errs2 === errors;
                    } else {
                        var valid0 = true;
                    }
                    if (valid0) {
                        if (data.result !== undefined) {
                            const _errs4 = errors;
                            if (typeof data.result !== 'string') {
                                validate41.errors = [
                                    {
                                        instancePath: instancePath + '/result',
                                        schemaPath:
                                            '#/definitions/request_token/properties/415/definitions/415/properties/result/type',
                                        keyword: 'type',
                                        params: {type: 'string'},
                                        message: 'must be string',
                                    },
                                ];
                                return false;
                            }
                            var valid0 = _errs4 === errors;
                        } else {
                            var valid0 = true;
                        }
                    }
                }
            }
        } else {
            validate41.errors = [
                {
                    instancePath,
                    schemaPath: '#/type',
                    keyword: 'type',
                    params: {type: 'object'},
                    message: 'must be object',
                },
            ];
            return false;
        }
    }
    validate41.errors = vErrors;
    return errors === 0;
}
function validate40(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    if (
        !validate41(data, {
            instancePath,
            parentData,
            parentDataProperty,
            rootData,
        })
    ) {
        vErrors =
            vErrors === null
                ? validate41.errors
                : vErrors.concat(validate41.errors);
        errors = vErrors.length;
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err0 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err0];
        } else {
            vErrors.push(err0);
        }
        errors++;
        validate40.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate40.errors = vErrors;
    return errors === 0;
}
export const validateReleaseTokenBody = validate43;
const schema48 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        TopLevel: {
            type: 'object',
            additionalProperties: false,
            properties: {reason: {type: 'string'}},
            title: 'TopLevel',
        },
    },
    oneOf: [
        {
            $ref: '#/definitions/release_token/properties/Body/definitions/TopLevel',
        },
    ],
};
const schema49 = {
    type: 'object',
    additionalProperties: false,
    properties: {reason: {type: 'string'}},
    title: 'TopLevel',
};
function validate43(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            const _errs4 = errors;
            for (const key0 in data) {
                if (!(key0 === 'reason')) {
                    delete data[key0];
                }
            }
            if (_errs4 === errors) {
                if (data.reason !== undefined) {
                    if (typeof data.reason !== 'string') {
                        const err0 = {
                            instancePath: instancePath + '/reason',
                            schemaPath:
                                '#/definitions/release_token/properties/Body/definitions/TopLevel/properties/reason/type',
                            keyword: 'type',
                            params: {type: 'string'},
                            message: 'must be string',
                        };
                        if (vErrors === null) {
                            vErrors = [err0];
                        } else {
                            vErrors.push(err0);
                        }
                        errors++;
                    }
                }
            }
        } else {
            const err1 = {
                instancePath,
                schemaPath:
                    '#/definitions/release_token/properties/Body/definitions/TopLevel/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err1];
            } else {
                vErrors.push(err1);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err2 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err2];
        } else {
            vErrors.push(err2);
        }
        errors++;
        validate43.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate43.errors = vErrors;
    return errors === 0;
}
export const validateReleaseToken200 = validate44;
const schema50 = {
    $schema: 'http://json-schema.org/draft-06/schema#',
    definitions: {
        '200': {
            type: 'object',
            additionalProperties: false,
            properties: {
                status: {
                    type: 'string',
                    description: 'Status of the response, eg. success.',
                    enum: ['success'],
                },
                result: {
                    type: 'boolean',
                    description:
                        'The result is true if successful, false otherwise.',
                },
            },
            required: ['status', 'result'],
            title: 'Status200',
        },
    },
    oneOf: [
        {$ref: '#/definitions/release_token/properties/200/definitions/200'},
    ],
};
const schema51 = {
    type: 'object',
    additionalProperties: false,
    properties: {
        status: {
            type: 'string',
            description: 'Status of the response, eg. success.',
            enum: ['success'],
        },
        result: {
            type: 'boolean',
            description: 'The result is true if successful, false otherwise.',
        },
    },
    required: ['status', 'result'],
    title: 'Status200',
};
function validate44(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    const _errs2 = errors;
    if (errors === _errs2) {
        if (data && typeof data == 'object' && !Array.isArray(data)) {
            let missing0;
            if (
                (data.status === undefined && (missing0 = 'status')) ||
                (data.result === undefined && (missing0 = 'result'))
            ) {
                const err0 = {
                    instancePath,
                    schemaPath:
                        '#/definitions/release_token/properties/200/definitions/200/required',
                    keyword: 'required',
                    params: {missingProperty: missing0},
                    message: "must have required property '" + missing0 + "'",
                };
                if (vErrors === null) {
                    vErrors = [err0];
                } else {
                    vErrors.push(err0);
                }
                errors++;
            } else {
                const _errs4 = errors;
                for (const key0 in data) {
                    if (!(key0 === 'status' || key0 === 'result')) {
                        delete data[key0];
                    }
                }
                if (_errs4 === errors) {
                    if (data.status !== undefined) {
                        let data0 = data.status;
                        const _errs5 = errors;
                        if (typeof data0 !== 'string') {
                            const err1 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/release_token/properties/200/definitions/200/properties/status/type',
                                keyword: 'type',
                                params: {type: 'string'},
                                message: 'must be string',
                            };
                            if (vErrors === null) {
                                vErrors = [err1];
                            } else {
                                vErrors.push(err1);
                            }
                            errors++;
                        }
                        if (!(data0 === 'success')) {
                            const err2 = {
                                instancePath: instancePath + '/status',
                                schemaPath:
                                    '#/definitions/release_token/properties/200/definitions/200/properties/status/enum',
                                keyword: 'enum',
                                params: {
                                    allowedValues:
                                        schema51.properties.status.enum,
                                },
                                message:
                                    'must be equal to one of the allowed values',
                            };
                            if (vErrors === null) {
                                vErrors = [err2];
                            } else {
                                vErrors.push(err2);
                            }
                            errors++;
                        }
                        var valid2 = _errs5 === errors;
                    } else {
                        var valid2 = true;
                    }
                    if (valid2) {
                        if (data.result !== undefined) {
                            const _errs7 = errors;
                            if (typeof data.result !== 'boolean') {
                                const err3 = {
                                    instancePath: instancePath + '/result',
                                    schemaPath:
                                        '#/definitions/release_token/properties/200/definitions/200/properties/result/type',
                                    keyword: 'type',
                                    params: {type: 'boolean'},
                                    message: 'must be boolean',
                                };
                                if (vErrors === null) {
                                    vErrors = [err3];
                                } else {
                                    vErrors.push(err3);
                                }
                                errors++;
                            }
                            var valid2 = _errs7 === errors;
                        } else {
                            var valid2 = true;
                        }
                    }
                }
            }
        } else {
            const err4 = {
                instancePath,
                schemaPath:
                    '#/definitions/release_token/properties/200/definitions/200/type',
                keyword: 'type',
                params: {type: 'object'},
                message: 'must be object',
            };
            if (vErrors === null) {
                vErrors = [err4];
            } else {
                vErrors.push(err4);
            }
            errors++;
        }
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err5 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err5];
        } else {
            vErrors.push(err5);
        }
        errors++;
        validate44.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate44.errors = vErrors;
    return errors === 0;
}
export const validateReleaseToken403 = validate45;
function validate45(
    data,
    {instancePath = '', parentData, parentDataProperty, rootData = data} = {},
) {
    let vErrors = null;
    let errors = 0;
    const _errs0 = errors;
    let valid0 = false;
    let passing0 = null;
    const _errs1 = errors;
    if (
        !validate41(data, {
            instancePath,
            parentData,
            parentDataProperty,
            rootData,
        })
    ) {
        vErrors =
            vErrors === null
                ? validate41.errors
                : vErrors.concat(validate41.errors);
        errors = vErrors.length;
    }
    var _valid0 = _errs1 === errors;
    if (_valid0) {
        valid0 = true;
        passing0 = 0;
    }
    if (!valid0) {
        const err0 = {
            instancePath,
            schemaPath: '#/oneOf',
            keyword: 'oneOf',
            params: {passingSchemas: passing0},
            message: 'must match exactly one schema in oneOf',
        };
        if (vErrors === null) {
            vErrors = [err0];
        } else {
            vErrors.push(err0);
        }
        errors++;
        validate45.errors = vErrors;
        return false;
    } else {
        errors = _errs0;
        if (vErrors !== null) {
            if (_errs0) {
                vErrors.length = _errs0;
            } else {
                vErrors = null;
            }
        }
    }
    validate45.errors = vErrors;
    return errors === 0;
}
