import React from 'react';
import cx from 'classnames';
import {useTranslation} from 'react-i18next';

import {IconTypes, Bar, Button} from '@pexip/components';

import {TestId} from '../../../../test/testIds';
import {FoldedSelfviewButton} from '../FoldedSelfview/FoldedSelfviewButton.view';
import {stopMouseEventPropagation} from '../../..';

import styles from './InMeetingSelfview.module.scss';

export const InMeetingSelfviewHeader: React.FC<{
    isMobileDevice?: boolean;
    isMaximized?: boolean;
    onClick: () => void;
    setIsMaximized?: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({isMobileDevice, isMaximized, onClick, setIsMaximized}) => {
    const {t} = useTranslation();

    return (
        <Bar
            className={styles.header}
            position="absoluteTop"
            flexDirection="rowReverse"
        >
            {isMobileDevice && isMaximized ? (
                <Button
                    colorScheme="light"
                    variant="secondary"
                    onClick={() => setIsMaximized?.(false)}
                    onPointerDownCapture={stopMouseEventPropagation()}
                >
                    {t('common.close', 'Close')}
                </Button>
            ) : (
                <FoldedSelfviewButton
                    onClick={onClick}
                    data-testid={TestId.ButtonCollapseSelfview}
                    tooltipText={t('meeting.hide-self-view', 'Hide self-view')}
                    aria-label={t('meeting.hide-self-view', 'Hide self-view')}
                    className={cx(
                        styles.collapseSelfviewButton,
                        isMobileDevice && styles.mobile,
                    )}
                    icon={IconTypes.IconFullscreenOff}
                    variant="neutral"
                    isMobileDevice={isMobileDevice}
                />
            )}
        </Bar>
    );
};
