import {createSignal} from '@pexip/signal';
import type {TransferDetails} from '@pexip/infinity';

export const meetingSignal = createSignal({name: 'meeting:meeting'});
export const stepSignal = createSignal({name: 'meeting:step'});
export const remoteStreamSignal = createSignal<MediaStream | undefined>({
    name: 'meeting:remoteStream',
});
export const pinRequiredSignal = createSignal<boolean>({
    name: 'meeting:pinRequired',
});
export const startPresentationSignal = createSignal<{
    stream: MediaStream;
}>({
    name: 'meeting:startPresentation',
});
export const endPresentationSignal = createSignal({
    name: 'meeting:endPresentation',
});
export const presentationStreamSignal = createSignal<MediaStream | undefined>({
    name: 'meeting:presentationStream',
});
export const splashScreenSignal = createSignal({name: 'meeting:splashScreen'});
export const idpSignal = createSignal({name: 'meeting:idp'});
export const layoutOverlayTextEnabledSignal = createSignal({
    name: 'meeting:layoutOverlayTextEnabled',
});
export const invalidPinSignal = createSignal({name: 'meeting:invalidPin'});
export const infinityErrorSignal = createSignal<{type: string; code: string}>({
    name: 'meeting:infinityError',
});
export const chatMessagesSignal = createSignal({name: 'meeting:chatMessages'});
export const unreadChatMessagesSignal = createSignal({
    name: 'meeting:unreadChatMessages',
});
export const directChatMessagesSignal = createSignal({
    name: 'meeting:directChatMessages',
});
export const unreadDirectChatMessagesSignal = createSignal({
    name: 'meeting:unreadDirectChatMessages',
});
export const unseenUnreadDirectChatMessagesSignal = createSignal({
    name: 'meeting:unseenUnreadDirectChatMessages',
});
export const transferSignal = createSignal<TransferDetails>({
    name: 'meeting:transfer',
});
export const disconnectedParticipantsSignal = createSignal({
    name: 'meeting:disconnectedParticipants',
});
export const transcriptsSignal = createSignal({
    name: 'meeting:transcripts',
});
