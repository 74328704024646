import React from 'react';
import cx from 'classnames';

import type {Quality} from '@pexip/peer-connection-stats';

import type {SelfviewProps} from '../Selfview.view';
import {AspectRatioSelfview} from '../AspectRatioSelfview/AspectRatioSelfview';

import {InMeetingSelfviewHeader} from './InMeetingSelfviewHeader.view';
import {InMeetingSelfviewFooter} from './InMeetingSelfviewFooter.view';

import styles from './InMeetingSelfview.module.scss';

export const InMeetingSelfview: React.FC<
    SelfviewProps & {
        quality: Quality;
        callQualityPosition: 'topRight' | 'bottomRight';
        overlayClassName?: string;
        isAudioInputMuted?: boolean;
        isAudioInputMissing?: boolean;
        isMobileDevice?: boolean;
        areEffectsEnabled: boolean;
        areEffectsApplied: boolean;
        isMaximized?: boolean;
        onCollapseSelfview: () => void;
        onToggleAudioClick: () => void;
        toggleFacingMode?: () => void;
        openEffectsModal: () => void;
        setIsMaximized?: React.Dispatch<React.SetStateAction<boolean>>;
    }
> = ({
    overlayClassName,
    isAudioInputMuted,
    isAudioInputMissing,
    isMobileDevice,
    quality,
    areEffectsEnabled,
    areEffectsApplied,
    isMaximized,
    onCollapseSelfview,
    onToggleAudioClick,
    toggleFacingMode,
    openEffectsModal,
    setIsMaximized,
    ...props
}) => (
    <AspectRatioSelfview
        isMobileDevice={isMobileDevice}
        aspectRatioType="in-meeting"
        overlayClassName={cx(styles.selfviewOverlay, overlayClassName)}
        isMaximized={isMaximized}
        {...props}
    >
        <InMeetingSelfviewHeader
            onClick={onCollapseSelfview}
            isMobileDevice={isMobileDevice}
            isMaximized={isMaximized}
            setIsMaximized={setIsMaximized}
        />
        <InMeetingSelfviewFooter
            isAudioInputMuted={isAudioInputMuted}
            isAudioInputMissing={isAudioInputMissing}
            isMobileDevice={isMobileDevice}
            quality={quality}
            areEffectsEnabled={areEffectsEnabled}
            areEffectsApplied={areEffectsApplied}
            onToggleAudioClick={onToggleAudioClick}
            toggleFacingMode={toggleFacingMode}
            openEffectsModal={openEffectsModal}
        />
    </AspectRatioSelfview>
);

export type InMeetingSelfviewProps = React.ComponentProps<
    typeof InMeetingSelfview
>;
