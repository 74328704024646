import React from 'react';
import {useTranslation} from 'react-i18next';

import {
    ThemeProvider,
    Row,
    List,
    TextLink,
    FontVariant,
} from '@pexip/components';
import {
    SettingsPanel,
    PanelHeader,
    TestId as MediaTestId,
} from '@pexip/media-components';

import {FALLBACK_LANGUAGES} from '../i18n';

const LANGUAGE_NAMES = {
    'zh-TW': '繁體中文',
    'zh-HK': '繁體中文',
    'zh-CN': '简体中文',
    zh: '简体中文',
    nn: 'Norsk (Nynorsk)',
    fr: 'Français',
    pt: 'Português',
    'en-GB': 'English (United Kingdom)',
    'en-US': 'English (United States)',
    en: 'English',
    cs: 'Česky',
    cy: 'Cymraeg',
    da: 'Dansk',
    de: 'Deutsch',
    'es-ES': 'Español',
    'es-US': 'Español de Ustados Unidos',
    fi: 'Suomi',
    'fr-CA': 'Français (Canadien)',
    'fr-FR': 'Français',
    ga: 'Gaeilge',
    id: 'Bahasa Indonesia',
    it: 'Italiano',
    ja: '日本語',
    ko: '한국어',
    nb: 'Norsk (bokmål)',
    nl: 'Nederlands',
    pl: 'Polski',
    'pt-BR': 'Português',
    sv: 'Svenska',
    th: 'ภาษาไทย',
    vi: 'Tiếng Việt',
    'zh-Hans': '简体中文',
    'zh-Hant': '繁體中文',
};

type LngKey = keyof typeof LANGUAGE_NAMES;

const IGNORED_LANGUAGES = [...FALLBACK_LANGUAGES, 'cimode'];

export const Languages: React.FC<{onBackClick: () => void}> = ({
    onBackClick,
}) => {
    const {t, i18n} = useTranslation();
    return (
        <SettingsPanel
            headerContent={
                <ThemeProvider colorScheme="light">
                    <PanelHeader
                        title={`${t('settings.languages', 'Languages')}`}
                        onBackClick={onBackClick}
                        backButtonTestId={MediaTestId.ButtonSettingsBack}
                    />
                </ThemeProvider>
            }
        >
            <ThemeProvider colorScheme="light">
                <List>
                    {i18n.options.supportedLngs &&
                        typeof i18n.options.supportedLngs !== 'boolean' &&
                        i18n.options.supportedLngs
                            ?.filter(
                                lngKey => !IGNORED_LANGUAGES.includes(lngKey),
                            )
                            .map(lngKey => (
                                <Row key={lngKey}>
                                    <TextLink
                                        onClick={() =>
                                            i18n.changeLanguage(lngKey)
                                        }
                                        variant="standard"
                                        fontVariant={
                                            lngKey === i18n.language
                                                ? FontVariant.BodyBold
                                                : FontVariant.Body
                                        }
                                    >
                                        {LANGUAGE_NAMES[lngKey as LngKey] ||
                                            lngKey}
                                    </TextLink>
                                </Row>
                            ))}
                </List>
            </ThemeProvider>
        </SettingsPanel>
    );
};
