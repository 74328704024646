export enum TestId {
    AudioMeterSettings = 'audio-meter-settings',
    AudioTestOutput = 'audio-test-output',
    Announcer = 'announcer',
    ButtonAccessibilitySettings = 'button-accessibility-settings',
    ButtonAddParticipant = 'button-add-participant',
    ButtonAddOverlay = 'button-add-overlay',
    ButtonAreYouHostNo = 'button-are-you-host-no',
    ButtonAreYouHostYes = 'button-are-you-host-yes',
    ButtonAskBreakoutHostForHelp = 'button-ask-breakout-host-for-help',
    ButtonAskForHelpModalJoinRoom = 'button-ask-for-help-modal-join-room',
    ButtonAskForHelpModalShowWhoNeedsHelp = 'button-ask-for-help-modal-show-who-needs-help',
    ButtonAudioMeter = 'button-audio-meter',
    ButtonAudioMeterMuted = 'button-audio-meter-muted',
    ButtonBlockedPermissions = 'button-blocked-permissions',
    ButtonCameraStepCantSee = 'button-camera-step-cant-see',
    ButtonCameraStepNo = 'button-camera-step-no',
    ButtonCameraStepYes = 'button-camera-step-yes',
    ButtonChatBack = 'button-chat-back',
    ButtonChooseAnother = 'button-choose-another',
    ButtonCloseChatPanel = 'button-close-chat-panel',
    ButtonDetectionNext = 'button-detection-next',
    ButtonDisconnectAll = 'button-disconnect-all',
    ButtonDisconnectAllCancel = 'button-disconnect-all-cancel',
    ButtonDisconnectAllConfirm = 'button-disconnect-all-confirm',
    ButtonEnterDTMF = 'button-enter-dtmf',
    ButtonEnterHostPin = 'button-enter-host-pin',
    ButtonFecc = 'button-fecc',
    ButtonFeccPanLeft = 'button-fecc-pan-left',
    ButtonFeccPanRight = 'button-fecc-pan-right',
    ButtonFeccTiltDown = 'button-fecc-tilt-down',
    ButtonFeccTiltUp = 'button-fecc-tilt-up',
    ButtonFeccZoomIn = 'button-fecc-zoom-in',
    ButtonFeccZoomOut = 'button-fecc-zoom-out',
    ButtonFlipCamera = 'button-flip-camera',
    ButtonFreeDialNext = 'button-free-dial-next',
    ButtonGetStarted = 'button-get-started',
    ButtonHideNameOverlay = 'button-hide-name-overlay',
    ButtonLock = 'button-lock',
    ButtonMeetingDeviceSettings = 'button-meeting-device-settings',
    ButtonMicrophoneStepCantHear = 'button-microphone-step-cant-hear',
    ButtonMicrophoneStepNo = 'button-speaker-step-no',
    ButtonMicrophoneStepYes = 'button-speaker-step-yes',
    ButtonOneTimeConfirmationModalNo = 'button-one-time-confirmation-modal-no',
    ButtonOneTimeConfirmationModalYes = 'button-one-time-confirmation-modal-yes',
    ButtonPresentationModeToggle = 'button-presentation-mode-toggle',
    ButtonRejoin = 'button-rejoin',
    ButtonReloadPage = 'button-reload-page',
    ButtonSkipStepByStep = 'button-skip-step-by-step',
    ButtonSetExtension = 'button-set-extension',
    ButtonSetName = 'button-set-name',
    ButtonStartPresentation = 'button-start-presentation',
    ButtonStopPresentation = 'button-stop-presentation',
    ButtonConfirmCustomStep = 'button-confirm-custom-step',
    ButtonSetPin = 'button-set-pin',
    ButtonSettingsCancel = 'button-settings-cancel',
    ButtonSettingsSave = 'button-settings-save',
    ButtonShowNameOverlay = 'button-show-name-overlay',
    ButtonSignInWith = 'button-sign-in-with',
    ButtonSpeakerStepCantHear = 'button-speaker-step-cant-hear',
    ButtonSpeakerStepNo = 'button-speaker-step-no',
    ButtonSpeakerStepYes = 'button-speaker-step-yes',
    ButtonStartConference = 'button-start-conference',
    ButtonTipsCamera = 'button-tips-camera',
    ButtonTipsMicrophone = 'button-tips-microphone',
    ButtonTipsSpeaker = 'button-tips-speaker',
    ButtonUnlock = 'button-unlock',
    CustomStepPage = 'custom-step-page',
    CustomStep = 'custom-step',
    DTMFNum = 'dtmf-num',
    ErrorMeetingScreen = 'error-meeting-screen',
    ErrorMeetingScreenTitle = 'error-meeting-screen-title',
    ErrorMeetingScreenSubtitle = 'error-meeting-screen-subtitle',
    ErrorScreen = 'error-screen',
    ErrorScreenCode = 'error-screen-code',
    InMeetingVideoWrapper = 'in-meeting-video-wrapper',
    InputExtension = 'input-extension',
    InputFreeDialId = 'input-free-dial-id',
    InputName = 'input-name',
    InputPin = 'input-pin',
    JoinAsGuestLink = 'join-as-guest-link',
    LinkAboutPexip = 'link-about-pexip',
    ButtonSelfviewQuality = 'button-selfview-quality',
    LinkDownloadLog = 'link-download-log',
    LinkLanguages = 'link-languages',
    LinkQuality = 'link-quality',
    LiveCaptions = 'live-captions',
    MicrophoneStepTip = 'microphone-step-tip',
    MediaModal = 'media-modal',
    ModalHostPin = 'modal-host-pin',
    ModalSettings = 'modal-settings',
    ModalBreakoutsAskForHelp = 'modal-breakouts-ask-for-help',
    ModalTransferParticipant = 'modal-transfer-participant',
    NotFoundMessage = 'not-found-message',
    OneTimeConfirmationModal = 'one-time-confirmation-modal',
    PanelFecc = 'panel-fecc',
    ParticipantPanelExternal = 'participant-panel-external',
    ParticipantPanelHandRaised = 'participant-panel-hand-raised',
    ParticipantPanelInMeeting = 'participant-panel-in-meeting',
    ParticipantPanelMainMenuButton = 'participant-panel-main-menu-button',
    ParticipantPanelWaitingInLobby = 'participant-panel-waiting-in-lobby',
    ParticipantMenuButton = 'participant-menu-button',
    ParticipantClosePanelButton = 'participant-close-panel-button',
    PermissionsRejectedHeader = 'permissions-rejected-header',
    PostFeedbackLink = 'post-feedback-link',
    SelectEffectBlur = 'select-effect-blur',
    SelectEffectNone = 'select-effect-none',
    SelectEffectOverlay = 'select-effect-overlay',
    SelectEffectUserOverlay = 'select-effect-user-overlay',
    StepHeaderCamera = 'step-header-camera',
    StepHeaderMicrophone = 'step-header-microphone',
    StepHeaderSpeaker = 'step-header-speaker',
    SubmitFeedback = 'submit-feedback',
    TabSettingsEffects = 'tab-settings-effects',
    TabSettingsMeetingLayout = 'tab-settings-meeting-layout',
    TabSettingsMeetingSettings = 'tab-settings-meeting-settings',
    TabSettingsAdditionalSettings = 'tab-settings-additional-settings',
    TabSettingsPersonalSettings = 'tab-settings-personal-settings',
    TermsAndServicesLink = 'terms-and-services-link',
    TextLinkLearnRequestPermissions = 'text-link-learn-request-permissions',
    TextLinkUnblockPermissionsVideo = 'text-link-unblock-permissions-video',
    ToastAllGuestsMutedByHost = 'toast-all-guests-muted-by-host',
    ToastAllGuestsUnmutedByHost = 'toast-all-guests-unmuted-by-host',
    ToggleAlwaysDisplayUiControls = 'toggle-always-display-ui-controls',
    ToggleBrowserCloseConformation = 'toggle-browser-close-confirmation',
    ToggleDisableKeyboardShortcuts = 'toggle-disable-keyboard-shortcuts',
    ToggleFecc = 'toggle-fecc',
    ToggleNoiseSuppression = 'toggle-noise-suppression',
    TogglePreferPresInMix = 'toggle-prefer-in-mix',
    TogglePrioritizeSharingMotion = 'toggle-prioritize-sharing-motion',
    ToggleLiveCaptions = 'toggle-live-captions',
    ToggleLockMeeting = 'toggle-lock-meeting',
    ToggleSpotlight = 'toggle-spotlight',
    UserMenu = 'user-menu',
    UserMenuAboutPexipModal = 'user-menu-about-pexip-model',
    UserMenuAddParticipant = 'user-menu-add-participant',
    UserMenuButton = 'user-menu-button',
    UserNamePage = 'user-name-page',
    UserNameStep = 'user-name-step',
    UserNameStepInputForm = 'user-name-step-input-form',
    UserNameStepTextInput = 'user-name-step-text-input',
    LinkUnmuteAllGuests = 'link-unmute-all-guests',
    LinkMuteAllGuests = 'link-mute-all-guests',
    LinkAllowGuestsToUnmute = 'link-allow-guests-to-unmute',
    LinkDisallowGuestsToUnmute = 'link-disallow-guests-to-unmute',
    UnmuteModal = 'unmute-modal',
    UnmuteModalKeepMuted = 'unmute-modal-keep-muted',
    UnmuteModalUnmute = 'unmute-modal-unmute',
    VideoAndSoundPanel = 'video-and-sound-panel',
    InviteSomeoneCanControlCheckbox = 'invite-someone-can-control-checkbox',
    PluginSidePanel = 'plugin-side-panel',
    ButtonClosePluginPanel = 'button-close-plugin-panel',
    TransferModalMeetingId = 'participant-transfer-meeting-id',
    TransferModalPin = 'participant-transfer-pin',
    TransferModalCanControl = 'participant-transfer-can-control',
    TransferModalBtn = 'participant-transfer-btn',
    ToggleLiveTranscripts = 'toggle-live-transcripts',
    LiveTranscriptsClosePanelButton = 'transcripts-close-panel-button',
}
