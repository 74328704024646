import {useSyncExternalStore, useMemo} from 'react';

import type {
    ChatMessage,
    ParticipantInDirectChatWith,
    UnseenUnreadDirectChatMessages,
} from '@pexip/media-components';
import {ChatType} from '@pexip/media-components';

import {useAssertedContext} from '../hooks/useAssertedContext';
import {MeetingContext} from '../contexts/meeting.context';
import type {Meeting} from '../services/InfinityClient.service';
import {
    stepSignal,
    remoteStreamSignal,
    pinRequiredSignal,
    idpSignal,
    layoutOverlayTextEnabledSignal,
    splashScreenSignal,
    invalidPinSignal,
    chatMessagesSignal,
    unreadChatMessagesSignal,
    directChatMessagesSignal,
    unreadDirectChatMessagesSignal,
    unseenUnreadDirectChatMessagesSignal,
    disconnectedParticipantsSignal,
    transcriptsSignal,
} from '../signals/Meeting.signals';
import {infinityClientSignals} from '../signals/InfinityClient.signals';
import type {Subscribe} from '../types';

export const useMeetingContext = () => useAssertedContext(MeetingContext);

export const useStep = (
    getState: Meeting['getStep'],
    subscribe: Subscribe = stepSignal.add,
) => useSyncExternalStore(subscribe, getState);

export const useRemoteStream = (
    getState: Meeting['getRemoteStream'],
    subscribe: Subscribe = remoteStreamSignal.add,
) => useSyncExternalStore(subscribe, getState);

export const usePinRequired = (
    getState: Meeting['getPinRequired'],
    subscribe: Subscribe = pinRequiredSignal.add,
) => useSyncExternalStore(subscribe, getState);

export const useIdps = (
    getState: Meeting['getIdps'],
    subscribe: Subscribe = idpSignal.add,
) => useSyncExternalStore(subscribe, getState);

export const useLayoutOverlayEnabled = (
    getState: Meeting['getLayoutOverlayEnabled'],
    subscribe: Subscribe = layoutOverlayTextEnabledSignal.add,
) => useSyncExternalStore(subscribe, getState);

export const useSplashScreen = (
    getState: Meeting['getSplashScreen'],
    subscribe: Subscribe = splashScreenSignal.add,
) => useSyncExternalStore(subscribe, getState);

export const useInvalidPinFeedback = (
    getInvalidPingMsg: () => string,
    subscribe: Subscribe = invalidPinSignal.add,
) => useSyncExternalStore(subscribe, getInvalidPingMsg);

export const useIsFeccEnabled = (
    getState: Meeting['getIsFeccEnabled'],
    subscribe: Subscribe = infinityClientSignals.onParticipants.add,
) => useSyncExternalStore(subscribe, getState);

export const useCurrentHostLayout = (
    getState: Meeting['getCurrentHostLayout'],
    subscribe: Subscribe = infinityClientSignals.onRequestedLayout.add,
) => useSyncExternalStore(subscribe, getState);

export const useEveryoneChatMessages = (
    getState: Meeting['getChatMessages'],
    subscribe: Subscribe = chatMessagesSignal.add,
) => useSyncExternalStore(subscribe, getState);

export const useUnreadChatMessages = (
    getState: Meeting['getUnreadChatMessages'],
    subscribe: Subscribe = unreadChatMessagesSignal.add,
) => useSyncExternalStore(subscribe, getState);

export const useDirectChatMessages = (
    getState: Meeting['getDirectChatMessages'],
    subscribe: Subscribe = directChatMessagesSignal.add,
) => useSyncExternalStore(subscribe, getState);

export const useUnreadDirectChatMessages = (
    getState: Meeting['getUnreadDirectChatMessages'],
    subscribe: Subscribe = unreadDirectChatMessagesSignal.add,
) => useSyncExternalStore(subscribe, getState);

export const useUnseenUnreadDirectChatMessages = (
    getState: Meeting['getUnseenUnreadDirectChatMessages'],
    subscribe: Subscribe = unseenUnreadDirectChatMessagesSignal.add,
) => useSyncExternalStore(subscribe, getState);

export const useAllUnreadAndUnseenChatMessages = (
    unreadChatMessages: ChatMessage[],
    unseenUnreadDirectChatMessages: UnseenUnreadDirectChatMessages,
) =>
    useMemo(
        () => [...unreadChatMessages, ...unseenUnreadDirectChatMessages],
        [unreadChatMessages, unseenUnreadDirectChatMessages],
    );

export const useChatMessages = (
    meeting: ReturnType<typeof useMeetingContext>,
    selectedChatType: ChatType,
    participantInDirectChatWith: ParticipantInDirectChatWith,
) => {
    const everyoneChatMessages = useEveryoneChatMessages(
        meeting.getChatMessages,
    );
    const directChatMessages = useDirectChatMessages(
        meeting.getDirectChatMessages,
    );

    const currentChatMessages = useMemo(
        () =>
            selectedChatType === ChatType.Everyone
                ? everyoneChatMessages
                : participantInDirectChatWith
                  ? directChatMessages.get(
                        participantInDirectChatWith.identity,
                    ) ?? []
                  : [],
        [
            directChatMessages,
            everyoneChatMessages,
            participantInDirectChatWith,
            selectedChatType,
        ],
    );

    return {everyoneChatMessages, directChatMessages, currentChatMessages};
};

export const useDisconnectedParticipants = (
    getState: Meeting['getDisconnectedParticipants'],
    subscribe: Subscribe = disconnectedParticipantsSignal.add,
) => useSyncExternalStore(subscribe, getState);

export const useTranscripts = (
    getState: Meeting['getTranscripts'],
    subscribe: Subscribe = transcriptsSignal.add,
) => useSyncExternalStore(subscribe, getState);
