import {useEffect, useReducer, useRef} from 'react';

import type {IScrollbars} from '@pexip/components';

import type {
    ChatMessage,
    ChatType,
    ParticipantInDirectChatWith,
} from '../types';
import {
    isLastMessageByCurrentUser,
    getIsLastNewMessageVisibleFn,
} from '../utils/chat';
import {MESSAGES_BOTTOM_OFFSET} from '../constants';

import {useDisplayNewChatMessagePill} from './useDisplayNewChatMessagePill';
import {useNewChatMessageCounter} from './useNewChatMessageCounter';

export const useChat = ({
    currentUserId,
    messages,
    sendMessage,
    selectedChatType,
    participantInDirectChatWith,
}: {
    currentUserId: string;
    messages: ChatMessage[];
    selectedChatType: ChatType;
    participantInDirectChatWith: ParticipantInDirectChatWith;
    sendMessage: (message: string, toParticipantUuid?: string) => void;
}) => {
    const scrollbarRef = useRef<IScrollbars>(null);

    const handleSubmit = (message: string, toParticipantUuid?: string) => {
        if (!message.trim()) {
            return;
        }
        sendMessage(message, toParticipantUuid);
    };

    const [
        {isLastMessageVisible, displayNewMessageToast, newMessagesCount},
        dispatch,
    ] = useNewChatMessageReducer();

    const isMessageByCurrentUser = isLastMessageByCurrentUser(
        messages,
        currentUserId,
    );

    const closeNewChatMessage = (
        e: React.SyntheticEvent<HTMLButtonElement>,
    ) => {
        e.stopPropagation();
        dispatch(setDisplayNewMessageToast(false));
    };

    const onScroll = () => {
        if (isLastNewMessageVisible(scrollbarRef?.current)) {
            dispatch(updateLastNewMessageVisible(true, false, 0));
        } else {
            dispatch(setIsLastMessageVisible(false));
        }
    };

    useDisplayNewChatMessagePill(
        {
            isLastMessageVisible,
            isMessageByCurrentUser,
            messageCount: messages.length,
            scrollbarRef,
        },
        setDisplayNewMessageToast,
        dispatch,
    );

    useNewChatMessageCounter(
        {isLastMessageVisible, isMessageByCurrentUser, messages},
        updateNewMessagesCount,
        dispatch,
    );

    useEffect(() => {
        /*
         * When selectedChatType or participantInDirectChatWith changes
         * we need to reset some of the state returned from this useChat hook because
         * some of the state between the chats are not common, for instance
         * new message count, displaying new message toast is particular for each chat.
         */
        dispatch(updateLastNewMessageVisible(true, false, 0));
    }, [selectedChatType, participantInDirectChatWith, dispatch]);

    return {
        closeNewChatMessage,
        displayNewMessageToast,
        isLastMessageVisible,
        newMessagesCount,
        onScroll,
        scrollbarRef,
        handleSubmit,
    };
};

export type UseChatReturnType = ReturnType<typeof useChat>;

const isLastNewMessageVisible = getIsLastNewMessageVisibleFn(
    MESSAGES_BOTTOM_OFFSET,
);

export type NewUnreadChatMessageState = {
    isLastMessageVisible: boolean;
    displayNewMessageToast: boolean;
    newMessagesCount: number;
};

export const initialNewUnreadMessageState: NewUnreadChatMessageState = {
    isLastMessageVisible: true,
    displayNewMessageToast: false,
    newMessagesCount: 0,
};

export const useNewChatMessageReducer = () =>
    useReducer(newChatMessageReducer, initialNewUnreadMessageState);

export const newChatMessageReducer = (
    state: NewUnreadChatMessageState,
    action: NewUnreadChatMessageActions,
) => {
    if (action.type === 'set-last-message-visible') {
        return {
            ...state,
            isLastMessageVisible: action.isLastMessageVisible,
        };
    }

    if (action.type === 'set-display-new-message-toast') {
        return {
            ...state,
            displayNewMessageToast: action.displayNewMessageToast,
        };
    }

    if (action.type === 'update-new-message-count') {
        return {
            ...state,
            newMessagesCount: action.newMessagesCount,
        };
    }

    if (action.type === 'update-last-message-visible') {
        return {
            ...state,
            isLastMessageVisible: action.isLastMessageVisible,
            displayNewMessageToast: action.displayNewMessageToast,
            newMessagesCount: action.newMessagesCount,
        };
    }

    return state;
};

export type NewUnreadChatMessageActions =
    | {type: 'set-last-message-visible'; isLastMessageVisible: boolean}
    | {type: 'set-display-new-message-toast'; displayNewMessageToast: boolean}
    | {type: 'update-new-message-count'; newMessagesCount: number}
    | {
          type: 'update-last-message-visible';
          isLastMessageVisible: boolean;
          displayNewMessageToast: boolean;
          newMessagesCount: number;
      };

export const setIsLastMessageVisible = (
    isLastMessageVisible: boolean,
): NewUnreadChatMessageActions => ({
    type: 'set-last-message-visible',
    isLastMessageVisible,
});

export const setDisplayNewMessageToast = (
    displayNewMessageToast: boolean,
): NewUnreadChatMessageActions => ({
    type: 'set-display-new-message-toast',
    displayNewMessageToast,
});

export const updateNewMessagesCount = (
    newMessagesCount: number,
): NewUnreadChatMessageActions => ({
    type: 'update-new-message-count',
    newMessagesCount,
});

export const updateLastNewMessageVisible = (
    isLastMessageVisible: boolean,
    displayNewMessageToast: boolean,
    newMessagesCount: number,
): NewUnreadChatMessageActions => ({
    type: 'update-last-message-visible',
    isLastMessageVisible,
    displayNewMessageToast,
    newMessagesCount,
});
