import cx from 'classnames';
import type {CSSProperties} from 'react';
import React from 'react';

import {ThemeConsumer} from '../../../themes/ThemeContext';
import type {LinkTarget} from '../../../types/propTypes';
import type {InteractiveElementProps} from '../InteractiveElement/InteractiveElement';
import {InteractiveElement} from '../InteractiveElement/InteractiveElement';
import type {TextProps} from '../Text/Text';
import {Text} from '../Text/Text';

import styles from './TextLink.module.scss';

export const TextLink: React.FC<
    InteractiveElementProps &
        Omit<TextProps, 'htmlTag'> & {
            href?: string;
            isDisabled?: boolean;
            isUnderlined?: boolean;
            target?: LinkTarget;
            textClassName?: string;
            textStyle?: CSSProperties;
        }
> = ({
    colorScheme,
    children,
    className,
    disabled,
    fontVariant,
    isDisabled,
    isUnderlined,
    isTruncated = false,
    isUppercase,
    htmlTag,
    target,
    variant = 'link',
    textStyle,
    textClassName,
    ...props
}) => (
    <ThemeConsumer>
        {({colorScheme: defaultColorScheme}) => {
            const currentColorScheme = colorScheme ?? defaultColorScheme;
            return (
                <InteractiveElement
                    className={cx(
                        styles.textLink,
                        styles[currentColorScheme],
                        variant === 'link' && styles[variant],
                        {
                            [styles.truncateWrap]: isTruncated,
                            [styles.disabled]: isDisabled || disabled,
                        },
                        className,
                    )}
                    target={target}
                    disabled={isDisabled}
                    htmlTag={isDisabled ? 'div' : htmlTag}
                    rel={target === '_blank' ? 'noreferrer noopener' : ''}
                    {...props}
                >
                    <Text
                        className={cx(
                            styles.textLinkContent,
                            {
                                [styles.underline]: isUnderlined,
                            },
                            textClassName,
                        )}
                        colorScheme={currentColorScheme}
                        fontVariant={fontVariant}
                        isTruncated={isTruncated}
                        isUppercase={isUppercase}
                        style={textStyle}
                        variant={variant}
                    >
                        {children}
                    </Text>
                </InteractiveElement>
            );
        }}
    </ThemeConsumer>
);

export type TextLinkProps = React.ComponentProps<typeof TextLink>;
