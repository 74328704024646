import React from 'react';
import cx from 'classnames';

import {Text, FontVariant, ThemeConsumer} from '../../..';

import styles from './CondensedTabs.module.scss';

type CondensedTabID = number | string;

type CondensedTab = {
    id: CondensedTabID;
    name: string;
    textEndContent?: React.ReactElement;
    testId: string;
};

type CondensedTabs = CondensedTab[];

export const CondensedTabs: React.FC<
    React.ComponentProps<'div'> & {
        sizeModifier: 'small' | 'medium' | 'large';
        tabs: CondensedTabs;
        selectedTabID: CondensedTabID;
        onTabChange: (newTabID: CondensedTabID) => void;
        allowActiveTabToBeClicked?: boolean;
    }
> = ({
    allowActiveTabToBeClicked = false,
    tabs,
    selectedTabID,
    onTabChange,
    className,
    sizeModifier = 'large',
}) => (
    <ThemeConsumer>
        {({colorScheme: defaultColorScheme}) => {
            return (
                <div
                    role="tablist"
                    className={cx(
                        defaultColorScheme,
                        styles[defaultColorScheme],
                        styles[sizeModifier],
                        styles.tabs,
                        className,
                    )}
                >
                    {tabs.map(({name, id, textEndContent, testId}) => (
                        <button
                            data-testid={testId}
                            onClick={() => {
                                if (
                                    selectedTabID !== id ||
                                    (selectedTabID === id &&
                                        allowActiveTabToBeClicked)
                                ) {
                                    onTabChange(id);
                                }
                            }}
                            role="tab"
                            key={id}
                            className={cx(
                                styles.tab,
                                styles[defaultColorScheme],
                                styles[sizeModifier],
                                {
                                    [styles.active]: id === selectedTabID,
                                    [styles.canClickActive]:
                                        allowActiveTabToBeClicked,
                                },
                            )}
                        >
                            <Text
                                fontVariant={
                                    sizeModifier === 'small'
                                        ? FontVariant.SmallBold
                                        : FontVariant.BodyBold
                                }
                                className={cx({'p-relative': !!textEndContent})}
                            >
                                {name}
                                {textEndContent}
                            </Text>
                        </button>
                    ))}
                </div>
            );
        }}
    </ThemeConsumer>
);
