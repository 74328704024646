import React, {useEffect, useState} from 'react';
import cx from 'classnames';
import {useTranslation} from 'react-i18next';

import type {VideoWrapperSizeModifier} from '@pexip/components';
import {VideoWrapper, Video} from '@pexip/components';

import {TestId} from '../../../test/testIds';

import {isVideoActive} from './SelfView.utils';
import {SelfviewOverlay} from './SelfviewOverlay.view';

import styles from './Selfview.module.scss';

export const Selfview: React.FC<
    React.PropsWithChildren<
        React.ComponentProps<typeof VideoWrapper> & {
            overlayClassName?: string;
            isMirrored?: boolean;
            isVideoInputMuted: boolean;
            localMediaStream?: MediaStream;
            shouldShowUserAvatar: boolean;
            sizeModifier?: VideoWrapperSizeModifier;
            shouldShowBorder?: boolean;
            isMobileDevice?: boolean;
            username: string;
            shouldShowErrorBorder?: boolean;
            showOverlayShadow?: boolean;
        }
    >
> = ({
    children,
    className,
    overlayClassName,
    isMirrored,
    isVideoInputMuted,
    localMediaStream,
    sizeModifier,
    shouldShowUserAvatar,
    shouldShowBorder,
    username,
    isMobileDevice = false,
    shouldShowErrorBorder = false,
    showOverlayShadow,
    ...props
}) => {
    const {t} = useTranslation();
    const showVideo = isVideoActive(isVideoInputMuted, localMediaStream);
    const [isVideoInitiallyShown, setIsVideoInitiallyShown] = useState(false);

    useEffect(() => {
        if (showVideo) {
            setIsVideoInitiallyShown(true);
        }
    }, [showVideo]);

    return (
        <div className={styles.wrapper} {...props}>
            <VideoWrapper
                className={cx(
                    styles.videoWrapper,
                    {
                        [styles.withInfoBorder]: shouldShowBorder,
                        [styles.withErrorBorder]: shouldShowErrorBorder,
                    },
                    className,
                )}
                size={sizeModifier}
                background={'secondary'}
                alignItems="stretch"
                isFullHeight={true}
                data-testid={TestId.VideoSelfviewWrapper}
            >
                <Video
                    aria-label={t('media.selfview', 'Selfview')}
                    data-testid={TestId.VideoSelfview}
                    isMirrored={isMirrored}
                    srcObject={localMediaStream}
                    disablePictureInPicture={isMobileDevice}
                    muted
                    className={cx(styles.video, {
                        [styles.hide]: !showVideo,
                    })}
                />
            </VideoWrapper>
            <SelfviewOverlay
                isVideoInitiallyShown={isVideoInitiallyShown}
                showPlaceholder={
                    !showVideo && !shouldShowErrorBorder && shouldShowUserAvatar
                }
                username={username}
                className={overlayClassName}
                showShadow={showOverlayShadow}
            >
                {children}
            </SelfviewOverlay>
        </div>
    );
};

export type SelfviewProps = React.ComponentProps<typeof Selfview>;
