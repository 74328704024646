import React from 'react';
import cx from 'classnames';

import {
    Box,
    Color,
    FontVariant,
    Icon,
    IconTypes,
    Text,
} from '@pexip/components';

import type {ChatMessage, ChatActivityType} from '../../types';
import {TestId} from '../../../test/testIds';

import styles from './ChatMessage.module.scss';

const getTestId = (chatActivityType?: ChatActivityType) => {
    switch (chatActivityType) {
        case 'removed':
            return TestId.ChatRemoveActivityMessage;

        case 'empty-chat':
            return TestId.ChatEmptyActivityMessage;

        default:
            return TestId.ChatActivityMessage;
    }
};

export const ChatActivityMessage: React.FC<{
    message: ChatMessage;
    className?: string;
}> = ({message, className}) => (
    <Box
        isWithShadow={false}
        className={cx('p-3', styles.activityMessageWrapper, className)}
        key={message.id}
        data-testid={getTestId(message.activityType)}
        hasBackground={false}
    >
        <div className={styles.messageText}>
            <Text isItalicized htmlTag="p" className={styles.messageText}>
                <Text
                    variant="tinted"
                    data-testid={TestId.ChatActivityMessageText}
                >
                    <Icon
                        color={Color.Neutral70}
                        className={styles.activityMessageIcon}
                        size="compact"
                        source={IconTypes.IconInfoRound}
                    />
                    {message.message}
                </Text>
                {message.activityType === 'removed' && (
                    <Text
                        variant="tinted"
                        className={styles.messageName}
                        translate="no"
                    >
                        &nbsp;{message.removedParticipant?.displayName}
                    </Text>
                )}
            </Text>
            {(message.activityType === 'joined' ||
                message.activityType === 'left') && (
                <Text
                    fontVariant={FontVariant.Small}
                    className={styles.messageDate}
                >
                    {message.timestamp}
                </Text>
            )}
        </div>
    </Box>
);

export type ChatActivityMessageProps = React.ComponentProps<
    typeof ChatActivityMessage
>;
