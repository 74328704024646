import React from 'react';
import {useTranslation} from 'react-i18next';

import {CondensedTabs, useTouchDevice} from '@pexip/components';

import {
    ChatType,
    UnreadIndicator,
    type ChatMessage,
    type UnseenUnreadDirectChatMessages,
    TestId,
} from '../..';

import styles from './ChatTypeSwitcher.module.scss';

export const ChatTypeSwitcher: React.FC<{
    allowChatTypeActiveTabClick: boolean;
    selectedChatType: ChatType;
    unreadChatMessages: ChatMessage[];
    unseenUnreadDirectChatMessages: UnseenUnreadDirectChatMessages;
    onChatTypeChange: (chatType: ChatType) => void;
}> = ({
    allowChatTypeActiveTabClick,
    selectedChatType,
    unreadChatMessages,
    unseenUnreadDirectChatMessages,
    onChatTypeChange,
}) => {
    const {t} = useTranslation();
    const isTouch = useTouchDevice();
    return (
        <CondensedTabs
            allowActiveTabToBeClicked={allowChatTypeActiveTabClick}
            sizeModifier={isTouch ? 'large' : 'medium'}
            selectedTabID={selectedChatType}
            onTabChange={id => onChatTypeChange(id as ChatType)}
            tabs={[
                {
                    id: ChatType.Everyone,
                    name: t('meeting.chat-with-everyone', 'Everyone'),
                    textEndContent:
                        unreadChatMessages.length > 0 ? (
                            <UnreadIndicator
                                testId={
                                    TestId.ChatUnreadEveryoneMessagesIndicator
                                }
                                className={styles.unreadIndicator}
                            />
                        ) : undefined,
                    testId: TestId.ButtonChatEveryoneTab,
                },
                {
                    id: ChatType.Direct,
                    name: t('meeting.chat-with-individual', 'Direct'),
                    textEndContent:
                        unseenUnreadDirectChatMessages.length > 0 ? (
                            <UnreadIndicator
                                testId={
                                    TestId.ChatUnseenDirectMessagesIndicator
                                }
                                className={styles.unreadIndicator}
                            />
                        ) : undefined,
                    testId: TestId.ButtonChatDirectTab,
                },
            ]}
        />
    );
};
