import React from 'react';
import cx from 'classnames';

import styles from './UnreadIndicator.module.scss';

export const UnreadIndicator: React.FC<
    {testId: string} & React.ComponentProps<'div'>
> = ({className, testId}) => (
    <div data-testid={testId} className={cx(styles.container, className)} />
);
