import type {InputFrame} from './types';

const wrapTransformController = (
    videoFrame: VideoFrame,
    controller: TransformStreamDefaultController<VideoFrame>,
): TransformStreamDefaultController<InputFrame> => {
    return {
        get desiredSize() {
            return controller.desiredSize;
        },
        enqueue: frame => {
            if (!frame) {
                return;
            }
            if (frame instanceof VideoFrame) {
                return controller.enqueue(frame);
            }
            if (
                ('OffscreenCanvas' in window &&
                    frame instanceof OffscreenCanvas) ||
                frame instanceof HTMLCanvasElement ||
                frame instanceof HTMLVideoElement ||
                frame instanceof HTMLImageElement ||
                frame instanceof ImageBitmap
            ) {
                const timestamp = videoFrame.timestamp ?? 0;
                try {
                    // Constructing a new VideoFrame with the modified frame
                    // could throw an error
                    const newFrame = new VideoFrame(frame, {
                        timestamp,
                        alpha: 'discard',
                    });
                    controller.enqueue(newFrame);
                    videoFrame.close();
                } catch {
                    controller.enqueue(videoFrame);
                }
                return;
            }
            throw new Error('Unexpected input frame');
        },
        error: reason => controller.error(reason),
        terminate: () => controller.terminate(),
    };
};

export const nullTransformController = (
    controller: Pick<
        TransformStreamDefaultController<CanvasImageSource>,
        'enqueue' | 'terminate'
    >,
): TransformStreamDefaultController<CanvasImageSource> => {
    return {
        get desiredSize() {
            return 0;
        },
        enqueue: frame => {
            if (
                ('OffscreenCanvas' in window &&
                    frame instanceof OffscreenCanvas) ||
                frame instanceof HTMLCanvasElement ||
                frame instanceof HTMLVideoElement ||
                frame instanceof HTMLImageElement ||
                frame instanceof ImageBitmap
            ) {
                return controller.enqueue(frame);
            }
            throw new Error('Unexpected input frame');
        },
        error: reason => {
            throw new Error(reason);
        },
        terminate: () => controller.terminate(),
    };
};

export const adaptInputFrameTransformer = (
    transformer: Transformer<InputFrame, InputFrame>,
) => {
    const transform = (
        frame: VideoFrame,
        controller: TransformStreamDefaultController<VideoFrame>,
    ) => {
        return transformer.transform?.(
            frame,
            wrapTransformController(frame, controller),
        );
    };
    return {...transformer, transform};
};
