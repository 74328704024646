/**
 * Assert the value is truthy.
 * @param t - The value to assert
 * @param message - The message to show when the assertion fails
 * @throws Error - When the value is falsy
 *
 * @example
 * ```ts
 * assert(true); // OK
 * assert(false); // Error: AssertError
 * assert(false, 'CustomError'); // Error: CustomError
 * ```
 **/
type Assert = <T>(t: T, message?: string) => asserts t;
export const assert: Assert = (t, message = 'AssertError') => {
    if (!t) {
        throw new Error(message, {cause: t});
    }
    return t;
};
