import React from 'react';
import cx from 'classnames';
import {useTranslation} from 'react-i18next';

import {
    Avatar,
    AvatarType,
    Box,
    FontVariant,
    Text,
    URLToLink,
    withColorScheme,
    ThemeConsumer,
} from '@pexip/components';

import type {ChatMessage} from '../../types';
import {TestId} from '../../../test/testIds';
import {UnreadIndicator} from '../UnreadIndicator/UnreadIndicator.view';

import styles from './ChatMessage.module.scss';

const BoxLight = withColorScheme(Box, 'light');

export const ChatMessageView: React.FC<{
    isYou?: boolean;
    message: ChatMessage;
    withAvatar?: boolean;
    isUnread?: boolean;
    isTruncated?: boolean;
    noPadding?: boolean;
    useHoverStyle?: boolean;
    className?: string;
}> = ({
    isYou = false,
    message,
    withAvatar = false,
    isUnread = false,
    isTruncated = false,
    noPadding = false,
    useHoverStyle = false,
    className,
}) => {
    const {t} = useTranslation();
    return (
        <ThemeConsumer>
            {({colorScheme: defaultColorScheme}) => {
                return (
                    <BoxLight
                        isWithShadow={false}
                        className={cx(
                            className,
                            styles.box,
                            defaultColorScheme,
                            styles[defaultColorScheme],
                            {
                                [styles.messagePending]: message.pending,
                                'p-3': !noPadding,
                                [styles.useHover]: useHoverStyle,
                                [styles.isYou]: isYou,
                            },
                        )}
                        key={message.id}
                        data-testid={TestId.ChatMessage}
                    >
                        {withAvatar && (
                            <Avatar
                                className="mr-2"
                                colorScheme="dark"
                                displayName={message.displayName}
                                sizeModifier={'small'}
                                type={AvatarType.Initials}
                            />
                        )}
                        <div className={styles.messageInformationContainer}>
                            <div className={styles.messageTitle}>
                                <div className="d-flex align-items-center">
                                    <Text
                                        fontVariant={FontVariant.BodyBold}
                                        className={styles.messageName}
                                        data-testid={TestId.ChatMessageName}
                                        translate="no"
                                    >
                                        {isYou
                                            ? t('meeting.you', 'You')
                                            : message.displayName}
                                    </Text>
                                    {isUnread && (
                                        <UnreadIndicator
                                            testId={
                                                TestId.ChatUnreadMessageIndicator
                                            }
                                            className="ml-1"
                                        />
                                    )}
                                </div>
                                <Text
                                    fontVariant={FontVariant.Small}
                                    className={styles.messageDate}
                                >
                                    {message.timestamp}
                                </Text>
                            </div>
                            <Text
                                htmlTag="p"
                                fontVariant={
                                    isUnread
                                        ? FontVariant.BodyBold
                                        : FontVariant.Body
                                }
                                className={cx(styles.messageText, {
                                    [styles.truncated]: isTruncated,
                                })}
                                data-testid={TestId.ChatMessageText}
                            >
                                <URLToLink
                                    text={message.message}
                                    data-testid={TestId.ChatMessageLink}
                                />
                            </Text>
                        </div>
                    </BoxLight>
                );
            }}
        </ThemeConsumer>
    );
};
